import {
    FETCH_PRIVATE_ENTREPRENEUR_ERROR,
    FETCH_PRIVATE_ENTREPRENEUR_START,
    FETCH_PRIVATE_ENTREPRENEUR_SUCCESS
} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";


export function createPrivateEntrepreneur(data) {
    return async dispatch => {
        try {
            const token = localStorage.getItem('token');
            return await API.post('recipient/store', data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        } catch (e) {
            console.error(e);
        }
    }
}

export function editPrivateEntrepreneurSend(id, data) {
    return async dispatch => {
        try {
            const token = localStorage.getItem('token');
            return await API.put(`recipient/${id}/update`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        } catch (e) {
            console.error(e);
        }
    }
}

export function fetchPrivateEntrepreneurs(data = '') {
    return async dispatch => {
        dispatch(fetchPrivateEntrepreneursStart())
        const token = localStorage.getItem('token');
        try {
            const response = await API.get(`recipient/index${data}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            dispatch(fetchPrivateEntrepreneursSuccess(response.data));
        } catch (e) {
            console.error(fetchPrivateEntrepreneursError(e));
        }
    }
}

export function fetchPrivateEntrepreneursStart() {
    return {
        type: FETCH_PRIVATE_ENTREPRENEUR_START
    }
}

export function fetchPrivateEntrepreneursSuccess(privateEnts) {
    return {
        type: FETCH_PRIVATE_ENTREPRENEUR_SUCCESS,
        privateEnts
    }
}

export function fetchPrivateEntrepreneursError(e) {
    return {
        type: FETCH_PRIVATE_ENTREPRENEUR_ERROR,
        error: e
    }
}

