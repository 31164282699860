import React, {Component} from 'react'
import './AddPrivateEntrepreneursPopup.css';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    createPrivateEntrepreneur,
    editPrivateEntrepreneurSend,
} from "../../../store/action/private-entrepreneurs";
import connect from "react-redux/es/connect/connect";
import {fieldEmpty} from "../../../services/validationService";
import InputMask from 'react-input-mask';


class AddPrivateEntrepreneursPopup extends Component {

    state = {
        isEditingBrand: false,
        editingTimeOut: false,
        data: {
            name: '',
            ipn: '',
            address: '',
            account: '',
            bank: '',
            mfo: '',
            phone: '',
        },
        validation: {
            name: false,
            ipn: false,
            address: false,
            account: false,
            bank: false,
            mfo: false,
            phone: false,
        }
    }

    componentDidMount() {
        if (this.props.isEdit) {
            let newData = {
                name: this.props.privateEnts[this.props.index].name,
                ipn: this.props.privateEnts[this.props.index].ipn,
                address: this.props.privateEnts[this.props.index].address,
                account: this.props.privateEnts[this.props.index].account,
                bank: this.props.privateEnts[this.props.index].bank,
                mfo: this.props.privateEnts[this.props.index].mfo,
                phone: this.props.privateEnts[this.props.index].phone,
            };

            this.setState({
                data: newData,
            })
        }
    }

    handleChangeInput = (event) => {
        let newVal = this.state.data;
        let field = event.target.dataset.name;
        newVal[field] = event.target.value;
        this.setState({
            data: newVal
        });
    }

    handleAutocompleteChangeInput = (event) => {
        if (event === null) return;

        this.setState({
            data: this.state.data
        });
    }

    handleAutocompleteChangeInputOnKeyUp = (event, text) => {
        if (event === null) return;

        this.setState({
            data: this.state.data
        });
    }

    handleClose = () => {
        this.props.handleClose();
    }

    createPrivateEntrepreneurHandler = () => {
        let dataForm = this.state.data;

        let validation = fieldEmpty(dataForm, []);

        if (!validation.isValidation) {
            this.setState({
                validation: validation.validation
            });
            return;
        }

        dataForm.phone = dataForm.phone.replace('(', '');
        dataForm.phone = dataForm.phone.replace(')', '');
        dataForm.phone = dataForm.phone.replace('+', '');

        this.props.createPrivateEntrepreneur(this.state.data)
            .then((response) => {
                if (response.status === 200) {
                    this.props.handleClose();
                    this.props.reRender();
                }
            });
    }

    editPrivateEntrepreneurHandler = () => {
        let dataForm = this.state.data;

        let validation = fieldEmpty(dataForm, []);

        if (!validation.isValidation) {
            this.setState({
                validation: validation.validation
            });
            return;
        }
        this.props.editPrivateEntrepreneurSend(this.props.privateEnts[this.props.index].id, this.state.data)
            .then((response) => {
                if (response.status === 200) {
                    this.props.handleClose();
                    this.props.reRender();
                }
            });
    }

    handleChangePayment = (event) => {
        let newVal = this.state.data;
        newVal.payment_type = event.target.value;

        this.setState({
            data: newVal
        })
    }

    render() {

        let editItem;

        if (this.props.isEdit) {
            editItem = this.props.privateEnts[this.props.index];
        }

        return (
            <div className="modal-popup__window">
                <div className="modal-popup__header">
                <span>
                  {this.props.isEdit ?
                      <FormattedMessage id="app.edit"/>
                      :
                      <FormattedMessage id="app.addNote"/>
                  }
                </span>
                </div>
                <div className="modal-popup__body">

                    {this.props.isEdit ?
                        <div className="row">
                            <div className="col-md-12">
                                <div className="modal-popup__row">
                                    <TextField
                                        defaultValue={editItem.name}
                                        error={this.state.validation.name}
                                        label={<FormattedMessage id="app.privateEntsName"/>}
                                        className={"modal-popup__input modal-field"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        inputProps={{'data-name': 'first_name'}}
                                        onKeyUp={this.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="modal-popup__row">
                                    <TextField
                                        defaultValue={editItem.ipn}
                                        error={this.state.validation.ipn}
                                        label={<FormattedMessage id="app.privateEntsIpn"/>}
                                        className={"modal-popup__input modal-field"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        inputProps={{'data-name': 'ipn'}}
                                        onKeyUp={this.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="modal-popup__row">
                                    <TextField
                                        defaultValue={editItem.address}
                                        error={this.state.validation.address}
                                        label={<FormattedMessage id="app.privateEntsAddress"/>}
                                        className={"modal-popup__input modal-field"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        inputProps={{'data-name': 'address'}}
                                        onKeyUp={this.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="modal-popup__row">
                                    <TextField
                                        defaultValue={editItem.account}
                                        error={this.state.validation.account}
                                        label={<FormattedMessage id="app.privateEntsAccount"/>}
                                        className={"modal-popup__input modal-field"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        inputProps={{'data-name': 'account'}}
                                        onKeyUp={this.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="modal-popup__row">
                                    <TextField
                                        defaultValue={editItem.bank}
                                        error={this.state.validation.bank}
                                        label={<FormattedMessage id="app.privateEntsBank"/>}
                                        className={"modal-popup__input modal-field"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        inputProps={{'data-name': 'bank'}}
                                        onKeyUp={this.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="modal-popup__row">
                                    <TextField
                                        defaultValue={editItem.mfo}
                                        error={this.state.validation.mfo}
                                        label={<FormattedMessage id="app.privateEntsMfo"/>}
                                        className={"modal-popup__input modal-field"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        inputProps={{'data-name': 'mfo'}}
                                        onKeyUp={this.handleChangeInput}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="modal-popup__row">
                                    <InputMask
                                        mask="+380(99)9999999"
                                        disabled={false}
                                        maskChar="_"
                                        defaultValue={editItem.phone}
                                        onChange={(event) => this.handleChangeInput(event)}
                                        inputProps={{'data-name': 'phone'}}
                                    >
                                        {() => <TextField
                                            className={"modal-popup__input modal-field"}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            margin="normal"
                                            inputProps={{'data-name': 'phone'}}
                                            label={<FormattedMessage id="app.privateEntsPhone"/>}
                                            error={this.state.validation.phone}/>
                                        }
                                    </InputMask>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">

                            <div className="col-md-12">
                                <div className="modal-popup__row">
                                    <TextField
                                        defaultValue=""
                                        error={this.state.validation.name}
                                        label={<FormattedMessage id="app.privateEntsName"/>}
                                        className={"modal-popup__input modal-field"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        inputProps={{'data-name': 'first_name'}}
                                        onKeyUp={this.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="modal-popup__row">
                                    <TextField
                                        defaultValue=""
                                        error={this.state.validation.ipn}
                                        label={<FormattedMessage id="app.privateEntsIpn"/>}
                                        className={"modal-popup__input modal-field"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        inputProps={{'data-name': 'ipn'}}
                                        onKeyUp={this.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="modal-popup__row">
                                    <TextField
                                        defaultValue=""
                                        error={this.state.validation.address}
                                        label={<FormattedMessage id="app.privateEntsAddress"/>}
                                        className={"modal-popup__input modal-field"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        inputProps={{'data-name': 'address'}}
                                        onKeyUp={this.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="modal-popup__row">
                                    <TextField
                                        defaultValue=""
                                        error={this.state.validation.account}
                                        label={<FormattedMessage id="app.privateEntsAccount"/>}
                                        className={"modal-popup__input modal-field"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        inputProps={{'data-name': 'account'}}
                                        onKeyUp={this.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="modal-popup__row">
                                    <TextField
                                        defaultValue=""
                                        error={this.state.validation.bank}
                                        label={<FormattedMessage id="app.privateEntsBank"/>}
                                        className={"modal-popup__input modal-field"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        inputProps={{'data-name': 'bank'}}
                                        onKeyUp={this.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="modal-popup__row">
                                    <TextField
                                        defaultValue=""
                                        error={this.state.validation.mfo}
                                        label={<FormattedMessage id="app.privateEntsMfo"/>}
                                        className={"modal-popup__input modal-field"}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        inputProps={{'data-name': 'mfo'}}
                                        onKeyUp={this.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="modal-popup__row">
                                    <InputMask
                                        mask="+380(99)9999999"
                                        disabled={false}
                                        maskChar="_"
                                        onChange={(event) => this.handleChangeInput(event)}
                                    >
                                        {() => <TextField
                                            className={"modal-popup__input modal-field"}
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            margin="normal"
                                            inputProps={{'data-name': 'phone'}}
                                            label={<FormattedMessage id="app.privateEntsPhone"/>}
                                            error={this.state.validation.phone}/>
                                        }
                                    </InputMask>
                                </div>
                            </div>
                        </div>
                    }


                </div>
                <div className="modal-popup__footer">
                    <div className="modal-popup__btn">
                        <Button variant="contained" color="secondary" className={"modal-popup__btn-i"}
                                onClick={this.handleClose}>
                            <FormattedMessage id="app.close"/>
                        </Button>
                        {this.props.isEdit ?
                            <Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                                    onClick={this.editPrivateEntrepreneurHandler}>
                                <FormattedMessage id="app.change"/>
                            </Button>
                            :
                            <Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                                    onClick={this.createPrivateEntrepreneurHandler}>
                                <FormattedMessage id="app.save"/>
                            </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        privateEnts: state.privateEnts.privateEnts
    }
}


function mapDispathToProps(dispath) {
    return {
        createPrivateEntrepreneur: (data) => dispath(createPrivateEntrepreneur(data)),
        editPrivateEntrepreneurSend: (id, data) => dispath(editPrivateEntrepreneurSend(id, data)),
    }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(AddPrivateEntrepreneursPopup));

