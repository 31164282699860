import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import {NavLink, withRouter} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import AddBillsItemPopup from "../../components/ModalPopups/AddBillsItemPopup/AddBillsItemPopup";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import AddProductWaybillsPopup from "../../components/ModalPopups/AddProductWaybillsPopup/AddProductWaybillsPopup";
import Autocomplete from '@material-ui/lab/Autocomplete';
import './ProductWaybills.css'
import TableRows from "../../components/Tables/TableRows/TableRows";
import TableHeader from "../../components/Tables/TableHeader/TableHeader";

import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {fetchDelivery, fetchProducts} from "../../store/action/products";
import connect from "react-redux/es/connect/connect";
import {fetchProductWaybills} from "../../store/action/productWaybills";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TableRowsDelivery from "../../components/Tables/TableRows/TableRowsDelivery";


class ProductWaybills extends Component {

  state = {
    isOpen: false,
    activeTab: 0,

    perPage: 30,
    currentPage: 1,
    perPageDelivery: 30,
    currentPageDelivery: 1,
    search: '',
    dateStart: '',
    dateEnd: '',
  }

  componentDidMount() {
    // this.props.fetchProductWaybills();
    // this.props.fetchDelivery();
    this.fetchDataCreate();

    document.title = this.props.intl.formatMessage({
      id: 'app.overhead',
    });
  }

  reRender = () => {
    this.fetchDataCreate();
  }

  addProductWaybillsHandle = () => {
    this.setState({
      isOpen: true
    })
  }
  closePopupProductWaybills = () => {
    this.setState({
      isOpen: false
    })
  }

  handleChangeTabs = (event, newValue) => {

    this.setState({activeTab: newValue})

    setTimeout(() => {
      this.fetchDataCreate();
    })

  }


  fetchDataCreate = (filter = false) => {
    let data = '',
      curPage = this.state.currentPage,
      perPage = this.state.perPage,
      dateStart = this.state.dateStart !== '' ? this.state.dateStart : null,
      dateEnd = this.state.dateEnd !== '' ? this.state.dateEnd : null,
      search = this.state.search !== '' ? this.state.search : null;

    if(filter) {
      curPage = 1;
      this.setState({currentPage: 1});
    }

    let tableDate = '&between=created_at';
    if (this.state.activeTab === 0) {
      curPage = this.state.currentPageDelivery;
      perPage = this.state.perPageDelivery;
      tableDate = '&between=delivery_date';
    }

    data = data + `?page=${curPage}&limit=${perPage}`;

    // between=created_at,2020-09-16:2020-09-16
    let date = '';

    if (dateStart !== null) date = date + `${tableDate},${dateStart}`;

    if (dateEnd !== null) {
      if (dateStart === null) {
        date = date + `${tableDate},:${dateEnd}`;
      } else {
        date = date + `:${dateEnd}`;
      }
    }

    data = data + date;

    // if (search !== null) data = data + `&filter=first_name:${search}`;

    if (this.state.activeTab === 0) {
      this.props.fetchDelivery(data);
    } else {
      this.props.fetchProductWaybills(data);
    }
  }

  filterReset = () => {
    this.setState({
      search: '',
      dateStart: '',
      dateEnd: ''
    })

    setTimeout(() => this.fetchDataCreate(true))
  }

  handleChangePage = (event, newPage) => {
    this.setState({currentPage: newPage + 1})
    setTimeout(() => this.fetchDataCreate())
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({perPage: parseInt(event.target.value, 10)})
    this.setState({currentPage: 1})
    setTimeout(() => this.fetchDataCreate())
  }


  handleChangePageDelivery = (event, newPage) => {
    this.setState({currentPageDelivery: newPage + 1})
    setTimeout(() => this.fetchDataCreate())
  }

  handleChangeRowsPerPageDelivery = (event) => {

    this.setState({perPageDelivery: parseInt(event.target.value, 10)})
    this.setState({currentPageDelivery: 1})
    setTimeout(() => this.fetchDataCreate())
  }

  render() {
    const top100Films = [
      {title: 'Олег Побережный'},
      {title: 'The Godfather'},
      {title: 'The Godfather: Part II'},
      {title: 'The Dark Knight'},
    ];


    let headerTable = [
      {
        colspan: '0',
        align: 'left',
        item: ''
      },
      {
        colspan: '0',
        align: 'left',
        item: 'id'
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.date"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.cash"/>
      },

      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.status"/>
      },
      {
        colspan: '0',
        align: 'right',
        item: ''
      }
    ];

    let headerDelivery = [
      {
        colspan: '0',
        align: 'left',
        item: ''
      },
      {
        colspan: '0',
        align: 'left',
        item: 'Номер накладной'
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.date"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.supplier"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.status"/>
      },
      {
        colspan: '0',
        align: 'right',
        item: ''
      }
    ];

    let headerUnderTable = [
      {
        colspan: '0',
        align: 'left',
        item: 'id'
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.article"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.name"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.count"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.price"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.stock"/>
      },
    ];

    let headerUnderTableDelivery = [
      {
        colspan: '0',
        align: 'left',
        item: 'id'
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.article"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.name"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.count"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.price"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.purchasePrice"/>
      },
    ];

    return (
      <div className="waybills">
        <div className="page-title">
           <span className={'header__name'}>
                <FormattedMessage id="app.overhead"/>
            </span>
        </div>

        <div className="filter-head">

          <div className="filter-head__left">
            <div className="site-filter">

              <TextField
                type="date"
                value={this.state.dateStart}
                label={<FormattedMessage id="app.selectDateFrom"/>}
                onChange={(event) => {
                  this.setState({dateStart: event.target.value})
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                type="date"
                label={<FormattedMessage id="app.selectDateFor"/>}
                value={this.state.dateEnd}
                onChange={(event) => {
                  this.setState({dateEnd: event.target.value})
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>


            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.filterReset}>
              <FormattedMessage id="app.reset"/>
            </Button>
            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={() => this.fetchDataCreate( true)}>
              <FormattedMessage id="app.search"/>
            </Button>
          </div>


          <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.addProductWaybillsHandle}>
            <FormattedMessage id="app.addNote"/>
          </Button>

          {this.state.isOpen ?
            <PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopupProductWaybills}>
              <AddProductWaybillsPopup reRender={this.reRender}/>
            </PopupWrapper>
            : ''}
        </div>

        <Paper>
          <Tabs
            value={this.state.activeTab}
            onChange={(event, newValue) => this.handleChangeTabs(event, newValue)}
            indicatorColor="primary"
            textColor="primary"
            style={{padding: '0 20px'}}
          >
            <Tab label={<FormattedMessage id="app.waybillsPurchasePrice"/>}/>
            <Tab label={<FormattedMessage id="app.waybillsSellingPrice"/>}/>
          </Tabs>
        </Paper>


        {this.state.activeTab === 0 ?
          <div className="page-section">
            <div className="page-section__content">

              <TableContainer className={'clients-table' + (this.props.loading ? ' loading-table' : '')}>
                <Table size="small" aria-label="a dense table">
                  <TableHeader rows={headerDelivery}/>

                  <TableBody>

                    {
                      // this.props.delivery.map((row, key) => {
                      this.props.delivery.hasOwnProperty('data') ?
                        Object.keys(this.props.delivery.data).map((row, key) => {
                          return (

                            <TableRowsDelivery reRender={this.reRender} key={key} row={this.props.delivery.data[row]}
                                               button={true}
                                               undertable={true} headerUnderTable={headerUnderTableDelivery}/>

                          )
                        })
                        :
                        null
                    }

                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 30, 50, 100]}
                        colSpan={8}
                        count={this.props.delivery.total || 0}
                        rowsPerPage={this.state.perPageDelivery }
                        page={this.state.currentPageDelivery - 1}
                        labelRowsPerPage={<FormattedMessage id="app.rowsPerPage"/>}
                        SelectProps={{
                          inputProps: {'aria-label': 'rows per page'},
                          native: true,
                        }}
                        onChangePage={this.handleChangePageDelivery}
                        onChangeRowsPerPage={this.handleChangeRowsPerPageDelivery}
                      />
                    </TableRow>
                  </TableFooter>

                </Table>
              </TableContainer>
            </div>
          </div>
          :
          <div className="page-section">
            <div className="page-section__content">

              <TableContainer className={'clients-table'}>
                <Table size="small" aria-label="a dense table">
                  <TableHeader rows={headerTable}/>

                  <TableBody>

                    {
                      // this.props.productWaybills.map((row, key) => {
                      this.props.productWaybills.hasOwnProperty('data') ?
                        Object.keys(this.props.productWaybills.data).map((row, key) => {
                          if(this.props.productWaybills.data[row].state === "DELETED") {
                            return;
                          }
                          return (

                            <TableRows reRender={this.reRender} key={key} row={this.props.productWaybills.data[row]} button={true} undertable={true}
                                       headerUnderTable={headerUnderTable}/>

                          )
                        })
                        :
                        null
                    }

                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 30, 50, 100]}
                        colSpan={8}
                        count={this.props.productWaybills.total || 0}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPage - 1}
                        labelRowsPerPage={<FormattedMessage id="app.rowsPerPage"/>}
                        SelectProps={{
                          inputProps: {'aria-label': 'rows per page'},
                          native: true,
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>

                </Table>
              </TableContainer>
            </div>
          </div>
        }


      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    loading: state.products.loading,
    productWaybills: state.productWaybills.productWaybills,
    delivery: state.products.delivery,
  }

}

function mapDispathToProps(dispath) {
  return {
    fetchProductWaybills: (data) => dispath(fetchProductWaybills(data)),
    fetchDelivery: (data) => dispath(fetchDelivery(data)),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(withRouter(injectIntl(ProductWaybills)));
