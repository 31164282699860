import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import './CreateTaskPopup.css';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import OutsideAlerter from "../../../hoc/OutsideCliker/OutsideCliker";
import {FormattedMessage, injectIntl} from "react-intl";
import {createTask} from "../../../store/action/projects";
import connect from "react-redux/es/connect/connect";
import {fetchMasters, fetchWorkers} from "../../../store/action/settings";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../../services/validationService";

class CreateTaskPopup extends Component {

  state = {

    name: '',
    description: '',
    executor: '',
    dateTaskCreate: '',
    status: 1,
    executor_id: '',

    validation: {
      name: false,
      description : false,
      user_id: false,
      finish_before : false,
    }

  }

  componentDidMount() {
    // this.props.fetchMasters();
    this.props.fetchWorkers();
  }

  handleChangeName = (event) => {
    this.setState({name: event.target.value});
  }

  handleChangeDescription = (event) => {
    this.setState({description: event.target.value});
  }

  handleChangeExecutor = (event) => {

    this.setState({
      executor: event.name,
      executor_id: event.id,
    })
  }

  handleChangeDateTaskCreate = (event) => {
    this.setState({dateTaskCreate: event.target.value});
  }

  handleChangeStatus = (event) => {
    this.setState({
      status: event.target.value,
    })
  }

  handleClose = () => {
    this.props.handleClose();
  }

  handleCreateTask = () => {
    let data = {
      name: this.state.name,
      description: this.state.description,
      user_id: this.state.executor_id,
      finish_before: this.state.dateTaskCreate,
      task_status: 'ACTIVE',
      is_draft: 1
    }

    let validation = fieldEmpty(data);

    if (!validation.isValidation) {
      this.setState({
        validation: validation.validation
      });
      return;
    }


    this.props.createTask(data).then((response) => {
      if (response.status === 200) {
        this.props.handleClose();
        this.props.reRender();
      }
    })
  }

  handleCreateDraft = () => {
    let data = {
      name: this.state.name,
      description: this.state.description,
      user_id: this.state.executor_id,
      finish_before: this.state.dateTaskCreate,
      task_status: 'DRAFT',
      is_draft: 1
    }

    let validation = fieldEmpty(data);

    if (!validation.isValidation) {
      this.setState({
        validation: validation.validation
      });
      return;
    }

    this.props.createTask(data).then((response) => {
      if (response.status === 200) {
        this.props.handleClose();
        this.props.reRender();
      }
    })
  }

  doneTask = () => {
    let data = {
      new_status: 'COMPLETED'
    };

    this.props.performTask(this.props.dataTask.indexTask, data)
  }

  inActiveTask = () => {
    let data = {
      new_status: 'ACTIVE'
    };
    this.props.performTask(this.props.dataTask.indexTask, data)
  }


  render() {
    const placeholderDesc = this.props.intl.formatMessage({
      id: 'app.description',
    });


    let emloyee = '';
    let readOnly;
    if(this.props.isEdit){
      this.props.workers.map((item)=>{
        if (item.id === this.props.dataTask.employeeTask) {
          emloyee = item.name;
        }
      });
      readOnly = Boolean(true);
    } else {
      readOnly = Boolean(false);
    }






    return (

      <div className="modal-popup__window container">
        <div className="modal-popup__header">
                <span>
                  {this.props.isEdit ? this.props.dataTask.nameTask : <FormattedMessage id="app.newTask"/>}
                </span>
        </div>
        <div className="modal-popup__body">
          <div className="task-create__name">
            {this.props.isEdit ?
              <TextField className={"task-create__name-input"} id="standard-basic"
                         label={<FormattedMessage id="app.enterTaskName"/>}
                         error={this.state.validation.name}
                         value={this.props.dataTask.nameTask}/> :
              <TextField className={"task-create__name-input"}  onChange={this.handleChangeName}
                         error={this.state.validation.name}
                         label={<FormattedMessage id="app.enterTaskName"/>}/>
            }

          </div>
          <div className="task-create__description">
                  <textarea name="" placeholder={placeholderDesc}
                            defaultValue={this.props.isEdit ? this.props.dataTask.descTask : ''}
                            className={this.state.validation.description ? 'error-textarea' : ''}
                            onChange={this.handleChangeDescription}
                            readOnly={readOnly}>

                  </textarea>
          </div>
          <div className="task-create__row task-create__executor">
            {this.props.isEdit ?
              <TextField className={"task-create__name-input"} id="standard-basic"
                         label={<FormattedMessage id="app.enterTaskName"/>}
                         value={emloyee}
                         error={this.state.validation.executor}
                         InputLabelProps={{
                            readOnly: readOnly
                         }}/>
              :
              <Autocomplete
                freeSolo
                style={{'width': 340}}
                className={'task-autocomplite'}
                options={this.props.workers}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  this.handleChangeExecutor(newValue)
                }}
                renderInput={(params) => <TextField {...params}
                                                    label={<FormattedMessage id="app.responsible"/>}
                                                    error={this.state.validation.user_id}
                                                    inputProps={{
                                                      ...params.inputProps,
                                                      'data-name': 'master'
                                                    }}
                />}
              />
            }
          </div>
          <div className="task-create__row task-create__time">
            <span><FormattedMessage id="app.deadline"/>:</span>
            <TextField
              id="datetime-local"
              type="datetime-local"
              value={this.props.isEdit ? this.props.dataTask.task_end.replace(' ', 'T') : null}
              error={this.state.validation.finish_before}
              InputLabelProps={{
                shrink: true,
                readOnly: readOnly,
                step: 1000, // 5 min
              }}
              onChange={this.handleChangeDateTaskCreate}
            />

          </div>


        </div>
        <div className="modal-popup__footer">
          <div className="task-create__btn">
            {this.props.isEdit ?
              <div>
                {this.props.completed ?
                  <div>
                    <Button variant="contained" color="secondary" className={"task-create__drafts"}
                            onClick={this.handleClose}>
                      <FormattedMessage id="app.close"/>
                    </Button>
                    <Button variant="contained" color="primary" className={"task-create__save"}
                            onClick={this.inActiveTask}>
                      <FormattedMessage id="app.inActive"/>
                    </Button>
                  </div>
                  :
                  null
                }
                {this.props.draft ?
                  <div>
                    <Button variant="contained" color="secondary" className={"task-create__drafts"}
                            onClick={this.handleClose}>
                      <FormattedMessage id="app.close"/>
                    </Button>
                    <Button variant="contained" color="primary" className={"task-create__save"}
                            onClick={this.inActiveTask}>
                      <FormattedMessage id="app.inActive"/>
                    </Button>
                  </div>
                  :
                  null
                }
                {this.props.active ?
                  <div>
                    <Button variant="contained" color="secondary" className={"task-create__drafts"}
                            onClick={this.handleClose}>
                      <FormattedMessage id="app.close"/>
                    </Button>
                    <Button variant="contained" color="primary" className={"task-create__save"}
                            onClick={this.doneTask}>
                      <FormattedMessage id="app.performed"/>
                    </Button>
                  </div>
                  :
                  null
                }
              </div>
              :
              <div>
                <div>
                  <Button variant="contained" color="secondary" className={"task-create__drafts"}
                          onClick={this.handleClose}>
                    <FormattedMessage id="app.close"/>
                  </Button>
                  <Button variant="contained" color="primary" className={"task-create__drafts"}
                          onClick={() => this.handleCreateDraft()}>
                    <FormattedMessage id="app.toDrafts"/>
                  </Button>
                  <Button variant="contained" color="primary" className={"task-create__save"}
                          onClick={() => this.handleCreateTask()}>
                    <FormattedMessage id="app.createTask"/>
                  </Button>
                </div>
              </div>
            }


          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    workers: state.settings.workers
  }
}

function mapDispathToProps(dispath) {
  return {
    createTask: (data) => dispath(createTask(data)),
    fetchMasters: () => dispath(fetchMasters()),
    fetchWorkers: () => dispath(fetchWorkers())
  }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(CreateTaskPopup))
