import React, {Component} from 'react'
import Papa from "papaparse";
import * as XLSX from "xlsx";

import {FormattedMessage, injectIntl} from "react-intl";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import './Reports.css'
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import TableHeader from "../../components/Tables/TableHeader/TableHeader.js";
import connect from "react-redux/es/connect/connect";
import {exportReports, fetchBills, fetchRecipient} from "../../store/action/bills";
import ShowBillsItemPopup from "../../components/ModalPopups/ShowBillsItemPopup/ShowBillsItemPopup";
import {fetchExpenses} from "../../store/action/expenses";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {exportProducts} from "../../store/action/products";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {getRecipienName, getRecipientType} from "../../services/recipientHelper";

class Reports extends Component {

    state = {
        isOpen: false,
        perPage: 30,
        currentPage: 1,
        dateStart: '',
        dateEnd: '',
        search: '',
        item: '',
        activeTab: 0,
        recipient: [],
    }

    componentDidMount() {
        this.props.fetchRecipient().then((response) => {
            this.setState({
                recipient: response.data
            })
        });
        // this.props.fetchExpenses();
        this.fetchDataCreate();

        document.title = this.props.intl.formatMessage({
            id: 'app.reports',
        });
    }

    addBillsItemHandle = () => {
        this.setState({
            isOpen: true
        })
    }
    closePopupAddBillsItem = () => {
        this.setState({
            isOpen: false
        })
    }

    showBillHandle = (item) => {
        this.setState({
            item: item,
            isOpen: true
        })
    }

    fetchDataCreate = (noCash = false, filter = false) => {
        let data = '',
            dataExpanses = '',
            curPage = this.state.currentPage,
            perPage = this.state.perPage,
            dateStart = this.state.dateStart !== '' ? this.state.dateStart : null,
            dateEnd = this.state.dateEnd !== '' ? this.state.dateEnd : null,
            search = this.state.search !== '' ? this.state.search : null;

        if (filter) {
            curPage = 1;
            this.setState({currentPage: 1});
        }

        // data = data + `?paginate=${curPage},${perPage}`;
        // dataExpanses = dataExpanses + `?paginate=${curPage},${perPage}`;

        data = data + `?page=${curPage}&limit=${perPage}`;
        dataExpanses = dataExpanses + `?page=${curPage}&limit=${perPage}`;

        // between=created_at,2020-09-16:2020-09-16
        let date = '';
        let dateExpanses = '';

        if (dateStart !== null) {
            date = date + `&between=updated_at,${dateStart}`;
            dateExpanses = dateExpanses + `&between=date,${dateStart}`;
        }

        if (dateEnd !== null) {
            if (dateStart === null) {
                date = date + `&between=updated_at,:${dateEnd}`;
                dateExpanses = dateExpanses + `&between=date,:${dateEnd}`;
            } else {
                date = date + `:${dateEnd}`;
                dateExpanses = dateExpanses + `:${dateEnd}`;
            }
        }

        data = data + date;
        dataExpanses = dataExpanses + dateExpanses;

        // if (search !== null) data = data + `&filter=first_name:${search}`;

        // console.log(noCash);
        if (this.state.activeTab === 1) {
            data = data + `&filter=recipient_id:1`;
            dataExpanses = dataExpanses + `&filter=recipient_id:1`;
        }

        if (this.state.activeTab === 2) {
            data = data + `&filter=recipient_id:2`;
            dataExpanses = dataExpanses + `&filter=recipient_id:2`;
        }

        if (this.state.activeTab === 3) {
            data = data + `&filter=recipient_id:3`;
            dataExpanses = dataExpanses + `&filter=recipient_id:3`;
        }


        if (search !== null) {
            if (this.state.activeTab === 0) {
                data = data + `&filter=`
            } else {
                data = data + `,`
            }
            data = data + `brand:${search}`;
        }

        this.props.fetchBills(data);
        this.props.fetchExpenses(dataExpanses);
    }

    filterReset = () => {
        this.setState({
            search: '',
            dateStart: '',
            dateEnd: ''
        })

        setTimeout(() => this.fetchDataCreate(false, true))
    }

    handleChangePage = (event, newPage) => {
        this.setState({currentPage: newPage + 1})
        setTimeout(() => this.fetchDataCreate())
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({perPage: parseInt(event.target.value, 10)})
        this.setState({currentPage: 1})
        setTimeout(() => this.fetchDataCreate())
    }

    exportReports = () => {
        let data = '',
            dataExpanses = '',
            curPage = this.state.currentPage,
            perPage = this.state.perPage,
            dateStart = this.state.dateStart !== '' ? this.state.dateStart : null,
            dateEnd = this.state.dateEnd !== '' ? this.state.dateEnd : null,
            search = this.state.search !== '' ? this.state.search : null,
            flagRecipient = false;

        // data = data + `?paginate=${curPage},${perPage}`;
        // dataExpanses = dataExpanses + `?paginate=${curPage},${perPage}`;

        data = data + `?page=${curPage}&limit=${perPage}`;
        dataExpanses = dataExpanses + `?page=${curPage}&limit=${perPage}`;

        // between=created_at,2020-09-16:2020-09-16
        let date = '';
        let dateExpanses = '';

        if (dateStart !== null) {
            date = date + `&between=updated_at,${dateStart}`;
            dateExpanses = dateExpanses + `&between=date,${dateStart}`;
        }

        if (dateEnd !== null) {
            if (dateStart === null) {
                date = date + `&between=updated_at,:${dateEnd}`;
                dateExpanses = dateExpanses + `&between=date,:${dateEnd}`;
            } else {
                date = date + `:${dateEnd}`;
                dateExpanses = dateExpanses + `:${dateEnd}`;
            }
        }

        data = data + date;
        dataExpanses = dataExpanses + dateExpanses;

        // if (search !== null) data = data + `&filter=first_name:${search}`;


        if (this.state.activeTab === 1) {
            data = data + `&filter=recipient_id:1`;
            dataExpanses = dataExpanses + `&filter=recipient_id:1`;
            flagRecipient = true;
        }

        if (this.state.activeTab === 2) {
            data = data + `&filter=recipient_id:2`;
            dataExpanses = dataExpanses + `&filter=recipient_id:2`;
            flagRecipient = true;
        }

        if (this.state.activeTab === 3) {
            data = data + `&filter=recipient_id:3`;
            dataExpanses = dataExpanses + `&filter=recipient_id:3`;
            flagRecipient = true;
        }

        if (search !== null) {
            if (flagRecipient) {
                data = data + `,brand:${search}`;
            } else {
                data = data + `&filter=brand:${search}`;
            }

        }


        this.props.exportReports(data)
            .then((response) => {

                // Парсимо CSV дані з використанням papaparse
                Papa.parse(response, {
                    complete: (result) => {

                        // Конвертуємо дані в робочий лист Excel
                        const ws = XLSX.utils.json_to_sheet(result.data);

                        ws['!cols'] =
                            [
                                {wch: 20},
                                {wch: 15},
                                {wch: 15},
                                {wch: 20},
                                {wch: 20},
                                {wch: 20},
                                {wch: 20},
                            ]

                        // Створюємо нову робочу книгу
                        const wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, "Reports");

                        // Експортуємо файл
                        XLSX.writeFile(wb, "reports.xlsx");
                    },
                    header: true,  // Оскільки у вас немає заголовків
                    skipEmptyLines: true // Пропускаємо порожні рядки
                });
            });

    }

    handleChangeTabs = (event, newValue) => {
        this.setState({activeTab: newValue})

        setTimeout(() => {
            if (newValue === 0) {
                this.fetchDataCreate();
            }
            if (newValue === 1) {
                this.fetchDataCreate('1', true);
            }
            if (newValue === 2) {
                this.fetchDataCreate('2', true);
            }
            if (newValue === 3) {
                this.fetchDataCreate('3', true);
            }
        })
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.fetchDataCreate(false, true);
        }
    }

    render() {

        let headerTable = [
            // {
            //   colspan: '0',
            //   align: 'left',
            //   item: <FormattedMessage id="app.vendorCode"/>
            // },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.date"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.type"/>
            },
            // {
            //   colspan: '0',
            //   align: 'left',
            //   item: <FormattedMessage id="app.article"/>
            // },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.carBrand"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.payments"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.income"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.expenses"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.amount"/>
            },
            {
                colspan: '0',
                align: 'right',
                item: ''
            },
        ];

        let headerTable2 = [
            // {
            //   colspan: '0',
            //   align: 'left',
            //   item: <FormattedMessage id="app.vendorCode"/>
            // },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.date"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.type"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.name"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.payments"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: ''
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.expenses"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: ''
            },
            {
                colspan: '0',
                align: 'right',
                item: ''
            },
        ]


        return (
            <div className="recording">
                <div className="page-title">
           <span className={'header__name'}>
              <FormattedMessage id="app.reports"/>
            </span>
                </div>
                <div className="filter-head">

                    <div className="site-filter">
                        <div className="filter-head__left">
                            <div className="site-filter">

                                <TextField
                                    type="date"
                                    value={this.state.dateStart}
                                    label={<FormattedMessage id="app.selectDateFrom"/>}
                                    onChange={(event) => {
                                        this.setState({dateStart: event.target.value})
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    type="date"
                                    label={<FormattedMessage id="app.selectDateFor"/>}
                                    value={this.state.dateEnd}
                                    onChange={(event) => {
                                        this.setState({dateEnd: event.target.value})
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>

                            <div className="filter-client-wrap">
                                <TextField
                                    className="filter-client-select"
                                    value={this.state.search}
                                    onChange={(event) => this.setState({search: event.target.value})}
                                    size="small"
                                    onKeyDown={(event) => this._handleKeyDown(event)}
                                    label={<FormattedMessage id="app.search"/>}
                                    variant="outlined"/>
                            </div>

                            <Button variant="contained" className="yellow-btn recording__add-btn"
                                    onClick={this.filterReset}>
                                <FormattedMessage id="app.reset"/>
                            </Button>
                            <Button variant="contained" className="yellow-btn recording__add-btn"
                                    onClick={() => this.fetchDataCreate(false, true)}>
                                <FormattedMessage id="app.search"/>
                            </Button>
                            <Button variant="contained" className=" recording__add-btn" onClick={this.exportReports}>
                                Скачать отчет
                            </Button>
                        </div>
                    </div>


                    {this.state.isOpen ?
                        <PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopupAddBillsItem}>
                            <ShowBillsItemPopup item={this.state.item}/>
                        </PopupWrapper>
                        : ''}
                </div>

                <Paper>
                    <Tabs
                        value={this.state.activeTab}
                        onChange={(event, newValue) => this.handleChangeTabs(event, newValue)}
                        indicatorColor="primary"
                        textColor="primary"
                        style={{padding: '0 20px'}}
                    >
                        <Tab label={<FormattedMessage id="app.all"/>}/>
                        <Tab label={<FormattedMessage id="app.statusCash"/>}/>
                        {
                            this.state.recipient.map((item, index) => {
                                return (
                                    <Tab key={index} label={item.name} value={item.id}/>
                                )
                            })
                        }

                        {/*<Tab label={<FormattedMessage id="app.cash"/>}/>*/}
                    </Tabs>
                </Paper>

                <div className="page-section">
                    <div className="page-section__content">
                        <b className={'name-rep'}><FormattedMessage id="app.incomes"/></b>
                        <TableContainer className={'reports-table' + (this.props.loading ? ' loading-table' : '')}>
                            <Table size="small" aria-label="a dense table">
                                <TableHeader rows={headerTable}/>
                                <TableBody>
                                    {
                                        this.props.bills.hasOwnProperty('data') ?
                                            // this.props.bills.map((item, index) => {
                                            Object.keys(this.props.bills.data).map((item, index) => {
                                                if (item === 'total_invoice_income' || item === 'total_invoice_total') return;
                                                return (
                                                    <TableRow className={'site-table-row'} key={index}>
                                                        {/*<TableCell align="left" className={'rep-1'}>{this.props.bills.data[item].id}</TableCell>*/}
                                                        <TableCell align="left"
                                                                   className={'rep-2'}>
                                                            {this.props.bills.data[item].updated_at}

                                                            {/*{this.props.bills.data[item].hasOwnProperty('doc_type') ?*/}

                                                            {/*this.props.bills.data[item].date_kassa*/}
                                                            {/*:*/}
                                                            {/*this.props.bills.data[item].updated_date*/}
                                                            {/*}*/}
                                                        </TableCell>
                                                        <TableCell align="left" className={'rep-3'}>
                                                            {
                                                                this.props.bills.data[item].hasOwnProperty('sale_document') && this.props.bills.data[item].sale_document !== null ?
                                                                    <FormattedMessage
                                                                        id="app.waybillsSellingPriceItem"/> :
                                                                    null
                                                            }
                                                            {
                                                                this.props.bills.data[item].work_order !== null && this.props.bills.data[item].hasOwnProperty('work_order') ?
                                                                    <FormattedMessage id="app.outfit"/> :
                                                                    null
                                                            }
                                                            {
                                                                this.props.bills.data[item].sale_document === null && this.props.bills.data[item].work_order === null ?
                                                                    this.props.bills.data[item].description :
                                                                    null
                                                            }
                                                        </TableCell>

                                                        <TableCell align="left" className={'rep-4'}>
                                                            {this.props.bills.data[item].brand !== null ?
                                                                this.props.bills.data[item].brand
                                                                : null}

                                                        </TableCell>

                                                        <TableCell align="left" className={'rep-5'}>
                                                            {
                                                                this.props.bills.data[item].sale_document === null && this.props.bills.data[item].work_order === null ?
                                                                    this.props.bills.data[item].doc_payment_type :
                                                                    getRecipientType(this.props.bills.data[item].recipient_id)
                                                            }
                                                            &nbsp;
                                                            {
                                                                this.state.recipient.length > 0 ?
                                                                    getRecipienName(this.state.recipient, this.props.bills.data[item].recipient_id)
                                                                    :
                                                                    null
                                                            }

                                                        </TableCell>

                                                        <TableCell align="left"
                                                                   className={'rep-6'}>{parseFloat(this.props.bills.data[item].invoice_income).toFixed(2)}</TableCell>
                                                        <TableCell align="left" className={'rep-7'}></TableCell>
                                                        <TableCell align="left"
                                                                   className={'rep-8'}>{parseFloat(this.props.bills.data[item].invoice_total).toFixed(2)}</TableCell>
                                                        <TableCell align="right" className={'rep-9'}>
                                                            {this.props.bills.data[item].client_id !== null || this.props.bills.data[item].hasOwnProperty('sale_document') ?
                                                                <Button variant="contained" size="small" color="primary"
                                                                        onClick={() => this.showBillHandle(this.props.bills.data[item])}>
                                                                    <FormattedMessage id="app.detail"/>
                                                                </Button>
                                                                :
                                                                null
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }) : null}

                                    <TableRow className={'site-table-row'}>
                                        <TableCell colSpan={4} align="left"><strong><FormattedMessage
                                            id="app.total"/></strong></TableCell>
                                        <TableCell
                                            align="left"><strong>{this.props.bills.hasOwnProperty('data') ? parseFloat(this.props.bills.data.total_invoice_income).toFixed(2) : null} UAH</strong>
                                        </TableCell>
                                        <TableCell
                                            align="left">
                                            <strong>0 UAH</strong>
                                        </TableCell>
                                        <TableCell
                                            align="left"><strong>{this.props.bills.hasOwnProperty('data') ? parseFloat(this.props.bills.data.total_invoice_total).toFixed(2) : null} UAH</strong>
                                        </TableCell>
                                        <TableCell align="left"> </TableCell>
                                        {/*<TableCell align="left"> </TableCell>*/}
                                    </TableRow>


                                </TableBody>
                            </Table>
                        </TableContainer>

                        <b className={'name-rep'}><FormattedMessage id="app.expenses"/></b>
                        <TableContainer className={'reports-table' + (this.props.loading ? ' loading-table' : '')}>
                            <Table size="small" aria-label="a dense table">
                                <TableHeader rows={headerTable2}/>
                                <TableBody>
                                    {/*{this.props.expenses.length > 0 ?*/}
                                    {/*this.props.expenses.map((item, index) => {*/}
                                    {this.props.expenses.hasOwnProperty('data') ?
                                        Object.keys(this.props.expenses.data).map((item, index) => {
                                            // this.props.expenses.data.map((item, index) => {
                                            if (item === 'totalPrice') return;
                                            return (
                                                <TableRow className={'products-table-row'} key={index}>
                                                    {/*<TableCell align="left" className={'rep-1'}> {this.props.expenses.data[item].id} </TableCell>*/}
                                                    <TableCell align="left"
                                                               className={'rep-2'}> {this.props.expenses.data[item].date} </TableCell>
                                                    <TableCell align="left" className={'rep-3'}>
                                                        {this.state.recipient.length > 0 ?
                                                            <React.Fragment>
                                                                {this.state.recipient.map((recipient, index) => {
                                                                    if (recipient.id === this.props.expenses.data[item].recipient_id) {
                                                                        return (
                                                                            recipient.name
                                                                        )
                                                                    }
                                                                })}
                                                            </React.Fragment>


                                                            :
                                                            null
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left"
                                                               className={'rep-4'}> {this.props.expenses.data[item].expenses_type.name} </TableCell>
                                                    <TableCell align="left" className={'rep-5'}> </TableCell>
                                                    <TableCell align="left" className={'rep-6'}> </TableCell>
                                                    <TableCell align="left"
                                                               className={'rep-7'}> {this.props.expenses.data[item].price} </TableCell>
                                                    <TableCell align="left" className={'rep-8'}> </TableCell>
                                                    <TableCell align="right" className={'rep-9'}>
                                                        <Button variant="contained"
                                                                style={{'opacity': '0', 'pointerEvents': 'none'}}
                                                                size="small"
                                                                color="primary">
                                                            <FormattedMessage id="app.detail"/>
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        null
                                    }

                                    <TableRow className={'site-table-row'}>
                                        <TableCell colSpan={4} align="left"><strong><FormattedMessage
                                            id="app.total"/></strong></TableCell>
                                        <TableCell
                                            align="left">
                                            {/*<strong>0 UAH</strong>*/}
                                            {/*<strong>{this.props.bills.hasOwnProperty('data') ? parseInt(this.props.bills.data.total_invoice_income).toFixed(2) : null} UAH</strong>*/}
                                        </TableCell>
                                        <TableCell
                                            align="left">
                                            <strong>{this.props.expenses.hasOwnProperty('data') ? parseFloat(this.props.expenses.data.totalPrice).toFixed(2) : null} UAH</strong>
                                        </TableCell>
                                        <TableCell
                                            align="left">
                                            {/*<strong>0 UAH</strong>*/}
                                            {/*<strong>{this.props.bills.hasOwnProperty('data') ? this.props.bills.data.total_invoice_total : null} UAH</strong>*/}
                                        </TableCell>
                                        <TableCell align="left"> </TableCell>
                                        {/*<TableCell align="left"> </TableCell>*/}
                                    </TableRow>


                                    <TableRow className={'site-table-row reports-kassa-row'}>
                                        <TableCell colSpan={7} align="left"><strong><FormattedMessage
                                            id="app.cassa"/></strong></TableCell>
                                        <TableCell colSpan={1} align="left">
                                            <strong>
                                                {this.props.bills.hasOwnProperty('data') ?
                                                    this.props.expenses.hasOwnProperty('data') ?
                                                        parseFloat(this.props.bills.data.total_invoice_total - this.props.expenses.data.totalPrice).toFixed(2)
                                                        : null
                                                    : null
                                                } UAH
                                            </strong>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow className={'site-table-row'}>
                                        <TableCell colSpan={7} align="left"><strong><FormattedMessage
                                            id="app.netProfit"/></strong></TableCell>
                                        <TableCell colSpan={1} align="left">
                                            <strong>
                                                {this.props.bills.hasOwnProperty('data') ?
                                                    this.props.expenses.hasOwnProperty('data') ?
                                                        (parseFloat(this.props.bills.data.total_invoice_income - this.props.expenses.data.totalPrice).toFixed(2))
                                                        : null
                                                    : null
                                                } UAH
                                            </strong>
                                        </TableCell>
                                    </TableRow>

                                </TableBody>

                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 30, 50, 100]}
                                            colSpan={8}
                                            count={this.props.bills.total > this.props.expenses.total ? this.props.bills.total || 0 : this.props.expenses.total || 0}
                                            rowsPerPage={this.state.perPage}
                                            page={this.state.currentPage - 1}
                                            labelRowsPerPage={<FormattedMessage id="app.rowsPerPage"/>}
                                            SelectProps={{
                                                inputProps: {'aria-label': 'rows per page'},
                                                native: true,
                                            }}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    </TableRow>
                                </TableFooter>

                            </Table>

                        </TableContainer>

                    </div>
                </div>
                {/*<br/><br/>*/}
                {/*<div className="page-section">*/}
                {/*<div className="page-section__content">*/}


                {/*</div>*/}
                {/*</div>*/}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        loading: state.bills.loading,
        bills: state.bills.bills,
        expenses: state.expenses.expenses,
    }

}

function mapDispathToProps(dispath) {
    return {
        fetchBills: (data) => dispath(fetchBills(data)),
        fetchRecipient: () => dispath(fetchRecipient()),
        fetchExpenses: (data) => dispath(fetchExpenses(data)),
        exportReports: (data) => dispath(exportReports(data)),
    }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(Reports));
