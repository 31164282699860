import React, {Component} from 'react'
import {FormattedMessage} from "react-intl";

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {NavLink} from "react-router-dom";
import Button from "@material-ui/core/Button";
import TableHeader from "../TableHeader/TableHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {fetchProductWaybills} from "../../../store/action/productWaybills";
import connect from "react-redux/es/connect/connect";
import {
  completeSaleDocument,
  fetchSalePDF,
  fetchSalePDFfetchSalePDFNoCash,
  removeSaleDocument
} from "../../../store/action/products";
import {fetchOutfitsPDF} from "../../../store/action/outfits";


class TableRows extends Component {


  state = {
    open: false,
  }

  handleToggleSubtable = () => {
    this.setState({
      open: !this.state.open
    })
  }

  handlePerform = (id) => {
    this.props.completeSaleDocument(id).then((response) => {
      // console.log(response);
      this.props.reRender();
    })
  }

  handleRemove = (id) => {
    this.props.removeSaleDocument(id).then((response) => {
      // console.log(response);
      this.props.reRender();
    })
  }

  handlePrint = (id) => {
    this.props.fetchSalePDF(id).then((response) => {
      // console.log(response);
    })
  }

  handlePrintNoCash = (id) => {
    this.props.fetchSalePDFfetchSalePDFNoCash(id).then((response) => {
      // console.log(response);
    })
  }


  render() {
    let that = this;
    // console.log(this.props.row.details);
    return (

      <React.Fragment>

        <TableRow>


          {this.props.undertable ?
            <TableCell style={{width: 50}}>
              <IconButton aria-label="expand row" size="small" onClick={() => that.handleToggleSubtable()}>
                {that.state.open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
              </IconButton>
            </TableCell>
            :
            null
          }

          <TableCell align={'left'}>
            {this.props.row.id}
          </TableCell>

          <TableCell align={'left'}>
            {this.props.row.data_start.substring(0, this.props.row.data_start.length - 6)}
          </TableCell>

          <TableCell align={'left'}>
            {this.props.row.data_end.substring(0, this.props.row.data_end.length - 6)}
          </TableCell>

          <TableCell align={'left'}>
            {this.props.row.master.name}
          </TableCell>

          <TableCell align={'left'}>
            {parseFloat(this.props.row.master_salary).toFixed(2)} UAH
          </TableCell>

          <TableCell align={'left'}>
            {parseFloat(this.props.row.service_income).toFixed(2)} UAH
          </TableCell>

        </TableRow>

        {this.props.undertable ?
          <TableRow>
            <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
              <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Typography variant="h6" gutterBottom component="div">
                    <FormattedMessage id="app.works"/>
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHeader rows={this.props.headerUnderTable}/>
                    <TableBody>
                      {/*{console.log(this.props.row.details)}*/}
                      {this.props.row.services.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {item.id}
                            </TableCell>

                            <TableCell component="th" scope="row">
                              {item.service !== null ?
                                item.service.name
                                :
                                null
                              }
                            </TableCell>

                            <TableCell component="th" scope="row">
                              {item.amount !== null ?
                                parseInt(item.amount) :
                                null
                              }
                            </TableCell>

                            <TableCell component="th" scope="row">
                              {parseFloat(item.price).toFixed(2)} UAH
                            </TableCell>

                          </TableRow>
                        )
                      })}


                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          :
          null
        }

      </React.Fragment>


    )
  }
}


function mapDispathToProps(dispath) {
  return {
    completeSaleDocument: (id) => dispath(completeSaleDocument(id)),
    removeSaleDocument: (id) => dispath(removeSaleDocument(id)),
    fetchSalePDF: (id) => dispath(fetchSalePDF(id)),
    fetchSalePDFfetchSalePDFNoCash: (id) => dispath(fetchSalePDFfetchSalePDFNoCash(id)),
  }
}


export default connect(null, mapDispathToProps)(TableRows);
