import {FETCH_EXPENSES_START, FETCH_EXPENSES_SUCCESS, FETCH_EXPENSES_ERROR} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";



export function fetchExpenses(data) {
  return async dispatch => {
    dispatch(fetchExpensesStart())
    try {
      const token = localStorage.getItem('token');
      const response = await API.get(`expenses${data}`, {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchExpensesSuccess(response.data))
    } catch (e) {
      console.error(fetchExpensesError(e));
    }
  }
}


export function fetchExpensesStart() {
  return {
    type: FETCH_EXPENSES_START
  }
}

export function fetchExpensesSuccess(expenses) {
  return {
    type: FETCH_EXPENSES_SUCCESS,
    expenses
  }
}

export function fetchExpensesError(e) {
  return {
    type: FETCH_EXPENSES_ERROR,
    error: e
  }
}

export function addExpenses(data) {
  return async dispatch => {
    dispatch(fetchExpensesStart())
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`expenses/store`, data, {headers: { Authorization: `Bearer ${token}` }})
      return response;
    } catch (e) {
      console.error(fetchExpensesError(e));
    }
  }
}

export function removeExpenses(id) {
  return async dispatch => {
    dispatch(fetchExpensesStart())
    try {
      const token = localStorage.getItem('token');
      const response = await API.delete(`expenses/${id}/delete`, {headers: { Authorization: `Bearer ${token}` }})
      return response;
    } catch (e) {
      console.error(fetchExpensesError(e));
    }
  }
}

export function editExpenses(data, id) {
  return async dispatch => {
    dispatch(fetchExpensesStart())
    try {
      const token = localStorage.getItem('token');
      const response = await API.put(`expenses/${id}/update`, data, {headers: { Authorization: `Bearer ${token}` }})
      return response;
    } catch (e) {
      console.error(fetchExpensesError(e));
    }
  }
}
