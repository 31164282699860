import React, {Component} from 'react'
import './Forgot.css'


import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import Logo from "../../../assets/img/new-logo-min.png";
import {FormattedMessage} from "react-intl";
import {auth, forgot} from "../../../store/action/auth";
import ForgotSuccessPopup from "../../../components/ModalPopups/ForgotSuccessPopup/ForgotSuccessPopup";
import PopupWrapper from "../../../hoc/PopupWrapper/PopupWrapper";

class Forgot extends Component {


  state = {
    email: '',
    isOpen: false,
  }

  closePopup = () => {
    this.setState({isOpen: false})
  }

  sendMail = () => {
    this.props.forgot({'email': this.state.email}).then((response) => {
      // console.log(response);
      if(response && response.data === "passwords.sent") {
        this.setState({isOpen: true})
      }
    })
  }

  render() {

    return (
      <div className={'forgot-page'}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <Typography component="h1" variant="h5">
              <FormattedMessage id="app.resetPassword"/>
            </Typography>

            {this.state.isOpen ?
              <PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopup}>
                <ForgotSuccessPopup index={this.state.editIndex} />
              </PopupWrapper>
              : ''}

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={<FormattedMessage id="app.emailAddress"/>}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(event) => this.setState({email: event.target.value})}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => this.sendMail()}
              >
                <FormattedMessage id="app.reset"/>
              </Button>

              <Grid container className={'to-login-block'}>
                <Grid item xs>
                  <div variant="body2">
                    <NavLink to="/auth">
                      <FormattedMessage id="app.login"/>
                    </NavLink>
                  </div>
                </Grid>
              </Grid>


          </div>

        </Container>
      </div>

    )
  }
}



function mapDispatchToProps(dispatch) {
  return {
    forgot: (email) => dispatch(forgot(email))
  }
}

export default connect(null, mapDispatchToProps)(Forgot)
