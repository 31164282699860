import React, {Component} from 'react'
import './AddExpensesTypePopup.css'

import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage} from "react-intl";
import {fetchClientCarsBrand} from "../../../store/action/clients";
import connect from "react-redux/es/connect/connect";
import {createExpenses, editExpenses} from "../../../store/action/settings";
import {editMaster} from "../../../store/action/settings";
import {logout} from "../../../store/action/auth";
import {openMenu} from "../../../store/action/main";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../../services/validationService";


class AddExpensesTypePopup extends Component {

  state = {
    name: '',
    // date: '',
    // price: '',

    validation: {
      name: false,
    }
  }


  handleClose = () => {
    this.props.handleClose();
  }

  handlerSaveForm = () => {
    let dataForm = {
      name: this.state.name
    };

    let validation = fieldEmpty(dataForm);

    if (!validation.isValidation) {
      this.setState({
        validation: validation.validation
      });
      return;
    }

    this.props.createExpenses(dataForm).then((response) => {
      if (response.status == 200) {
        this.props.handleClose();
        this.props.getExpenses();
      }
    });
  }

  handlerEditForm = () => {

    let dataForm = {
      name: this.refs.masterName.getElementsByTagName('input')[0].value,
      // date: this.refs.masterDate.getElementsByTagName('input')[0].value,
      // price: this.refs.masterPrice.getElementsByTagName('input')[0].value,
    }

    let validation = fieldEmpty(dataForm);

    if (!validation.isValidation) {
      this.setState({
        validation: validation.validation
      });
      return;
    }


    this.props.editExpenses(dataForm, this.props.expenses[this.props.index].id).then((response) => {
      if (response.status == 200) {
        this.props.handleClose();
        this.props.getExpenses();
      }
    });
  }


  render() {



    return (


      <div className="modal-popup__window container">
        <div className="modal-popup__header">
                <span>
                  {this.props.isEdit ? <FormattedMessage id="app.edit"/> : <FormattedMessage id="app.addExpenses"/>}
                </span>
        </div>
        <div className="modal-popup__body">
          <div className="waybills-popup__inner">
            {this.props.isEdit ?
              <React.Fragment>


                <div className="row">
                  <div className="col-12">

                    <div className="row">
                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"} id="standard-basic"
                                   defaultValue={this.props.expenses[this.props.index].name}
                                   error={this.state.validation.name}
                                   inputProps={{'data-name': 'name'}}
                                   onChange={(event) => this.setState({name: event.target.value})}
                                   ref={'masterName'}

                                   label={<FormattedMessage id="app.name"/>}/>
                      </div>


                      {/*<div className={'col-12'}>*/}
                        {/*<TextField*/}
                          {/*className={"modal-popup__input--date"}*/}
                          {/*id="datetime-local"*/}
                          {/*type="datetime-local"*/}
                          {/*defaultValue={this.props.expenses[this.props.index].date}*/}
                          {/*onChange={(event) => this.setState({date: event.target.value})}*/}
                          {/*ref={'masterDate'}*/}
                          {/*value={this.state.date}*/}
                          {/*// defaultValue="2017-05-24T10:30"*/}
                          {/*InputLabelProps={{*/}
                            {/*shrink: true,*/}
                          {/*}}*/}
                        {/*/>*/}

                      {/*</div>*/}
                      {/*<div className={'col-12'}>*/}
                        {/*<TextField className={"task-create__name-input"} id="standard-basic"*/}
                          {/*// value={this.props.master.lastName}*/}
                                   {/*defaultValue={this.props.expenses[this.props.index].price}*/}
                                   {/*inputProps={{'data-name': 'price'}}*/}
                                   {/*onChange={(event) => this.setState({price: event.target.value})}*/}
                                   {/*ref={'masterPrice'}*/}
                                   {/*label={<FormattedMessage id="app.price"/>}/>*/}

                      {/*</div>*/}

                    </div>
                  </div>

                </div>


              </React.Fragment>

              :

              <React.Fragment>
                <div className="row">
                  <div className="col-12">

                    <div className="row">
                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"}
                                   value={this.state.name}
                                   error={this.state.validation.name}
                                   inputProps={{'data-name': 'name'}}
                                   onChange={(event) => this.setState({name: event.target.value})}
                                   label={<FormattedMessage id="app.name"/>}/>
                      </div>

                      {/*<div className={'col-12'}>*/}
                        {/*<TextField*/}
                          {/*className={"modal-popup__input--date"}*/}
                          {/*id="datetime-local"*/}
                          {/*type="datetime-local"*/}
                          {/*onChange={(event) => this.setState({date: event.target.value})}*/}
                          {/*value={this.state.date}*/}
                          {/*// defaultValue="2017-05-24T10:30"*/}
                          {/*InputLabelProps={{*/}
                            {/*shrink: true,*/}
                            {/*'data-name': 'date'*/}
                          {/*}}*/}
                        {/*/>*/}

                      {/*</div>*/}

                      {/*<div className={'col-12'}>*/}
                        {/*<TextField className={"task-create__name-input"} id="standard-basic"*/}
                                   {/*value={this.state.price}*/}
                                   {/*inputProps={{'data-name': 'price'}}*/}
                                   {/*onChange={(event) => this.setState({price: event.target.value})}*/}
                                   {/*label={<FormattedMessage id="app.price"/>}/>*/}

                      {/*</div>*/}

                    </div>
                  </div>

                </div>

              </React.Fragment>
            }


          </div>

          <div className="modal-popup__footer">
            <div className="modal-popup__btn">
              <Button variant="contained" color="secondary" className={"modal-popup__btn-i"} onClick={this.handleClose}>
                <FormattedMessage id="app.close"/>
              </Button>

              {this.props.isEdit ?
                <Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                        onClick={this.handlerEditForm}>
                  <FormattedMessage id="app.change"/>
                </Button>

                :

                < Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                         onClick={this.handlerSaveForm}>
                  <FormattedMessage id="app.save"/>
                </Button>
              }
            </div>
          </div>

        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    expenses: state.settings.expenses,
  }
}

function mapDispathToProps(dispath) {
  return {
    createExpenses: (data) => dispath(createExpenses(data)),
    editExpenses: (data, id) => dispath(editExpenses(data, id))
  }
}


export default connect(mapStateToProps, mapDispathToProps)(AddExpensesTypePopup);

