import {FETCH_OUTFITS_START, FETCH_OUTFITS_SUCCESS, FETCH_OUTFITS_ERROR} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";


export function inDraftOutfits(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`work-order/${id}/in-drafts`, null, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.error(e);
    }

  }
}

export function createOutfits() {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post('work-order/create', null, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.error(e);
    }

  }
}

export function updateOutfits(id, data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`work-order/${id}/update`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.error(e);
    }

  }
}

export function deleteOutfits(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`work-order/${id}/delete`, null, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.error(e);
    }

  }
}

export function performOutfits(id) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`work-order/${id}/complete`, null, {headers: { Authorization: `Bearer ${token}` }});
      // if(response.data.hasOwnProperty('error')){
      //   // alert('Дата исполнения заказа не может быть старше сегодняшнего дня');
      //   return;
      // }
      return response;
    } catch (e) {
      return e;
    }

  }
}

export function createOutfitsService(idOrder) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`work-order/${idOrder}/service-row/create`, null, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.error(e);
    }

  }
}

export function updateOutfitsService(idOrder, idOrderService, data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`work-order/${idOrder}/service-row/${idOrderService}/update`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.error(e);
    }

  }
}

export function deleteOutfitsService(idOrder, idOrderService) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.delete(`work-order/${idOrder}/service-row/${idOrderService}/delete`, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.error(e);
    }

  }
}


export function createOutfitsParts(idOrder) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`work-order/${idOrder}/part-row/create`, null, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.error(e);
    }

  }
}


export function updateOutfitsParts(idOrder, idOrderPart, data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.post(`work-order/${idOrder}/part-row/${idOrderPart}/update`, data, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      return e.response
      console.error(e);
    }

  }
}

export function deleteOutfitsParts(idOrder, idOrderPart) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const response = await API.delete(`work-order/${idOrder}/part-row/${idOrderPart}/delete`, {headers: { Authorization: `Bearer ${token}` }});
      return response;
    } catch (e) {
      console.error(e);
    }

  }
}

export function fetchOutfits(data = '') {
  return async dispatch => {
    dispatch(fetchOutfitsStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`work-order/index${data}`, {headers: { Authorization: `Bearer ${token}` }})
      dispatch(fetchOutfitsSuccess(response.data))
    } catch (e) {
      console.error(fetchOutfitsError(e));
    }
  }
}

export function fetchOutfitsStart() {
  return {
    type: FETCH_OUTFITS_START
  }
}

export function fetchOutfitsSuccess(outfits) {
  return {
    type: FETCH_OUTFITS_SUCCESS,
    outfits
  }
}

export function fetchOutfitsError(e) {
  return {
    type: FETCH_OUTFITS_ERROR,
    error: e
  }
}

export function fetchOutfitsCashPDF(id) {
  return async dispatch => {
    // dispatch(fetchOutfitsStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`work-order/${id}/person-pdf`, {headers: { Authorization: `Bearer ${token}` }})

      // const linkSource = `data:application/pdf;base64,${response.data}`;
      // const downloadLink = document.createElement("a");
      // const fileName = "abc.pdf";
      // downloadLink.href = linkSource;
      // downloadLink.download = fileName;
      // downloadLink.click();

      let pdfWindow = window.open("")
      pdfWindow.document.write("<style type='text/css'>body {margin: 0; overflow: hidden}</style> <iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(response.data) + "'></iframe>")


    } catch (e) {
      console.error(fetchOutfitsError(e));
    }
  }
}

export function fetchOutfitsPDF(id) {
  return async dispatch => {
    // dispatch(fetchOutfitsStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`work-order/${id}/company-pdf`, {headers: { Authorization: `Bearer ${token}` }})

      // const linkSource = `data:application/pdf;base64,${response.data}`;
      // const downloadLink = document.createElement("a");
      // const fileName = "abc.pdf";
      // downloadLink.href = linkSource;
      // downloadLink.download = fileName;
      // downloadLink.click();

      let pdfWindow = window.open("")
      pdfWindow.document.write("<style type='text/css'>body {margin: 0; overflow: hidden}</style> <iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(response.data) + "'></iframe>")


    } catch (e) {
      console.error(fetchOutfitsError(e));
    }
  }
}
