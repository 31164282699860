import React, {Component} from 'react'
import './SideBarLeft.css';
import Man from '../../../assets/img/man.jpg'
import Logo from '../../../assets/img/new-logo-min.png'
import {NavLink} from 'react-router-dom'
import {FormattedMessage, injectIntl} from "react-intl";
import Bills from "../../../containers/Bills/Bills";
import {connect} from "react-redux";
import {closeMenu} from "../../../store/action/main";
import {getCurrentUser} from "../../../store/action/auth";
import {showAvatar, storeAvatar} from "../../../store/action/settings";

class SideBarLeft extends Component {

    componentDidMount() {
        this.props.getCurrentUser()
        this.props.showAvatar()
    }

    render() {
        // console.log(this.props.userData);

        const manager = this.props.intl.formatMessage({
            id: 'app.manager',
        });
        const admin = this.props.intl.formatMessage({
            id: 'app.admin',
        });

        return (
            <aside className={'aside' + (this.props.menu ? ' open' : '')}>

                <div className="aside__inner">

                    <div className={"menu-close-mob" + (this.props.menu ? ' open' : '')} onClick={this.props.closeMenu}>
                        +
                    </div>

                    <div className="aside__top">
                        <NavLink className={'logo-name'} to="/">
                            <span className={'logo'}>New </span>
                            <span className="logo-sub">Life Auto</span>
                        </NavLink>
                    </div>

                    {this.props.userData !== null ?
                        <div className="aside__user">
                            <div className="aside__user-img">
                                {this.props.avatar.length > 0 ?
                                    <img src={'data:image/png;base64,' + this.props.avatar} alt="sada"/> :
                                    <img src={Man} alt=""/>}
                            </div>
                            <div className="aside__user-right">
                                <div className="aside__user-name">
                                    {/*<FormattedMessage id="app.asideUserName"/>*/}
                                    {this.props.userData.name !== null ?
                                        this.props.userData.name + ' ' + this.props.userData.last_name
                                        :
                                        null
                                    }
                                </div>

                                <div className="aside__user-position">
                                    {/*<FormattedMessage id="app.asideUserPosition"/>*/}
                                    {this.props.userData.role !== null ?
                                        this.props.userData.role === 'ADMIN' ? admin : null
                                        :
                                        null
                                    }
                                    {this.props.userData.role !== null ?
                                        this.props.userData.role === 'MANAGER' ? manager : null
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                    <nav className="aside__nav">
                        <ul className="aside__nav-items">
                            <li className="aside__nav-item">
                                <NavLink to="/" exact={true} className="aside__nav-link">
                                    <FormattedMessage id="app.dashboard"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/projects" className="aside__nav-link">
                                    <FormattedMessage id="app.tasks"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/clients" className="aside__nav-link">
                                    <FormattedMessage id="app.clients"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/private-entrepreneurs" className="aside__nav-link">
                                    <FormattedMessage id="app.privateEnts"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/waybills" className="aside__nav-link">
                                    <FormattedMessage id="app.outfits"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/product-waybills" className="aside__nav-link">
                                    <FormattedMessage id="app.overhead"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/products" className="aside__nav-link">
                                    <FormattedMessage id="app.products"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/recording" className="aside__nav-link">
                                    <FormattedMessage id="app.recording"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/workers-statistic" className="aside__nav-link">
                                    <FormattedMessage id="app.works"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/expenses" className="aside__nav-link">
                                    <FormattedMessage id="app.expenses"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/bills" className="aside__nav-link">
                                    <FormattedMessage id="app.bills"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/reports" className="aside__nav-link">
                                    <FormattedMessage id="app.reports"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/report-card" className="aside__nav-link">
                                    <FormattedMessage id="app.reportCard"/>
                                </NavLink>
                            </li>
                            <li className="aside__nav-item">
                                <NavLink to="/settings" className="aside__nav-link">
                                    <FormattedMessage id="app.settings"/>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>


                </div>

            </aside>
        )
    }
}


function mapStateToProps(state) {
    return {
        menu: state.main.menu,
        userData: state.auth.userData,
        avatar: state.settings.avatar,
    }
}

function mapDispathToProps(dispatch) {
    return {
        closeMenu: () => dispatch(closeMenu()),
        getCurrentUser: () => dispatch(getCurrentUser()),
        showAvatar: () => dispatch(showAvatar()),
    }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(SideBarLeft));
