import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import {applyMiddleware, compose, createStore} from "redux";
import {IntlProvider} from 'react-intl-redux'
import {Provider} from 'react-redux'
import reduxThunk from 'redux-thunk'

import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './store/reducers/rootReducer'
// import localRu from './languages/ru'
import localUa from './languages/ua'

const userLocale = 'uk';

const initialState = {
    intl: {
        locale: userLocale,
    },
}

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;

const loggerMiddleware = store => next => action => {
    // console.log('Middleware', store.getState());
    return next(action)
}

const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(
    loggerMiddleware,
    reduxThunk
)))

const app = (
    <Provider store={store}>
        <IntlProvider locale={userLocale} messages={localUa}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </IntlProvider>
    </Provider>
)

ReactDOM.render(
    app,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
