import React, {Component} from 'react'
import './Dashboard.css'
import Grid from "../Header/Header";
import {FormattedMessage, injectIntl} from "react-intl";
import ProjectCard from "../ProjectCard/ProjectCard";
import CreateTaskPopup from "../../components/ModalPopups/CreateTaskPopup/CreateTaskPopup";
import Time from "../../components/Time/Time";


import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import '../Recording/Recording.css'
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import {editExpenses, fetchExpenses, removeExpenses} from "../../store/action/expenses";
import connect from "react-redux/es/connect/connect";
import {fetchDashboard} from "../../store/action/dashboard";
import {fetchProjects, performTask} from "../../store/action/projects";
import {fetchAppointment} from "../../store/action/appointment";
import TableHeader from "../../components/Tables/TableHeader/TableHeader";
import {NavLink} from 'react-router-dom'
import {
    closeDay, currentDay,
    fetchWorkersStatistic,
    fetchWorkToday,
    nextOpenDay,
    reOpenDay
} from "../../store/action/workersStatistic";
import TableRowsWorkersStatistic from "../../components/Tables/TableRows/TableRowsWorkersStatistic";
import QuestionPopup from "../../components/ModalPopups/QuestionPopup/QuestionPopup";
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import AddCassaPopup from "../../components/ModalPopups/AddCassaPopup/AddCassaPopup";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {fetchRecipient} from "../../store/action/bills";


class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.isMountedRef = React.createRef();
    }

    state = {
        intervalId: false,
        currentCount: 0,
        openSnack: false,

        countMasters: null,
        salary: null,
        errPay: false,
        masterSumm: [],

        recipient: [],

        fopIncomeToday: '0',
        fopExpensesToday: '0',
        fopCassaMonth: '0',
        fopCassaAll: '0',
        fopIncomeAll: '0',
        fopIncomeMonth: '0',

        isOpenCloseDayPopup: false,

        workDaySalary: []
    }

    componentDidMount() {

        this.isMountedRef.current = true;

        this.props.fetchDashboard();
        this.props.fetchProjects();
        this.props.fetchAppointment();
        this.props.currentDay();

        this.props.fetchRecipient()
            .then((response) => {
                if (response) {
                    this.setState({
                        recipient: response.data
                    })
                }
            });

        let day = new Date().getDate() < 10 ? '0' + parseInt(new Date().getDate()) : new Date().getDate();
        let dayMonth = new Date().getMonth() + 1 < 10 ? '0' + parseInt(new Date().getMonth() + 1) : new Date().getMonth() + 1;
        let dayYear = new Date().getFullYear();
        let lastMounth = dayYear + '-' + dayMonth + '-' + day;
        this.props.fetchWorkersStatistic(`?&between=data_start,${lastMounth}`);

        let today = new Date();
        let nowTime = today.getHours() + ':' + today.getMinutes();
        this.setState({currentCount: nowTime});
        let intervalId = setInterval(() => {
            if (this.isMountedRef.current) {
                let today = new Date();
                let nowTime = today.getHours() + ':' + today.getMinutes();
                this.setState({currentCount: nowTime});
            }
        }, 1000);
        // store intervalId in the state so it can be accessed later:

        this.setState({intervalId: intervalId});

        document.title = this.props.intl.formatMessage({
            id: 'app.dashboard',
        });


        // this.props.fetchWorkToday().then(() => {
        //   if (!this.props.workingDay.hasOwnProperty('masters')) return;
        //
        //   let countMasters = this.props.workingDay.masters.length;
        //   let salary = this.props.workingDay.workingDay.master_income;
        //   let workDaySalary = [];
        //
        //   this.props.workingDay.masters.map((item, index) => {
        //
        //     let master = {
        //       id: item.id,
        //       salary: 0,
        //       checked: false,
        //     };
        //
        //     workDaySalary.push(master);
        //   });
        //
        //   this.setState({
        //     countMasters: countMasters,
        //     salary: salary,
        //     workDaySalary: workDaySalary
        //   })
        // });

        this.fetchWorkToday();
    }

    fetchWorkToday = () => {
        this.props.fetchWorkToday().then(() => {
            if (!this.props.workingDay.hasOwnProperty('masters')) return;

            let countMasters = this.props.workingDay.masters.length;
            let salary = this.props.workingDay.workingDay.master_income;
            let workDaySalary = [];

            this.props.workingDay.masters.map((item, index) => {

                let master = {
                    id: item.id,
                    salary: 0,
                    checked: false,
                };

                workDaySalary.push(master);
            });

            this.setState({
                countMasters: countMasters,
                salary: salary,
                workDaySalary: workDaySalary
            })
        });
    }

    componentWillUnmount() {
        this.isMountedRef.current = false; // Mark as unmounted
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    timer(that) {
        // setState method is used to update the state

    }

    handleCloseSnack = () => {
        this.setState({openSnack: false});
    }

    handleOpenSnack = () => {
        this.setState({openSnack: true});
    }

    reOpenDay = () => {
        this.props.reOpenDay().then(() => {
            this.fetchWorkToday();
        });
    }

    nextOpenDay = () => {
        this.props.nextOpenDay().then(() => {
            this.fetchWorkToday();
        });
    }

    closeDay = () => {

        this.setState({
            isOpenCloseDayPopup: false,
        })

        let allSalary = 0;
        let workDaySalary = [];

        this.state.workDaySalary.map((item, index) => {
            allSalary = parseFloat(allSalary) + parseFloat(item);

            if (item.checked === true) {
                workDaySalary.push(item);
            }
        });

        if (workDaySalary.length === 0) {
            alert('выберите хоть одного мастера');
            return;
        }

        if (allSalary > this.state.salary) {
            this.setState({errPay: true});
            return;
        } else {
            this.setState({errPay: false});
        }

        this.props.closeDay(workDaySalary).then(() => {
            this.fetchWorkToday();
        });
    }

    changeCheckMaster = (event, index) => {
        let workDaySalary = this.state.workDaySalary;
        let masters = this.state.countMasters;

        workDaySalary[index].checked = event.target.checked;

        if (event.target.checked === false) {
            workDaySalary[index].salary = 0;
            masters = masters - 1;
        } else {
            masters = masters + 1;
        }

        masters = workDaySalary.filter(master => master.checked === true).length;

        workDaySalary.map((item, index) => {
            if (item.checked === true) {
                item.salary = parseFloat(parseFloat(this.props.workingDay.workingDay.master_income).toFixed(2) / parseFloat(masters).toFixed(2)).toFixed(2)
            }
        })


        this.setState({workDaySalary: workDaySalary, countMasters: masters})
    }

    closeDayPopup = () => {
        this.setState({
            isOpenCloseDayPopup: true,
        })
    }

    closePopup = () => {
        this.setState({isOpen: false, isOpenAddCassa: false})
    }

    openPopupAddCassa = () => {
        this.setState({isOpenAddCassa: true})
    }

    reRenderCassa = () => {
        this.props.fetchDashboard();
    }

    performTask = (id, data) => {
        // console.log(id, data);
        this.props.performTask(id, data).then((response) => {
            // console.log(response);
            this.props.fetchProjects();
            this.setState({isEditTask: false})
            this.setState({isOpenPopup: false})
        })

    }

    handleChangeFop = (event) => {
        this.setState({fopIncomeToday: event.target.value})
    }

    render() {


        let today = new Date();
        let mounth = today.getMonth() + 1;
        mounth = mounth < 10 ? '0' + mounth : mounth;
        let date = today.getDate() + '.' + mounth + '.' + today.getFullYear();

        let nowTime;

        setInterval(function () {
            nowTime = today.getHours() + ':' + today.getMinutes() + today.getSeconds()
        }, 1000)

        const action = (
            <div className={'task-snack__create'}>
                <div className={'task-snack__create-title'}>
                    <span>Создана задача: </span>
                    <span>Новая задача</span>
                </div>
                <div className={'task-snack__create-txt'}>
                    <span>Описание: </span>
                    <span>
            sdfsdfsdfsdfsd sdfsdfsdf fsdfsdf sdfsd fsdfsdfsdfsd fsdfsd
          </span>
                </div>
                <Button color="primary" size="small" className={'task-snack__create-btn'}>
                    Подробнее
                </Button>
            </div>
        );
        let vertical = 'top';
        let horizontal = 'right';

        let headerTable = [
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.time"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.task"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.clientName"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.clientPhone"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.responsible"/>
            },
        ];

        let headerDelivery = [
            {
                colspan: '0',
                align: 'left',
                item: ''
            },
            {
                colspan: '0',
                align: 'left',
                item: 'id'
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.orderDate"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.orderExecutionDate"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.master"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.masterSalary"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.income"/>
            }
        ];
        let headerUnderTableAll = [
            {
                colspan: '0',
                align: 'left',
                item: 'id'
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.name"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.count"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.price"/>
            },
        ];

        return (
            <div className="dashboard">


                <div className="page-title">
           <span className={'header__name'}>
             <FormattedMessage id="app.dashboard"/>
            </span>
                </div>

                <div className="task-snack">
                    <Snackbar
                        anchorOrigin={{vertical, horizontal}}
                        open={this.state.openSnack}
                        onClose={this.handleCloseSnack}
                        key={vertical + horizontal}
                        action={action}
                    />
                </div>


                {/*<Button color="secondary" size="small" onClick={this.handleOpenSnack}>*/}
                {/*open*/}
                {/*</Button>*/}

                <div className="dashboard-cards">
                    <div className="dashboard-cards__inner row">
                        <div className="col-md-3">
                            <div className="dashboard-cards__item">
                                <div className="dashboard-cards__title">
                                    <FormattedMessage id="app.today"/>
                                </div>
                                <div className="dashboard-cards__caption">
                                    <FormattedMessage id="app.incomes"/>

                                    {this.state.recipient.length > 0 ?
                                        <RadioGroup className={'radio-group radio-group-dashboard'} name="gender1"
                                                    defaultValue={this.state.fopIncomeToday}
                                                    onChange={(event) => {
                                                        this.setState({fopIncomeToday: event.target.value})
                                                    }}>
                                            <FormControlLabel value={'0'} control={<Radio size={'small'}/>}
                                                              label='Общая'/>
                                            <FormControlLabel value={'1'} control={<Radio size={'small'}/>}
                                                              label='Наличные'/>
                                            {
                                                this.state.recipient.map((item, index) => {
                                                    return (
                                                        <FormControlLabel key={index} value={'' + item.id}
                                                                          control={<Radio size={'small'}/>}
                                                                          label={item.name}/>
                                                    )
                                                })
                                            }
                                        </RadioGroup>
                                        :
                                        null
                                    }

                                </div>
                                <div className="dashboard-cards__money">

                                    {Object.keys(this.props.dashboard).length > 0 ?
                                        <React.Fragment>
                                            {
                                                this.state.fopIncomeToday === '0' ?
                                                    parseFloat(this.props.dashboard.today.income.total).toFixed(2)
                                                    : null
                                            }
                                            {
                                                this.state.fopIncomeToday !== '0' ?
                                                    parseFloat(this.props.dashboard.today.income[this.state.fopIncomeToday]).toFixed(2)
                                                    : null
                                            }
                                        </React.Fragment>
                                        :
                                        0
                                    } UAH
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashboard-cards__item">
                                <div className="dashboard-cards__title">
                                    <FormattedMessage id="app.today"/>
                                </div>
                                <div className="dashboard-cards__caption">
                                    <FormattedMessage id="app.expenses"/>
                                    {this.state.recipient.length > 0 ?
                                        <RadioGroup className={'radio-group radio-group-dashboard'} name="gender1"
                                                    defaultValue={this.state.fopExpensesToday}
                                                    onChange={(event) => {
                                                        this.setState({fopExpensesToday: event.target.value})
                                                    }}>
                                            <FormControlLabel value={'0'} control={<Radio size={'small'}/>}
                                                              label='Общая'/>
                                            <FormControlLabel value={'1'} control={<Radio size={'small'}/>}
                                                              label='Наличные'/>
                                            {
                                                this.state.recipient.map((item, index) => {
                                                    return (
                                                        <FormControlLabel key={index} value={'' + item.id}
                                                                          control={<Radio size={'small'}/>}
                                                                          label={item.name}/>
                                                    )
                                                })
                                            }
                                        </RadioGroup>
                                        :
                                        null
                                    }
                                </div>
                                <div className="dashboard-cards__money">
                                    {Object.keys(this.props.dashboard).length > 0 ?
                                        <React.Fragment>
                                            {
                                                this.state.fopExpensesToday === '0' ?
                                                    parseFloat(this.props.dashboard.today.expenses.total).toFixed(2)
                                                    : null
                                            }
                                            {
                                                this.state.fopExpensesToday !== '0' ?
                                                    parseFloat(this.props.dashboard.today.expenses[this.state.fopExpensesToday]).toFixed(2)
                                                    : null
                                            }
                                        </React.Fragment>
                                        :
                                        0
                                    } UAH
                                </div>

                            </div>

                        </div>

                        <div className="col-md-3">
                            <div className="dashboard-cards__item">
                                <div className="dashboard-cards__title">
                                    <FormattedMessage id="app.worksForDay"/>
                                </div>
                                <div className="dashboard-cards__caption">
                                    {/*<FormattedMessage id="app.expenses"/>*/} &nbsp;

                                </div>
                                <div className="dashboard-cards__money">
                                    {Object.keys(this.props.dashboard).length > 0 ?
                                        parseInt(this.props.dashboard.today.works).toFixed(2)
                                        :
                                        0
                                    } UAH
                                </div>

                            </div>

                        </div>

                        <div className="col-md-3">
                            <div className="dashboard-cards__item dashboard-cards__item--orange">
                                <div className="dashboard-cards__title">
                                    <FormattedMessage id="app.forLastMonth"/>
                                </div>

                                {this.state.isOpenAddCassa ?
                                    <PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopup}>
                                        <AddCassaPopup reRenderCassa={this.reRenderCassa}
                                                       question={'Введите количество, которое ходите добавить'}/>
                                    </PopupWrapper>
                                    : ''}


                                <div className="dashboard-cards__caption">
                                    {this.state.recipient.length > 0 ?
                                        <RadioGroup className={'radio-group radio-group-dashboard'} name="gender1"
                                                    defaultValue={this.state.fopCassaMonth}
                                                    onChange={(event) => {
                                                        this.setState({fopCassaMonth: event.target.value})
                                                    }}>
                                            <FormControlLabel value={'0'} control={<Radio size={'small'}/>}
                                                              label='Общая'/>
                                            <FormControlLabel value={'1'} control={<Radio size={'small'}/>}
                                                              label='Наличные'/>
                                            {
                                                this.state.recipient.map((item, index) => {
                                                    return (
                                                        <FormControlLabel key={index} value={'' + item.id}
                                                                          control={<Radio size={'small'}/>}
                                                                          label={item.name}/>
                                                    )
                                                })
                                            }
                                        </RadioGroup>
                                        :
                                        null
                                    }

                                    <Button variant="contained" color="secondary" size={'small'}
                                            onClick={this.openPopupAddCassa}>
                                        + Добавить в кассу
                                    </Button>

                                </div>
                                <div className="dashboard-cards__money">
                                    {Object.keys(this.props.dashboard).length > 0 ?
                                        <React.Fragment>
                                            {
                                                this.state.fopCassaMonth === '0' ?
                                                    parseFloat(this.props.dashboard.month.kassa.total).toFixed(2)
                                                    : null
                                            }
                                            {
                                                this.state.fopCassaMonth !== '0' ?
                                                    parseFloat(this.props.dashboard.month.kassa[this.state.fopCassaMonth]).toFixed(2)
                                                    : null
                                            }
                                        </React.Fragment>
                                        :
                                        0
                                    } UAH
                                </div>
                            </div>
                        </div>


                        <div className="col-md-3">
                            <div className="dashboard-cards__item dashboard-cards__item--green">
                                <div className="dashboard-cards__title">
                                    <FormattedMessage id="app.allCassa"/>
                                </div>
                                <div className="dashboard-cards__caption">
                                    {this.state.recipient.length > 0 ?
                                        <RadioGroup className={'radio-group radio-group-dashboard'} name="gender1"
                                                    defaultValue={this.state.fopCassaAll}
                                                    onChange={(event) => {
                                                        this.setState({fopCassaAll: event.target.value})
                                                    }}>
                                            <FormControlLabel value={'0'} control={<Radio size={'small'}/>}
                                                              label='Общая'/>
                                            <FormControlLabel value={'1'} control={<Radio size={'small'}/>}
                                                              label='Наличные'/>
                                            {
                                                this.state.recipient.map((item, index) => {
                                                    return (
                                                        <FormControlLabel key={index} value={'' + item.id}
                                                                          control={<Radio size={'small'}/>}
                                                                          label={item.name}/>
                                                    )
                                                })
                                            }
                                        </RadioGroup>
                                        :
                                        null
                                    }
                                    &nbsp;
                                </div>
                                <div className="dashboard-cards__money">
                                    {Object.keys(this.props.dashboard).length > 0 ?
                                        <React.Fragment>
                                            {
                                                this.state.fopCassaAll === '0' ?
                                                    parseFloat(this.props.dashboard.total.kassa.total).toFixed(2)
                                                    : null
                                            }
                                            {
                                                this.state.fopCassaAll !== '0' ?
                                                    parseFloat(this.props.dashboard.total.kassa[this.state.fopCassaAll]).toFixed(2)
                                                    : null
                                            }
                                        </React.Fragment>
                                        :
                                        0
                                    } UAH
                                </div>
                            </div>

                        </div>

                        <div className="col-md-3">
                            <div className="dashboard-cards__item dashboard-cards__item--green">
                                <div className="dashboard-cards__title">
                                    <FormattedMessage id="app.allIncomes"/>
                                </div>
                                <div className="dashboard-cards__caption">
                                    {this.state.recipient.length > 0 ?
                                        <RadioGroup className={'radio-group radio-group-dashboard'} name="gender1"
                                                    defaultValue={this.state.fopIncomeAll}
                                                    onChange={(event) => {
                                                        this.setState({fopIncomeAll: event.target.value})
                                                    }}>
                                            <FormControlLabel value={'0'} control={<Radio size={'small'}/>}
                                                              label='Общая'/>
                                            <FormControlLabel value={'1'} control={<Radio size={'small'}/>}
                                                              label='Наличные'/>
                                            {
                                                this.state.recipient.map((item, index) => {
                                                    return (
                                                        <FormControlLabel key={index} value={'' + item.id}
                                                                          control={<Radio size={'small'}/>}
                                                                          label={item.name}/>
                                                    )
                                                })
                                            }
                                        </RadioGroup>
                                        :
                                        null
                                    }
                                    &nbsp;
                                </div>
                                <div className="dashboard-cards__money">
                                    {Object.keys(this.props.dashboard).length > 0 ?
                                        <React.Fragment>
                                            {
                                                this.state.fopIncomeAll === '0' ?
                                                    parseFloat(this.props.dashboard.total.income.total).toFixed(2)
                                                    : null
                                            }
                                            {
                                                this.state.fopIncomeAll !== '0' ?
                                                    parseFloat(this.props.dashboard.total.income[this.state.fopIncomeAll]).toFixed(2)
                                                    : null
                                            }
                                        </React.Fragment>
                                        :
                                        0
                                    } UAH
                                </div>
                            </div>

                        </div>

                        <div className="col-md-3">
                            <div className="dashboard-cards__item dashboard-cards__item--orange">
                                <div className="dashboard-cards__title">
                                    <FormattedMessage id="app.forCurrMonth"/>
                                </div>
                                <div className="dashboard-cards__caption">
                                    {this.state.recipient.length > 0 ?
                                        <RadioGroup className={'radio-group radio-group-dashboard'} name="gender1"
                                                    defaultValue={this.state.fopIncomeMonth}
                                                    onChange={(event) => {
                                                        this.setState({fopIncomeMonth: event.target.value})
                                                    }}>
                                            <FormControlLabel value={'0'} control={<Radio size={'small'}/>}
                                                              label='Общая'/>
                                            <FormControlLabel value={'1'} control={<Radio size={'small'}/>}
                                                              label='Наличные'/>
                                            {
                                                this.state.recipient.map((item, index) => {
                                                    return (
                                                        <FormControlLabel key={index} value={'' + item.id}
                                                                          control={<Radio size={'small'}/>}
                                                                          label={item.name}/>
                                                    )
                                                })
                                            }
                                        </RadioGroup>
                                        :
                                        null
                                    }
                                    &nbsp;
                                </div>
                                <div className="dashboard-cards__money">
                                    {Object.keys(this.props.dashboard).length > 0 ?
                                        <React.Fragment>
                                            {
                                                this.state.fopIncomeMonth === '0' ?
                                                    parseFloat(this.props.dashboard.month.income.total).toFixed(2)
                                                    : null
                                            }
                                            {
                                                this.state.fopIncomeMonth !== '0' ?
                                                    parseFloat(this.props.dashboard.month.income[this.state.fopIncomeMonth]).toFixed(2)
                                                    : null
                                            }
                                        </React.Fragment>
                                        :
                                        0
                                    } UAH
                                </div>
                            </div>

                        </div>


                    </div>
                </div>

                <div className="dashboard__close-day">
                    <div className="dashboard__actual-task-title">
            <span className={"header__name"}>
              <FormattedMessage id="app.reportCard"/>
            </span>
                    </div>

                    {this.state.isOpenCloseDayPopup ?
                        <PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopup}>
                            <QuestionPopup yes={this.closeDay} question={'Вы уверены что хотите закрыть день?'}/>
                        </PopupWrapper>
                        : ''}

                    <div className="page-section">
                        <div className="page-section__content">

                            {this.props.workingDay.hasOwnProperty('error') ?
                                <div>
                                    <div className={'no-open-day'}><FormattedMessage id="app.noOpenDay"/></div>
                                    <div className={'pb-4 d-flex justify-content-center'}>
                                        <Button variant="contained" className="yellow-btn recording__add-btn"
                                                onClick={this.reOpenDay}>
                                            Открыть последний день
                                        </Button>
                                        <Button variant="contained" className="yellow-btn recording__add-btn"
                                                onClick={this.nextOpenDay}>
                                            Открыть новый день
                                        </Button>
                                    </div>
                                </div>
                                :
                                <React.Fragment>
                                    <div className="income-salary row">
                                        <div className="all-income col-md-2">
                                            <FormattedMessage id="app.cassa"/> &nbsp;
                                            {
                                                this.props.workingDay.hasOwnProperty('workingDay') ?
                                                    parseFloat(this.props.workingDay.workingDay.service_income).toFixed(2)
                                                    :
                                                    null
                                            }
                                            &nbsp; UAH
                                        </div>
                                        <div className="all-income col-md-3">
                                            <FormattedMessage id="app.mastersSalary"/> &nbsp;
                                            {
                                                this.props.workingDay.hasOwnProperty('workingDay') ?
                                                    parseFloat(this.props.workingDay.workingDay.master_income).toFixed(2)
                                                    :
                                                    null
                                            }
                                            &nbsp; UAH
                                        </div>
                                    </div>


                                    <TableContainer className={'clients-table'}>
                                        <Table size="small" aria-label="a dense table">

                                            <TableHead>
                                                <TableRow className={'clients-table-head'}>
                                                    <TableCell align={'left'}></TableCell>
                                                    <TableCell align={'left'}>id</TableCell>
                                                    <TableCell align={'left'}><FormattedMessage
                                                        id="app.name"/></TableCell>
                                                    <TableCell align={'left'}><FormattedMessage id="app.masterSalary"/></TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {
                                                    this.props.workingDay.hasOwnProperty('masters') ?
                                                        this.props.workingDay.masters.map((row, key) => {
                                                            return (
                                                                <TableRow key={key}>
                                                                    <TableCell align={'left'}>
                                                                        {this.state.workDaySalary.length > 0 && this.state.workDaySalary[key].hasOwnProperty('checked') ?
                                                                            <input type="checkbox"
                                                                                   checked={this.state.workDaySalary[key].checked}
                                                                                   onChange={(event) => this.changeCheckMaster(event, key)}/>
                                                                            :
                                                                            null
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align={'left'}>
                                                                        {row.id}
                                                                    </TableCell>
                                                                    <TableCell align={'left'}>
                                                                        {row.name}
                                                                    </TableCell>
                                                                    <TableCell align={'left'}>
                                                                        {
                                                                            (this.state.countMasters !== null && this.state.salary !== null) ?

                                                                                <TextField
                                                                                    className="work-day-salary"
                                                                                    // defaultValue={parseFloat(parseFloat(this.state.salary).toFixed(2) / parseFloat(this.state.countMasters).toFixed(2)).toFixed(2)}
                                                                                    value={this.state.workDaySalary[key].salary}
                                                                                    onChange={(event) => this.changeDaySallary(event, key)}
                                                                                    size="small"
                                                                                    variant="outlined"/>

                                                                                :

                                                                                <TextField
                                                                                    className="work-day-salary"
                                                                                    // onChange={(event) => this.setState({workDaySalary: event.target.value})}
                                                                                    size="small"
                                                                                    variant="outlined"/>

                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <div className="btn-salary d-flex justify-content-end pb-3 pr-3">
                    <span className={'d-flex align-items-center'}>
                      {this.props.workingDay.hasOwnProperty('workingDay') ? this.props.workingDay.workingDay.date : null}
                    </span>

                                        <Button variant="contained" className="yellow-btn recording__add-btn"
                                                onClick={this.closeDayPopup}>
                                            <FormattedMessage id="app.closeDay"/>
                                        </Button>
                                    </div>

                                    {this.state.errPay ?
                                        <div className="err-txt d-flex justify-content-end pb-3 pr-3">
                                            Сумма выплаты мастерам больше выделеной им суммы
                                        </div>
                                        : null
                                    }
                                </React.Fragment>
                            }


                        </div>
                    </div>
                </div>

                <div className="dashboard__actual-task">
          <span className={'header__name'}>
              <FormattedMessage id="app.recording"/>
            </span>
                </div>
                <div className={'dashboard-records-block'}>
                    <div className="filter-head">
                        <div className="site-filter">
                            <NavLink to="/recording">
                                <Button variant="contained" color="primary" size="small">
                                    <FormattedMessage id="app.seeAllRecords"/>
                                </Button>
                            </NavLink>


                        </div>
                    </div>
                    <div className="page-section">
                        <div className="page-section__name">
                            <FormattedMessage id="app.today"/>
                            {/*{this.props.appointment.length > 0 && this.props.appointment.day.length > 0 ?*/}
                            {/*this.props.appointment.day[0].date*/}
                            {/*: null*/}
                            {/*}*/}
                        </div>
                        <div className="page-section__content">
                            <TableContainer className={'recording-table'}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHeader rows={headerTable}/>

                                    <TableBody>


                                        {this.props.appointment.hasOwnProperty('day') && this.props.appointment.day.length > 0 ?
                                            this.props.appointment.day.map(function (item, index) {

                                                return (

                                                    <TableRow className={'site-table-row'} key={index}>
                                                        <TableCell align="left">{item.date}</TableCell>
                                                        <TableCell align="left">{item.task_name}</TableCell>
                                                        <TableCell align="left">{item.client_name}</TableCell>
                                                        <TableCell align="left">{item.client_phone}</TableCell>
                                                        <TableCell align="left">{item.user_name}</TableCell>
                                                    </TableRow>
                                                )
                                            })

                                            :

                                            <TableRow className={'site-table-row'}>
                                                <TableCell align="left" colSpan={6}><h2 className={'no-records'}>
                                                    <FormattedMessage
                                                        id="app.noRecords"/></h2></TableCell>
                                            </TableRow>

                                        }
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>

                <div className="dashboard__actual-task">
                    <div className="dashboard__actual-task-title">
            <span className={"header__name"}>
              <FormattedMessage id="app.actualTask"/>
            </span>
                    </div>
                    <div className="projects-card">
                        <div className="projects-card__content">
                            <div className="row">
                                {!this.props.loading ?
                                    <React.Fragment>
                                        {
                                            this.props.projects.map((item, index) => (
                                                <ProjectCard key={index} item={item} active={true}
                                                             infoHandler={() => this.getInfoTask(item)}
                                                             performTask={this.performTask}/>
                                            ))
                                        }
                                    </React.Fragment>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="dashboard__actual-task">
                    <div className="dashboard__actual-task-title">
            <span className={"header__name"}>
              <FormattedMessage id="app.works"/>
            </span>
                    </div>

                    <div className="page-section">
                        <div className="page-section__content">
                            <TableContainer className={'clients-table'}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHeader rows={headerDelivery}/>

                                    <TableBody>
                                        {
                                            this.props.workersStatistic.hasOwnProperty('all') ?
                                                this.props.workersStatistic.all.map((row, key) => {
                                                    return (

                                                        <TableRowsWorkersStatistic reRender={this.reRender} key={key}
                                                                                   row={row}
                                                                                   button={true}
                                                                                   undertable={true}
                                                                                   headerUnderTable={headerUnderTableAll}/>
                                                    )
                                                })
                                                : null
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

// fetchDashboard

function mapStateToProps(state) {
    return {
        dashboard: state.dashboard.dashboard,
        projects: state.projects.projects,
        appointment: state.appointment.appointment,
        workersStatistic: state.workersStatistic.workersStatistic,
        workingDay: state.workersStatistic.workingDay,
    }

}

function mapDispathToProps(dispath) {
    return {
        fetchRecipient: () => dispath(fetchRecipient()),
        fetchDashboard: () => dispath(fetchDashboard()),
        fetchProjects: () => dispath(fetchProjects()),
        performTask: (id, data) => dispath(performTask(id, data)),
        fetchAppointment: () => dispath(fetchAppointment()),
        fetchWorkersStatistic: (data) => dispath(fetchWorkersStatistic(data)),
        fetchWorkToday: () => dispath(fetchWorkToday()),
        closeDay: (data) => dispath(closeDay(data)),
        reOpenDay: () => dispath(reOpenDay()),
        nextOpenDay: () => dispath(nextOpenDay()),
        currentDay: () => dispath(currentDay()),
    }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(Dashboard));
