import axios from 'axios'

// production url
// export const API = axios.create({
//   baseURL: 'http://auto-crm-back/api/'
// })

// dev url
export const API = axios.create({
  baseURL: 'https://back.newlifeauto.com.ua/api/'
})

// local url
// export const API = axios.create({
//     baseURL: 'http://localhost:8080/api/'
// })
