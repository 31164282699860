import {
  FETCH_PRODUCT_WAYBILLS_START,
  FETCH_PRODUCT_WAYBILLS_SUCCESS,
  FETCH_PRODUCT_WAYBILLS_ERROR,
} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";


// export function createClient(data) {
//   return async dispatch => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await API.post('client/store', data, {headers: { Authorization: `Bearer ${token}` }});
//       return response;
//     } catch (e) {
//       console.error(e);
//     }
//
//   }
//
// }
// export function editClientSend(id, data) {
//   return async dispatch => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await API.put(`client/update/${id}`, data, {headers: { Authorization: `Bearer ${token}` }});
//       return response;
//     } catch (e) {
//       console.error(e);
//     }
//
//   }
//
// }

export function fetchProductWaybills(data = '') {
  return async dispatch => {
    dispatch(fetchProductWaybillsStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`sale-document/index${data}`, {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchProductWaybillsSuccess(response.data))
    } catch (e) {
      console.error(fetchProductWaybillsError(e));
    }
  }
}

export function fetchProductWaybillsStart() {
  return {
    type: FETCH_PRODUCT_WAYBILLS_START
  }
}

export function fetchProductWaybillsSuccess(productWaybills) {
  return {
    type: FETCH_PRODUCT_WAYBILLS_SUCCESS,
    productWaybills
  }
}

export function fetchProductWaybillsError(e) {
  return {
    type: FETCH_PRODUCT_WAYBILLS_ERROR,
    error: e
  }
}


export function fetchProductWaybillsPDF(id) {
  return async dispatch => {
    dispatch(fetchProductWaybillsStart())
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`work-order/${id}/product-receipt-pdf`, {headers: { Authorization: `Bearer ${token}` }})

      return response;
    } catch (e) {
      console.error(fetchProductWaybillsError(e));
    }
  }
}

