import React, {Component} from 'react'
import './Auth.css'


import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {connect} from "react-redux";
import {auth} from "../../store/action/auth";
import {NavLink} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../services/validationService";
import LanguagesHead from "../../components/Languages/LanguagesHead/LanguagesHead";

class Auth extends Component {

  loginHandler = () => {
    // this.props.auth(
    //   'email',
    //   'pass',
    //   true
    // )
  }

  state = {
    email: '',
    pass: '',
    error: true,
    success: true,

    validation: {
      email: false,
      pass : false
    }
  }

  handleLogin = () => {

    let dataForm = {
      email : this.state.email,
      pass : this.state.pass,
    };


    let validation = fieldEmpty(dataForm);
    let validEmail = fieldEmail(dataForm.email);
    let validPassword = fieldPassword(dataForm.pass);


    if (!validation.isValidation || !validEmail || !validPassword) {

      if(!validEmail){
        validation.validation.email = true;
      }

      if(!validPassword){
        validation.validation.pass = true;
      }

      this.setState({
        validation: validation.validation
      });

      return;
    }

    // if(this.state.email.length === 0){
    //   return;
    // }
    //
    // if(this.state.pass.length === 0){
    //   return;
    // }


    this.props.auth(
      this.state.email,
      this.state.pass,
      true
    ).then((response) => {
      if (response === undefined) {
        this.setState({success: false})
      }
    })

  }

  handleChangeEmail = (event) => {
    this.setState({email: event.target.value});
  }

  handleChangePass = (event) => {
    // console.log(event);
    this.setState({pass: event.target.value});
  }

  render() {

    return (
      <div className={'login-page'}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div >
            <div className="fixed-lang">
              <LanguagesHead/>
            </div>


            <Typography component="h1" variant="h5">
              <FormattedMessage id="app.signIn"/>
            </Typography>
            {/*<form  noValidate onSubmit={this.handleLogin}>*/}
            <form>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label={<FormattedMessage id="app.emailAddress"/>}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={this.handleChangeEmail}
                error={this.state.validation.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={<FormattedMessage id="app.password"/>}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.handleChangePass}
                error={this.state.validation.pass}
              />
              {/*<FormControlLabel*/}
                {/*control={<Checkbox value="remember" color="primary" />}*/}
                {/*label={<FormattedMessage id="app.rememberMe"/>}*/}
              {/*/>*/}
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.handleLogin}
              >
                <FormattedMessage id="app.signIn"/>
              </Button>
              {!this.state.success ?
                <div className="login-page__error">
                  <FormattedMessage id="app.dataIncorrectly"/>
                </div>
                : null
              }
              <Grid container className={'forgot-block'}>
                <Grid item xs>
                  <div href="#" variant="body2">
                    <NavLink to="/forgot">
                      <FormattedMessage id="app.forgotPassword"/>
                    </NavLink>
                  </div>

                </Grid>
              </Grid>
            </form>
          </div>

        </Container>
      </div>

    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    auth: (email, password, isLogin) => dispatch(auth(email, password, isLogin))
  }
}

export default connect(null, mapDispatchToProps)(Auth)
