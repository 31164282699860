import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import './Recording.css'
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import AddRecordingPopup from "../../components/ModalPopups/AddRecordingPopup/AddRecordingPopup";
import TableHeader from "../../components/Tables/TableHeader/TableHeader.js";
import connect from "react-redux/es/connect/connect";
import {fetchAppointment, fetchAppointmentList} from "../../store/action/appointment";


class Recording extends Component {

    state = {
        isOpen: false,
        isEdit: false,
        itemEdit: null,
        indexEdit: null,
        perPage: 30,
        currentPage: 0,
        search: '',
        dateStart: '',
        dateEnd: '',
    }

    componentDidMount() {
        this.props.fetchAppointment();

        document.title = this.props.intl.formatMessage({
            id: 'app.recording',
        });
    }

    addRecordingHandle = () => {
        this.setState({
            isOpen: true
        })
    }

    closePopupAddRecording = () => {
        this.setState({
            isOpen: false,
            isEdit: false
        })
    }

    reRender = () => {
        this.props.fetchAppointment();
    }
    // fetchAppointmentList
    editRecordHandle = (index, item) => {
        this.setState({
            isOpen: true,
            isEdit: true,
            itemEdit: item,
            indexEdit: index
        })
    }

    fetchDataCreate = (filter = false) => {

        let data = '',
            curPage = this.state.currentPage,
            perPage = this.state.perPage,
            dateStart = this.state.dateStart !== '' ? this.state.dateStart : null,
            dateEnd = this.state.dateEnd !== '' ? this.state.dateEnd : null,
            search = this.state.search !== '' ? this.state.search : null;

        if (filter) {
            curPage = 1;
            this.setState({currentPage: 1});
        }

        data = data + `?page=${curPage}&limit=${perPage}`;

        // if (search !== null) data = data + `&filter=name:${search}`;

        // between=created_at,2020-09-16:2020-09-16
        let date = '';

        if (dateStart !== null) date = date + `&between=date,${dateStart}`;

        if (dateEnd !== null) {
            if (dateStart === null) {
                date = date + `&between=date,:${dateEnd}`;
            } else {
                date = date + `:${dateEnd}`;
            }
        }

        data = data + date;

        this.props.fetchAppointmentList(data)
    }


    filterReset = () => {
        this.setState({
            search: '',
            dateStart: '',
            dateEnd: ''
        })

        setTimeout(() => this.props.fetchAppointment())
    }

    handleChangePage = (event, newPage) => {
        this.setState({currentPage: newPage})
        setTimeout(() => this.fetchDataCreate())
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({perPage: parseInt(event.target.value, 10)})
        this.setState({currentPage: 0})
        setTimeout(() => this.fetchDataCreate())
    }


    render() {

        let that = this;

        let headerTable = [
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.time"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.task"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.clientName"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.clientPhone"/>
            },
            {
                colspan: '0',
                align: 'left',
                item: <FormattedMessage id="app.responsible"/>
            },
            {
                colspan: '0',
                align: 'right',
                item: ''
            }
        ];

        return (
            <div className="recording">
                <div className="page-title">
           <span className={'header__name'}>
              <FormattedMessage id="app.recording"/>
            </span>
                </div>

                <div className="filter-head">
                    <div className="filter-head__left">
                        <div className="site-filter">
                            {/*<span><FormattedMessage id="app.selectDate"/></span>*/}
                            <TextField
                                type="date"
                                value={this.state.dateStart}
                                label={<FormattedMessage id="app.selectDateFrom"/>}
                                onChange={(event) => {
                                    this.setState({dateStart: event.target.value})
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                type="date"
                                label={<FormattedMessage id="app.selectDateFor"/>}
                                value={this.state.dateEnd}
                                onChange={(event) => {
                                    this.setState({dateEnd: event.target.value})
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>

                        <Button variant="contained" className="yellow-btn recording__add-btn"
                                onClick={this.filterReset}>
                            <FormattedMessage id="app.reset"/>
                        </Button>
                        <Button variant="contained" className="yellow-btn recording__add-btn"
                                onClick={() => this.fetchDataCreate(true)}>
                            <FormattedMessage id="app.search"/>
                        </Button>
                    </div>

                    <Button variant="contained" className="yellow-btn recording__add-btn"
                            onClick={this.addRecordingHandle}>
                        <FormattedMessage id="app.addNote"/>
                    </Button>


                    {this.state.isOpen ?
                        <PopupWrapper classPopup={'add-recording'} closePopup={this.closePopupAddRecording}>
                            <AddRecordingPopup reRender={this.reRender} isEdit={this.state.isEdit}
                                               itemEdit={this.state.itemEdit}
                                               indexEdit={this.state.indexEdit}/>
                        </PopupWrapper>
                        : ''}
                </div>

                {this.props.appointment.hasOwnProperty('data') ?
                    <div className="page-section">
                        <div className="page-section__name">
                            Записи
                        </div>
                        <div className="page-section__content">
                            <TableContainer
                                className={'recording-table' + (this.props.loading ? ' loading-table' : '')}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHeader rows={headerTable}/>

                                    <TableBody>


                                        {this.props.appointment.data.hasOwnProperty(0) ?
                                            Object.keys(this.props.appointment.data).map((item, index) => {
                                                return (

                                                    <TableRow className={'site-table-row'} key={index}>
                                                        <TableCell
                                                            align="left">{this.props.appointment.data[item].date}</TableCell>
                                                        <TableCell
                                                            align="left">{this.props.appointment.data[item].task_name}</TableCell>
                                                        <TableCell
                                                            align="left">{this.props.appointment.data[item].client_name}</TableCell>
                                                        <TableCell
                                                            align="left">{this.props.appointment.data[item].client_phone}</TableCell>
                                                        <TableCell
                                                            align="left">{this.props.appointment.data[item].user_name}</TableCell>
                                                        <TableCell align="right">
                                                            <Button variant="contained" color="primary" size="small"
                                                                    onClick={() => that.editRecordHandle(index, this.props.appointment.data[item])}>
                                                                <FormattedMessage id="app.edit"/>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })

                                            :

                                            <TableRow className={'site-table-row'}>
                                                <TableCell align="left" colSpan={6}>
                                                    <h2 className={'no-records'}>
                                                        <FormattedMessage
                                                            id="app.noRecords"/></h2></TableCell>
                                            </TableRow>

                                        }
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    :

                    <React.Fragment>

                        {/* TODAY */}
                        <div className="page-section">
                            <div className="page-section__name">
                                <FormattedMessage id="app.today"/>
                                {/*{this.props.appointment.length > 0 && this.props.appointment.day.length > 0 ?*/}
                                {/*this.props.appointment.day[0].date*/}
                                {/*: null*/}
                                {/*}*/}
                            </div>
                            <div className="page-section__content">
                                <TableContainer
                                    className={'recording-table' + (this.props.loading ? ' loading-table' : '')}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHeader rows={headerTable}/>
                                        <TableBody>
                                            {this.props.appointment.hasOwnProperty('day_0') && this.props.appointment.day_0.length > 0 ?
                                                this.props.appointment.day_0.map(function (item, index) {
                                                    return (
                                                        <TableRow className={'site-table-row'} key={index}>
                                                            <TableCell align="left">{item.date}</TableCell>
                                                            <TableCell align="left">{item.task_name}</TableCell>
                                                            <TableCell align="left">{item.client_name}</TableCell>
                                                            <TableCell align="left">{item.client_phone}</TableCell>
                                                            <TableCell align="left">{item.user_name}</TableCell>
                                                            <TableCell align="right">
                                                                <Button variant="contained" color="primary" size="small"
                                                                        onClick={() => that.editRecordHandle(index, item)}>
                                                                    <FormattedMessage id="app.edit"/>
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })

                                                :

                                                <TableRow className={'site-table-row'}>
                                                    <TableCell align="left" colSpan={6}><h2 className={'no-records'}>
                                                        <FormattedMessage
                                                            id="app.noRecords"/></h2></TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        {/* TOMORROW */}

                        <div className="page-section">
                            <div className="page-section__name">
                                <FormattedMessage id="app.tomorrow"/>
                            </div>
                            <div className="page-section__content">
                                <TableContainer
                                    className={'recording-table' + (this.props.loading ? ' loading-table' : '')}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHeader rows={headerTable}/>
                                        <TableBody>

                                            {this.props.appointment.hasOwnProperty('day_1') && this.props.appointment.day_1.length > 0 ?
                                                this.props.appointment.day_1.map(function (item, index) {
                                                    return (

                                                        <TableRow className={'site-table-row'} key={index}>
                                                            <TableCell align="left">{item.date}</TableCell>
                                                            <TableCell align="left">{item.task_name}</TableCell>
                                                            <TableCell align="left">{item.client_name}</TableCell>
                                                            <TableCell align="left">{item.client_phone}</TableCell>
                                                            <TableCell align="left">{item.user_name}</TableCell>
                                                            <TableCell align="right">
                                                                <Button variant="contained" color="primary" size="small"
                                                                        onClick={() => that.editRecordHandle(index, item)}>
                                                                    <FormattedMessage id="app.edit"/>
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })

                                                :

                                                <TableRow className={'site-table-row'}>
                                                    <TableCell align="left" colSpan={6}><h2 className={'no-records'}>
                                                        <FormattedMessage
                                                            id="app.noRecords"/></h2></TableCell>
                                                </TableRow>

                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        {Object.keys(this.props.appointment)
                            .filter((day) => day !== 'day_0' && day !== 'day_1')
                            .map((dayKey, index) => {
                                const dayData = this.props.appointment[dayKey];

                                return dayData.length > 0 ? (
                                    <div className="page-section" key={dayData[0].date}>
                                        <div className="page-section__name">
                                            {new Date(dayData[0].date).toLocaleDateString("uk-UK")}
                                        </div>

                                        <div className="page-section__content">
                                            <TableContainer
                                                className={'recording-table' + (this.props.loading ? ' loading-table' : '')}>
                                                <Table size="small" aria-label="a dense table">
                                                    <TableHeader rows={headerTable}/>
                                                    <TableBody>

                                                        {dayData.length ? dayData.map((item, index) =>
                                                                <TableRow className={'site-table-row'}
                                                                          key={index}>
                                                                    <TableCell
                                                                        align="left">{item.date}</TableCell>
                                                                    <TableCell
                                                                        align="left">{item.task_name}</TableCell>
                                                                    <TableCell
                                                                        align="left">{item.client_name}</TableCell>
                                                                    <TableCell
                                                                        align="left">{item.client_phone}</TableCell>
                                                                    <TableCell
                                                                        align="left">{item.user_name}</TableCell>
                                                                    <TableCell align="right">
                                                                        <Button variant="contained"
                                                                                color="primary" size="small"
                                                                                onClick={() => that.editRecordHandle(index, item)}>
                                                                            <FormattedMessage
                                                                                id="app.edit"/>
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                            :

                                                            <TableRow className={'site-table-row'}>
                                                                <TableCell align="left" colSpan={6}><h2
                                                                    className={'no-records'}>
                                                                    <FormattedMessage
                                                                        id="app.noRecords"/></h2></TableCell>
                                                            </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                ) : ''
                            })
                        }

                    </React.Fragment>
                }
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        appointment: state.appointment.appointment,
        loading: state.appointment.loading,
    }
}

function mapDispathToProps(dispath) {
    return {
        fetchAppointment: () => dispath(fetchAppointment()),
        fetchAppointmentList: (data) => dispath(fetchAppointmentList(data))
    }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(Recording));

