import {
    FETCH_PRIVATE_ENTREPRENEUR_ERROR,
    FETCH_PRIVATE_ENTREPRENEUR_START,
    FETCH_PRIVATE_ENTREPRENEUR_SUCCESS
} from "../action/actionTypes";

const initialState = {
    privateEnts: {},
    loading: false,
    error: null
}

export default function privateEntsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PRIVATE_ENTREPRENEUR_START:
            return {
                ...state, loading: true
            }

        case FETCH_PRIVATE_ENTREPRENEUR_SUCCESS:
            return {
                ...state, loading: false, privateEnts: action.privateEnts
            }

        case FETCH_PRIVATE_ENTREPRENEUR_ERROR:
            return {
                ...state, loading: false, error: action.error
            }

        default:
            return state
    }
}
