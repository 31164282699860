import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import './AddProductWaybillsPopup.css';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage} from "react-intl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AutocompliteItem from "../../AutocompliteItem/AutocompliteItem";
import {fetchProjects} from "../../../store/action/projects";
import connect from "react-redux/es/connect/connect";
import {fetchSupplier} from "../../../store/action/settings";
import {
  completeDelivery,
  createDelivery,
  createProduct,
  fetchProducts,
  fetchProductsAll
} from "../../../store/action/products";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../../services/validationService";


class AddProductWaybillsPopup extends Component {

  state = {
    supplier: '',
    supplier_id: '',
    supplier_number: '',
    indexInput: false,
    createProduct: false,
    create: false,
    visible: true,
    readOnly: false,
    products: [
      {
        id: '',
        name: '',
        article: '',
        stock: '',
        last_net_price: '',
        price: '',
        productData: '',
        amount: ''
      }
    ],
    newProduct: {
      name: '',
      article: '',
      stock: '',
      last_net_price: '',
      supplier_id: '',
      price: '',
    },


    validation: {
      supplier_number: false,
      supplier_id: false,
      table: false,
    },

    new: false,
    newTxt: '',
  }


  componentDidMount() {
    this.props.fetchSupplier();
    this.props.fetchProductsAll();
  }


  handleClose = () => {
    this.props.handleClose();
  }

  changeHandler = (index, val) => {
    let newVal = this.state.products;
    newVal[index].name = val;
    this.setState({
      products: newVal
    });
  }

  handleChangeParts = (newValue, event, key) => {

    let index = key;
    let newVal = this.state.products;
    if (newValue === null) {
      newVal[index].name = '';
    } else {
      newVal[index].id = newValue.id;
      newVal[index].name = newValue.name;
      newVal[index].article = newValue.article;
      newVal[index].stock = newValue.stock;
      newVal[index].last_net_price = newValue.last_net_price;
      newVal[index].price = newValue.price;
      // console.log(newValue);
    }
    this.setState({
      products: newVal
    });
  }

  handleChangeArticle = (event) => {
    let index = event.target.dataset.key;
    let newVal = this.state.products;
    newVal[index].article = event.target.value;
    this.setState({
      products: newVal
    });
  }

  handleChangeCount = (event) => {
    let index = event.target.dataset.key;
    let newVal = this.state.products;
    newVal[index].amount = event.target.value;
    this.setState({
      products: newVal
    });
  }

  handleBlurCount = (event) => {
    let index = event.target.dataset.key;
    let newVal = this.state.products;
    let value = event.target.value;
    if(value < 0){
      value = value * -1;
    }
    newVal[index].amount = value;
    this.setState({
      products: newVal
    });
  }

  handleBlurPrice = (event) => {
    let index = event.target.dataset.key;
    let newVal = this.state.products;
    let value = event.target.value;
    if(value < 0){
      value = value * -1;
    }
    newVal[index].last_net_price = value;
    this.setState({
      products: newVal
    });
  }

  handleChangePrice = (event) => {
    let index = event.target.dataset.key;
    let newVal = this.state.products;
    newVal[index].last_net_price = event.target.value;
    this.setState({
      products: newVal
    });
  }

  handleChangeSellPrice = (event) => {
    let index = event.target.dataset.key;
    let newVal = this.state.products;
    newVal[index].price = event.target.value;
    this.setState({
      products: newVal
    });
  }

  handleBlurSellPrice = (event) => {
    let index = event.target.dataset.key;
    let newVal = this.state.products;
    let value = event.target.value;
    if(value < 0){
      value = value * -1;
    }
    newVal[index].price = value;
    this.setState({
      products: newVal
    });
  }

  addProductHandler = () => {
    let product = {
      id: '',
      name: '',
      article: '',
      stock: '',
      amount: '',
      last_net_price: '',
      price: '',
      productData: '',
    }
    let oldProducts = this.state.products;
    oldProducts.push(product);

    this.setState({
      products: oldProducts
    });
  }

  removeProductHandler = (event) => {
    let index = event.target.dataset.key;
    let oldProducts = this.state.products;
    oldProducts.splice(index, 1);

    this.setState({
      products: oldProducts
    });
  }

  supplierChange = (newValue) => {
    this.setState({
      supplier_id: newValue.id,
      supplier: newValue
    });

    this.props.fetchProductsAll(`?filter=supplier_id:${newValue.id}`);


  }

  handleCreate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;

    let queryDataProducts = [];
    let queryData;

    this.state.products.map((item) => {
      let newItem = {
        article_id: item.id,
        article_name: item.name,
        article: item.article,
        amount: item.amount,
        supplier_price: item.last_net_price,
        price: item.price
      }

      queryDataProducts.push(newItem);
    });

    queryData = {
      supplier_id: this.state.supplier_id,
      supplier_number: this.state.supplier_number,
      description: '',
      delivery_date: today,
      details: queryDataProducts
    }

    let valid = {
      supplier_id: this.state.supplier_id,
      supplier_number: this.state.supplier_number,
    }

    let flag = false;
    let spareParts = this.state.products[0].name;
    let validation = fieldEmpty(valid);


    if (spareParts === '') {
      validation.validation.table = true;
      flag = true;
    } else {
      validation.validation.table = false
    }

    if (!validation.isValidation) {
      flag = true;
    }

    this.setState({
      validation: validation.validation
    });

    if (flag) {
      return;
    }


    this.props.createDelivery(queryData).then((response) => {
      // console.log(response);
      this.props.completeDelivery(response.data.id).then(() => {
        this.props.reRender();
      })

    });

    this.props.handleClose();

  }

  closeAddProduct = () => {
    this.setState({createProduct: false})
  }

  newProductChangeHandler = (name, event) => {
    let newVal = this.state.newProduct;
    newVal[name] = event.target.value;
    this.setState({
      newProduct: newVal
    });
  }

  newProductBlurHandler = (name, event) => {
    let newVal = this.state.newProduct;
    let value = event.target.value;
    if(value < 0){
      value = value * -1;
    }
    newVal[name] = value;
    this.setState({
      newProduct: newVal
    });
  }

  newProductSupplier = (newValue) => {
    let product = this.state.newProduct;

    product.supplier_id = newValue.id;

    this.setState({
      newProduct: product
    });
  }


  createProductHandle = () => {
    let product = this.state.newProduct;
    let stock = this.state.newProduct.stock;
    product.stock = 0;
    product.last_net_price = product.last_net_price.replace(',', '.');
    product.price = product.price.replace(',', '.');

    this.props.createProduct(product).then((response) => {

      if(response.data === 'Name non unique'){
        this.setState(
          {new: true, newTxt: 'Такое имя уже существует'}
        )
        return;
      }
      if(response.data === 'Article code non unique'){
        this.setState(
          {new: true, newTxt: 'Такой артикул уже существует'}
        )
        return;
      }

      let responseProduct = response;

      this.props.fetchProductsAll().then(() => {
        this.setState({visible: false})
        let newProduct = {
          id: responseProduct.data.id,
          name: responseProduct.data.name,
          article: responseProduct.data.article,
          amount: stock,
          last_net_price: responseProduct.data.last_net_price,
          price: responseProduct.data.price,
          productData: responseProduct.data
        }

        let newVal = this.state.products;
        if (newVal.length === 1 && newVal[0].id === '') {
          newVal = [];
        }
        newVal.push(newProduct);

        setTimeout(() => {
          this.setState({visible: true})
        }, )
        this.setState({
          products: newVal
        });

        let newValNewProduct = {
          name: '',
          article: '',
          stock: '',
          last_net_price: '',
          price: '',
        }
        this.setState({
          newProduct: newValNewProduct,
          createProduct: false,
          readOnly: true
        });

      })


    });
  }

  createNewProductHandler = () => {
    let product = this.state.newProduct;
    product.supplier_id = this.state.supplier_id;
    this.setState({createProduct: true, newProduct: product})
  }


  render() {

    let that = this;
    return (
      <div className="modal-popup__window add-product-popup-inner">
        <div className="modal-popup__header">
                <span>
                <FormattedMessage id="app.addWaybill"/>
                </span>
        </div>
        <div className="modal-popup__body">
          <div className="row">
            <div className="col-md-4">
              <div className="modal-popup__row">
                <TextField
                  onChange={(event) => {
                    this.setState({supplier_number: event.target.value})
                  }}
                  error={this.state.validation.supplier_number}
                  label={<FormattedMessage id="app.invoiceNumber"/>}
                  className={"modal-popup__input"}
                  fullWidth
                  margin="normal"
                />
              </div>
            </div>
            <div className="col-md-8">
            {
              that.props.suppliers !== undefined ?
                that.props.suppliers.length > 0 ?
                <div className="modal-popup__row">
                  <Autocomplete
                    freeSolo
                    className={"filter-client-select" + (this.state.readOnly ? ' read' : '')}
                    onChange={(event, newValue) => {
                      this.supplierChange(newValue, event)
                    }}
                    options={this.props.suppliers}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params}
                                                        label={<FormattedMessage id="app.provider"/>}
                                                        className={"modal-popup__input"}
                                                        margin="normal"
                                                        error={this.state.validation.supplier_id}
                    />}
                  />
                </div>
                :
                  <React.Fragment>
                    <div className="error-txt">
                      <FormattedMessage id="app.suppliersError"/>
                    </div>
                  </React.Fragment>
              : null
            }
            </div>

          </div>

          {this.state.supplier_id === '' ? '' :
            <React.Fragment>
              {this.state.products.map((item, key1) => {

                return (
                  <div className="row" key={key1}>
                    <div className={'waybills-popup__works col-12'}>
                      <div className="product-waybills-popup__work-head row">
                        <div className="col-md-4">
                          {this.state.visible ?
                            <React.Fragment>
                              {Object.keys(item.productData).length > 0 ?
                                <Autocomplete
                                  value={item.productData}
                                  options={that.props.productsAll}
                                  getOptionLabel={(option) => option.name + ' ' + option.article + (option.short_supplier_name !== '' ? '_' + option.short_supplier_name : '')}
                                  onChange={(event, newValue) => {
                                    this.handleChangeParts(newValue, event, key1)
                                  }}
                                  // changeHandler={that.changeHandler}
                                  renderInput={(params) => <TextField {...params}
                                                                      label={<FormattedMessage id="app.sparePartsName"/>}
                                                                      size={'small'}
                                                                      className={"task-create__name-input"}
                                                                      inputProps={{
                                                                        ...params.inputProps,
                                                                      }}
                                  />}
                                />

                                :

                                <Autocomplete
                                  options={that.props.productsAll}
                                  getOptionLabel={(option) => option.name + ' ' + option.article + (option.short_supplier_name !== '' ? '_' + option.short_supplier_name : '')}
                                  onChange={(event, newValue) => {
                                    this.handleChangeParts(newValue, event, key1)
                                  }}
                                  // changeHandler={that.changeHandler}
                                  renderInput={(params) => <TextField {...params}
                                                                      label={<FormattedMessage id="app.sparePartsName"/>}
                                                                      size={'small'}
                                                                      className={"task-create__name-input"}
                                                                      inputProps={{
                                                                        ...params.inputProps,
                                                                      }}
                                  />}
                                />
                              }
                            </React.Fragment>
                            :
                            null
                          }


                        </div>
                        <div className="col-md-4">
                          <TextField className={"task-create__name-input"}
                                     onChange={that.handleChangeArticle}
                                     value={item.article}
                                     inputProps={{'data-key': key1}}
                                     label={<FormattedMessage id="app.vendorCode"/>}/>
                        </div>

                        <div className="col-md-4">
                          <TextField className={"task-create__name-input"}
                                     onChange={that.handleChangeCount}
                                     onBlur={that.handleBlurCount}
                                     value={item.amount}
                                     inputProps={{'data-key': key1}}
                                     label={<FormattedMessage id="app.count"/>}/>
                        </div>
                      </div>

                      <div className="waybills-popup__work-name row">

                        <div className="col-md-4">
                          <TextField className={"task-create__name-input"} id="standard-basic"
                                     onChange={that.handleChangePrice}
                                     onBlur={that.handleBlurPrice}
                                     value={item.last_net_price}
                                     inputProps={{'data-key': key1}}
                                     label={<FormattedMessage id="app.purchasePrice"/>}/>
                        </div>
                        <div className="col-md-4">
                          <TextField className={"task-create__name-input"} id="standard-basic"
                                     onChange={that.handleChangeSellPrice}
                                     onBlur={that.handleBlurSellPrice}
                                     value={item.price}
                                     inputProps={{'data-key': key1}}
                                     label={<FormattedMessage id="app.sellingPrice"/>}/>
                        </div>
                        <div className="col-md-4 d-flex justify-content-end align-items-end ">
                          <Button variant="contained" color="primary" size="small" data-key={key1} className={'add-list-product-btn'}
                                  onClick={that.removeProductHandler}>
                            {/*<FormattedMessage id="app.remove"/>*/}
                            +
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

              <div className="row">
                <div className={'waybills-popup__works col-12 waybills-popup__works--btn'}>
                  <Button variant="contained" color="primary" size="small" onClick={this.addProductHandler}>
                    <FormattedMessage id="app.add"/>
                  </Button>

                </div>
              </div>
            </React.Fragment>
          }



        </div>


        {this.state.createProduct ?
          <div className={'create-product-block row'}>
            <div className="col-md-12">
              <div className="modal-popup__header">
                <span>
                <FormattedMessage id="app.addProduct"/>
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="modal-popup__row">
                <TextField
                  label={<FormattedMessage id="app.productsName"/>}
                  value={this.state.newProduct.name}
                  onChange={(event) => this.newProductChangeHandler('name', event)}
                  className={"modal-popup__input"}
                  fullWidth
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="modal-popup__row">
                {/*{*/}
                  {/*that.props.suppliers !== undefined ?*/}
                    {/*that.props.suppliers.length > 0 ?*/}
                      {/*<div className="modal-popup__row">*/}
                        {/*<Autocomplete*/}
                          {/*className="filter-client-select"*/}
                          {/*onChange={(event, newValue) => {*/}
                            {/*this.newProductSupplier(newValue, event)*/}
                          {/*}}*/}
                          {/*options={this.props.suppliers}*/}
                          {/*getOptionLabel={(option) => option.name}*/}
                          {/*renderInput={(params) => <TextField {...params}*/}
                                                              {/*label={<FormattedMessage id="app.provider"/>}*/}
                                                              {/*className={"modal-popup__input"}*/}
                                                              {/*margin="normal"*/}
                                                              {/*// error={this.state.validation.supplier_id}*/}
                          {/*/>}*/}
                        {/*/>*/}
                      {/*</div>*/}
                      {/*:*/}
                      {/*<React.Fragment>*/}
                        {/*<div className="error-txt">*/}
                          {/*<FormattedMessage id="app.suppliersError"/>*/}
                        {/*</div>*/}
                      {/*</React.Fragment>*/}
                    {/*: null*/}
                {/*}*/}

                <TextField
                  value={this.state.supplier.name}
                  // onChange={(event) => this.newProductChangeHandler('article', event)}
                  label={<FormattedMessage id="app.provider"/>}
                  className={"modal-popup__input"}
                  fullWidth
                  InputLabelProps={{
                    readOnly: Boolean(true),
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="modal-popup__row">
                <TextField
                  value={this.state.newProduct.article}
                  onChange={(event) => this.newProductChangeHandler('article', event)}
                  label={<FormattedMessage id="app.vendorCode"/>}
                  className={"modal-popup__input"}
                  fullWidth
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="modal-popup__row">
                <TextField
                  value={this.state.newProduct.stock}
                  onChange={(event) => this.newProductChangeHandler('stock', event)}
                  onBlur={(event) => this.newProductBlurHandler('stock', event)}
                  label={<FormattedMessage id="app.count"/>}
                  className={"modal-popup__input"}
                  fullWidth
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="modal-popup__row">
                <TextField
                  value={this.state.newProduct.last_net_price}
                  onChange={(event) => this.newProductChangeHandler('last_net_price', event)}
                  onBlur={(event) => this.newProductBlurHandler('last_net_price', event)}
                  label={<FormattedMessage id="app.purchasePrice"/>}
                  className={"modal-popup__input"}
                  fullWidth
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="modal-popup__row">
                <TextField
                  value={this.state.newProduct.price}
                  onChange={(event) => this.newProductChangeHandler('price', event)}
                  onBlur={(event) => this.newProductBlurHandler('price', event)}
                  label={<FormattedMessage id="app.sellingPrice"/>}
                  className={"modal-popup__input"}
                  fullWidth
                />
              </div>
            </div>
            <div className={'waybills-popup__works col-12 waybills-popup__works--btn'}>
              <Button variant="contained" color="secondary" size="small" onClick={this.closeAddProduct}>
                <FormattedMessage id="app.close"/>
              </Button>
              <Button variant="contained" color="primary" className={"modal-popup__btn-i"} size="small"
                      onClick={this.createProductHandle}>
                <FormattedMessage id="app.add"/>
              </Button>
            </div>

            {this.state.new ?
              <div className={'error-txt col-md-12'}>
                {this.state.newTxt}
              </div>
              :
              null
            }
          </div>
          :
          null
        }


        {this.state.validation.table ?
          <div className={'error-txt'}>
            Заполните обьязательные поля
          </div>
          :
          null
        }


        <div className="modal-popup__footer">
          <div className="modal-popup__btn">
            <Button variant="contained" color="secondary" className={"modal-popup__btn-i product-waybills-close-btn"} onClick={this.handleClose}>
              <FormattedMessage id="app.close"/>
            </Button>

            {this.state.supplier_id === '' ? '' :
              <Button variant="contained" color="primary" size="small" className={"modal-popup__btn-i yellow-btn"}
                      onClick={() => {
                        this.createNewProductHandler()
                      }}>
                <FormattedMessage id="app.addNewProduct"/>
              </Button>
            }


            <Button variant="contained" color="primary" className={"modal-popup__btn-i"} onClick={this.handleCreate}>
              <FormattedMessage id="app.save"/>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    loading: state.supplier.loading,
    suppliers: state.supplier.supplier,
    products: state.products.products,
    productsAll: state.products.productsAll,
  }

}

function mapDispathToProps(dispath) {
  return {
    fetchSupplier: () => dispath(fetchSupplier()),
    fetchProductsAll: (data) => dispath(fetchProductsAll(data)),
    createProduct: (data) => dispath(createProduct(data)),
    createDelivery: (data) => dispath(createDelivery(data)),
    completeDelivery: (id) => dispath(completeDelivery(id)),
  }
}

export default connect(mapStateToProps, mapDispathToProps)(AddProductWaybillsPopup);

