import {
    FETCH_CLIENTS_START,
    FETCH_CLIENTS_SUCCESS,
    FETCH_CLIENTS_ERROR,
    FETCH_CLIENTS_CAR_BRAND,
    FETCH_CLIENTS_CAR_BRAND_ERROR,
    FETCH_CLIENTS_CAR_MODELS,
    CLEAR_CLIENTS_CAR_MODELS,
    FETCH_CLIENTS_CAR_MODELS_ERROR
} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";


export function createClient(data) {
    return async dispatch => {
        try {
            const token = localStorage.getItem('token');
            const response = await API.post('client/store', data, {headers: {Authorization: `Bearer ${token}`}});
            return response;
        } catch (e) {
            console.error(e);
        }

    }

}

export function editClientSend(id, data) {
    return async dispatch => {
        try {
            const token = localStorage.getItem('token');
            const response = await API.put(`client/update/${id}`, data, {headers: {Authorization: `Bearer ${token}`}});
            return response;
        } catch (e) {
            console.error(e);
        }

    }

}

export function fetchClients(data = '') {
    return async dispatch => {
        dispatch(fetchClientsStart())
        const token = localStorage.getItem('token');
        try {
            const response = await API.get(`client/index${data}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            dispatch(fetchClientsSuccess(response.data));
        } catch (e) {
            console.error(fetchClientsError(e));
        }
    }
}

export function fetchClientsStart() {
    return {
        type: FETCH_CLIENTS_START
    }
}

export function fetchClientsSuccess(clients) {
    return {
        type: FETCH_CLIENTS_SUCCESS,
        clients
    }
}

export function fetchClientsError(e) {
    return {
        type: FETCH_CLIENTS_ERROR,
        error: e
    }
}


export function fetchClientCarsBrand() {
    return async dispatch => {
        // dispatch(fetchClientsStart())
        const token = localStorage.getItem('token');
        try {
            const response = await API.get(`car/brands`, {headers: {Authorization: `Bearer ${token}`}})

            dispatch(fetchClientCarsBrandSuccess(response.data))
        } catch (e) {
            console.error(fetchClientCarsBrandError(e));
        }
    }
}


export function fetchClientCarsBrandSuccess(carsBrand) {
    return {
        type: FETCH_CLIENTS_CAR_BRAND,
        carsBrand
    }
}


export function fetchClientCarsBrandError(e) {
    return {
        type: FETCH_CLIENTS_CAR_BRAND_ERROR,
        error: e
    }
}

export function fetchClientCarModels(brand) {
    return async dispatch => {
        // dispatch(fetchClientsStart())
        const token = localStorage.getItem('token');
        try {
            const response = await API.get(`car/models/${brand}`, {headers: {Authorization: `Bearer ${token}`}})

            dispatch(fetchClientCarModelsSuccess(response.data))
        } catch (e) {
            console.error(fetchClientCarModelsError(e));
        }
    }
}

export function clearClientCarModels() {
    return {
        type: CLEAR_CLIENTS_CAR_MODELS
    }
}


export function fetchClientCarModelsSuccess(carModels) {
    return {
        type: FETCH_CLIENTS_CAR_MODELS,
        carModels
    }
}


export function fetchClientCarModelsError(e) {
    return {
        type: FETCH_CLIENTS_CAR_MODELS_ERROR,
        error: e
    }
}
