import React from 'react';
import './App.css';
import '../node_modules/normalize.css/normalize.css'
import '../node_modules/bootstrap/dist/css/bootstrap-grid.min.css'
import {connect} from 'react-redux'
import Layout from "./hoc/Layout/Layout";
import {Route, Switch, Redirect} from 'react-router-dom'
import Dashboard from "./containers/Dashboard/Dashboard";
import Projects from "./containers/Projects/Projects";
import ProjectItem from "./containers/ProjectItem/ProjectItem";
import ErrorPage from "./containers/ErrorPage/ErrorPage";
import Auth from "./containers/Auth/Auth";
import {autoLogin} from "./store/action/auth";
import Forgot from "./containers/Auth/Forgot/Forgot";
import Drafts from "./containers/Drafts/Drafts";
import Clients from "./containers/Clients/Clients";
import Products from "./containers/Products/Products";
import Recording from "./containers/Recording/Recording";
import Settings from "./containers/Settings/Settings";
import Bills from "./containers/Bills/Bills";
import Outfits from "./containers/Outfits/Outfits";
import ProductWaybills from "./containers/ProductWaybills/ProductWaybills";
import Invoice from "./containers/Invoice/Invoice";
import Clients2 from "./containers/Clients/Clients2";
import CompletedTask from "./containers/CompletedTask/CompletedTask";
import Reports from "./containers/Reports/Reports";
import Expenses from "./containers/Expenses/Expenses";
import WorkersStatistic from "./containers/WorkersStatistic/WorkersStatistic";
import ReportCard from "./containers/ReportCard/ReportCard";
import NewPass from "./containers/Auth/NewPass/NewPass";
import PrivateEntrepreneurs from "./containers/PrivateEntrepreneurs/PrivateEntrepreneurs";

class App extends React.Component {

  state = {
    login: true
  }

  componentDidMount() {
    let login = this.props.autoLogin()
    // this.setState({login: login});
  }

  render() {

    let routes = (

      <Switch>
        <Route path='/' exact render={() => <Dashboard/>}/>
        <Route exact path="/autocrm">
          <Redirect to="/"/>
        </Route>
        <Route exact path="/auth">
          <Redirect to="/"/>
        </Route>
        <Route path='/projects' exact render={() => <Projects/>}/>
        <Route path='/project/:id' exact render={(props) => <ProjectItem {...props} />}/>
        <Route path='/drafts' exact render={() => <Drafts/>}/>
        <Route path='/task/completed' exact render={() => <CompletedTask/>}/>
        <Route path='/clients' exact render={() => <Clients/>}/>
        <Route path='/private-entrepreneurs' exact render={() => <PrivateEntrepreneurs/>}/>
        <Route path='/products' exact render={() => <Products/>}/>
        <Route path='/recording' exact render={() => <Recording/>}/>
        <Route path='/Settings' exact render={() => <Settings/>}/>
        <Route path='/bills' exact render={() => <Bills/>}/>
        <Route path='/reports' exact render={() => <Reports/>}/>
        <Route path='/expenses' exact render={() => <Expenses/>}/>
        <Route path='/product-waybills' exact render={() => <ProductWaybills/>}/>
        <Route path='/waybills' exact render={() => <Outfits/>}/>
        <Route path='/waybills/:id' exact render={() => <Outfits/>}/>
        <Route path='/workers-statistic' exact render={() => <WorkersStatistic/>}/>
        <Route path='/report-card' exact render={() => <ReportCard/>}/>
        {/*<Route path='/invoice' exact render={() => <Invoice/>}/>*/}
        <Route render={() => <ErrorPage/>}/>
      </Switch>
    )

    if (!this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path='/auth' exact render={() => <Auth/>}/>
          <Route path='/forgot' exact render={() => <Forgot/>}/>
          <Route path='/password/reset/:id' exact render={() => <NewPass/>}/>
          <Redirect to="/auth"/>

          {/*<Route path='/' exact render={() => <Dashboard/>}/>*/}
          {/*<Route exact path="/autocrm">*/}
          {/*<Redirect to="/" />*/}
          {/*</Route>*/}
          {/*<Route path='/projects' exact render={() => <Projects/>}/>*/}
          {/*<Route path='/project/:id' exact render={(props) => <ProjectItem {...props} />}/>*/}
          {/*<Route render={() => <ErrorPage/>}/>*/}
        </Switch>
      )
    }

    return (

      <div className={"site-wrap"  + (this.props.menu ? ' menu' : '')}>
        <Layout isAuthenticated={this.props.isAuthenticated}>
          {routes}
        </Layout>
      </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.main.menu,
    currentLocale: state.intl,
    isAuthenticated: state.auth.isAuthenticated
  }
}

function mapDispathToProps(dispath) {
  return {
    autoLogin: () => dispath(autoLogin())
  }
}


export default connect(mapStateToProps, mapDispathToProps)(App);
