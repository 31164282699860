import {  FETCH_BILLS_START,
  FETCH_BILLS_SUCCESS,
  FETCH_BILLS_ERROR} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";
import {fetchProductsError} from "./products";



export function fetchRecipient() {
  return async dispatch => {
    // dispatch(fetchDraftsStart())
    try {
      const token = localStorage.getItem('token');
      const response = await API.get(`recipient/index`, {headers: { Authorization: `Bearer ${token}` }})

      return response;
      // dispatch(fetchDraftsSuccess(response.data))
    } catch (e) {
      console.error(fetchDraftsError(e));
    }
  }
}

export function fetchBills(data = '') {
  return async dispatch => {
    dispatch(fetchDraftsStart())
    try {
      const token = localStorage.getItem('token');
      const response = await API.get(`invoice/index-income${data}`, {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchDraftsSuccess(response.data))
    } catch (e) {
      console.error(fetchDraftsError(e));
    }
  }
}

export function fetchDraftsStart() {
  return {
    type: FETCH_BILLS_START
  }
}

export function fetchDraftsSuccess(bills) {
  return {
    type: FETCH_BILLS_SUCCESS,
    bills
  }
}

export function fetchDraftsError(e) {
  return {
    type: FETCH_BILLS_ERROR,
    error: e
  }
}


export function exportReports(data) {
  return async dispatch => {
    const token = localStorage.getItem('token');
    try {
      const response = await API.get(`invoice/export${data}`, {headers: { Authorization: `Bearer ${token}` }})

      // API({
      //   url: 'invoice/export',
      //   method: 'GET',
      //   responseType: 'blob',
      //   headers: { Authorization: `Bearer ${token}` }
      // }).then((response) => {
      //   const url = window.URL.createObjectURL(new Blob([response.data]));
      //   console.log(url);
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.setAttribute('download', 'www' +'.csv');
      //   document.body.appendChild(link);
      //   link.click();
      //
      // }).catch((error) => {
      //
      // });

      return response.data;
    } catch (e) {
      console.error(fetchProductsError(e));
    }
  }
}
