import React, {Component} from 'react'
import './AddExpensesPopup.css'

import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage} from "react-intl";
import {fetchClientCarsBrand} from "../../../store/action/clients";
import connect from "react-redux/es/connect/connect";
import {createExpenses, fetchExpensesType} from "../../../store/action/settings";
import {editMaster} from "../../../store/action/settings";
import {logout} from "../../../store/action/auth";
import {openMenu} from "../../../store/action/main";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../../services/validationService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {addExpenses, editExpenses} from "../../../store/action/expenses";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {fetchRecipient} from "../../../store/action/bills";
import Tab from "../../../containers/Reports/Reports";


class AddExpensesPopup extends Component {

  state = {
    id: '',
    idExpenses: '',
    name: '',
    date: '',
    price: '',
    expense: '',
    expenseFop: '1',
    recipient: [],

    validation: {
      name: false,
      date: false,
      price: false,
    }
  }

  componentDidMount() {
    this.props.fetchRecipient().then((response) => {
      this.setState({
        recipient: response.data
      })
    });
    this.props.fetchExpensesType();

    if (this.props.isEdit) {
      let date = this.props.item.date;

      this.setState({
        id: this.props.item.id,
        name: this.props.item.expenses_type.name,
        expense: this.props.item.expenses_type,
        idExpenses: this.props.item.expenses_type.id,
        date: date,
        price: this.props.item.price,
        expenseFop: this.props.item.recipient_id,
      })
    }
  }

  handleClose = () => {
    this.props.handleClose();
  }

  handleChangeExpensesType = (newValue, event) => {
    if (newValue === null) return;

    this.setState({
      idExpenses: newValue.id,
      name: newValue.name,
      expense: newValue,
    })
  }

  handlerSaveForm = () => {
    let dataForm = {
      expenses_type_id: this.state.idExpenses,
      name: this.state.name,
      date: this.state.date,
      price: this.state.price,
      recipient_id: this.state.expenseFop,
    };

    let validation = fieldEmpty(dataForm);

    if (!validation.isValidation) {
      this.setState({
        validation: validation.validation
      });
      return;
    }

    this.props.addExpenses(dataForm).then((response) => {
      if (response.status == 200) {
        this.props.handleClose();
        this.props.reRender();
      }
    });
  }


  handlerEditForm = () => {

    let dataForm = {
      expenses_type_id: this.state.idExpenses,
      name: this.state.name,
      date: this.state.date,
      price: this.state.price,
      recipient_id: this.state.expenseFop,
    };

    let validation = fieldEmpty(dataForm);

    if (!validation.isValidation) {
      this.setState({
        validation: validation.validation
      });
      return;
    }

    this.props.editExpenses(dataForm, this.state.id).then((response) => {
      if (response.status == 200) {
        this.props.handleClose();
        this.props.reRender();
      }
    });
  }

  handleChangeFop = (event) => {
    // console.log(event.target.value);
    this.setState({expenseFop: event.target.value})
  }

  render() {


    return (


      <div className="modal-popup__window container">
        <div className="modal-popup__header">
                <span>
                  {this.props.isEdit ? <FormattedMessage id="app.edit"/> : <FormattedMessage id="app.addExpenses"/>}
                </span>
        </div>
        <div className="modal-popup__body">
          <div className="waybills-popup__inner expenses-popup">
            {this.props.isEdit ?
              <React.Fragment>
                <div className="row">
                  <div className="col-md-12">
                    {this.state.recipient.length > 0 ?
                      <RadioGroup className={'radio-group'} name="gender1" defaultValue={'' + this.state.expenseFop}
                                  onChange={this.handleChangeFop}>
                        <FormControlLabel value={'1'} control={<Radio/>}
                                          label={'Наличные'}/>
                        {
                          this.state.recipient.map((item, index) => {
                            return (
                              <FormControlLabel value={String(item.id)} key={index} control={<Radio/>}
                                                label={item.name}/>
                            )
                          })
                        }
                      </RadioGroup>

                      :
                      null
                    }
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">

                    <div className="row">
                      <div className={'col-4'}>

                        {this.props.expensesType.length > 0 ?
                          <Autocomplete
                            className="filter-client-select"
                            options={this.props.expensesType}
                            getOptionLabel={(option) => option.name}
                            value={this.state.expense}
                            onChange={(event, newValue) => {
                              this.handleChangeExpensesType(newValue, event)
                            }}
                            // changeHandler={that.changeHandler}
                            renderInput={(params) => <TextField {...params}
                                                                label={<FormattedMessage id="app.name"/>}
                                                                size={'small'}
                                                                className={"task-create__name-input"}
                                                                error={this.state.validation.name}
                            />}
                          />

                          :
                          null
                        }

                      </div>


                      <div className={'col-4'}>
                        <TextField
                          className={"task-create__name-input"}
                          type="date"
                          value={this.state.date}
                          onChange={(event) => this.setState({date: event.target.value})}
                          error={this.state.validation.date}
                          ref={'masterDate'}
                          // defaultValue="2017-05-24T10:30"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                      </div>

                      <div className={'col-4'}>
                        <TextField className={"task-create__name-input"}
                          // value={this.props.master.lastName}
                                   value={this.state.price}
                                   inputProps={{'data-name': 'price'}}
                                   onChange={(event) => this.setState({price: event.target.value})}
                                   error={this.state.validation.price}
                                   ref={'masterPrice'}
                                   label={<FormattedMessage id="app.price"/>}/>

                      </div>

                    </div>

                  </div>

                </div>


              </React.Fragment>

              :

              <React.Fragment>
                <div className="row">
                  <div className="col-md-12">
                    <RadioGroup className={'radio-group'} name="gender1" defaultValue={String(this.state.expenseFop)}
                                onChange={this.handleChangeFop}>
                      <FormControlLabel value={'1'} control={<Radio/>}
                                        label={'Наличные'}/>
                      {
                        this.state.recipient.map((item, index) => {
                          return (
                            <FormControlLabel value={String(item.id)} key={index} control={<Radio/>} label={item.name}/>
                          )
                        })
                      }
                    </RadioGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">

                    <div className="row">
                      <div className={'col-4'}>
                        <Autocomplete
                          className="filter-client-select"
                          options={this.props.expensesType}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            this.handleChangeExpensesType(newValue, event)
                          }}
                          // changeHandler={that.changeHandler}
                          renderInput={(params) => <TextField {...params}
                                                              label={<FormattedMessage id="app.name"/>}
                                                              size={'small'}
                                                              className={"task-create__name-input"}
                                                              inputProps={{
                                                                ...params.inputProps,
                                                                readOnly: Boolean(true),
                                                              }}
                                                              error={this.state.validation.name}
                          />}
                        />

                      </div>

                      <div className={'col-4'}>
                        <TextField
                          className={"task-create__name-input"}
                          type="date"
                          onChange={(event) => this.setState({date: event.target.value})}
                          error={this.state.validation.date}
                          value={this.state.date}
                          // defaultValue="2017-05-24T10:30"
                          InputLabelProps={{
                            shrink: true,
                            'data-name': 'date'
                          }}
                        />

                      </div>

                      <div className={'col-4'}>
                        <TextField className={"task-create__name-input"}
                                   value={this.state.price}
                                   inputProps={{'data-name': 'price'}}
                                   onChange={(event) => this.setState({price: event.target.value})}
                                   error={this.state.validation.price}
                                   label={<FormattedMessage id="app.price"/>}/>

                      </div>

                    </div>
                  </div>

                </div>


              </React.Fragment>
            }


          </div>

          <div className="modal-popup__footer">
            <div className="modal-popup__btn">
              <Button variant="contained" color="secondary" className={"modal-popup__btn-i"} onClick={this.handleClose}>
                <FormattedMessage id="app.close"/>
              </Button>

              {this.props.isEdit ?
                <Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                        onClick={this.handlerEditForm}>
                  <FormattedMessage id="app.change"/>
                </Button>

                :

                < Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                         onClick={this.handlerSaveForm}>
                  <FormattedMessage id="app.save"/>
                </Button>
              }
            </div>
          </div>

        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    expenses: state.settings.expenses,
    expensesType: state.settings.expenses,
  }
}

function mapDispathToProps(dispath) {
  return {
    addExpenses: (data) => dispath(addExpenses(data)),
    fetchRecipient: () => dispath(fetchRecipient()),
    fetchExpensesType: (data) => dispath(fetchExpensesType(data)),
    editExpenses: (data, id) => dispath(editExpenses(data, id))
  }
}


export default connect(mapStateToProps, mapDispathToProps)(AddExpensesPopup);

