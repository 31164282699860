import {
  FETCH_WORKERS_STATISTIC_START,
  FETCH_WORKERS_STATISTIC_SUCCESS,
  FETCH_WORKERS_STATISTIC_ERROR,
  FETCH_WORK_DAYS_SUCCESS,
  FETCH_WORK_DAYS_ERROR,
  FETCH_WORK_TODAY_ERROR,
  FETCH_WORK_TODAY_SUCCESS,
  FETCH_MASTERS_SALARY_SUCCESS,
  FETCH_MASTERS_SALARY_ERROR,
  FETCH_MASTERS_SALARY_INFO_SUCCESS, FETCH_MASTERS_SALARY_INFO_ERROR, FETCH_WORK_DAYS_START
} from "./actionTypes";
import {API} from "../../axios/axiosMainUrl";



export function fetchWorkersStatistic(data = '') {
  return async dispatch => {
    dispatch(fetchWorkersStatisticStart())
    try {
      const token = localStorage.getItem('token');

      const response = await API.post(`work-order/services${data}`, null, {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchWorkersStatisticSuccess(response.data))
    } catch (e) {
      console.error(fetchWorkersStatisticError(e));
    }
  }
}

export function fetchWorkersStatisticStart() {
  return {
    type: FETCH_WORKERS_STATISTIC_START
  }
}

export function fetchWorkersStatisticSuccess(workersStatistic) {
  return {
    type: FETCH_WORKERS_STATISTIC_SUCCESS,
    workersStatistic
  }
}

export function fetchWorkersStatisticError(e) {
  return {
    type: FETCH_WORKERS_STATISTIC_ERROR,
    error: e
  }
}

export function fetchWorkDays(data = '') {
  return async dispatch => {
    dispatch(fetchWorkDaysStart())
    try {
      const token = localStorage.getItem('token');

      const response = await API.get(`work-day/index${data}`, {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchWorkDaysSuccess(response.data))
    } catch (e) {
      console.error(fetchWorkDaysError(e));
    }
  }
}


export function fetchWorkDaysStart() {
  return {
    type: FETCH_WORK_DAYS_START
  }
}

export function fetchWorkDaysSuccess(workDays) {
  return {
    type: FETCH_WORK_DAYS_SUCCESS,
    workDays
  }
}

export function fetchWorkDaysError(e) {
  return {
    type: FETCH_WORK_DAYS_ERROR,
    error: e
  }
}



export function fetchWorkToday() {
  return async dispatch => {
    dispatch(fetchWorkersStatisticStart())
    try {
      const token = localStorage.getItem('token');

      const response = await API.get(`work-day/close-day`, {headers: { Authorization: `Bearer ${token}` }})

      dispatch(fetchWorkTodaySuccess(response.data))
      return response;
    } catch (e) {
      console.error(fetchWorkTodayError(e));
    }
  }
}


export function fetchWorkTodaySuccess(workingDay) {
  return {
    type: FETCH_WORK_TODAY_SUCCESS,
    workingDay
  }
}

export function fetchWorkTodayError(e) {
  // console.error(e);
  return {
    type: FETCH_WORK_TODAY_ERROR,
    error: e
  }
}

export function reOpenDay() {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');

      const response = await API.post(`work-day/reopen-day`, null, {headers: { Authorization: `Bearer ${token}` }})

      // if(response.data.error === "NO WORKING DAY"){
      //
      // }
      // dispatch(fetchWorkTodaySuccess(response.data))
      return response;
    } catch (e) {
      console.error(fetchWorkTodayError(e));
    }
  }
}

export function nextOpenDay() {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');

      const response = await API.post(`work-day/open-day`, null, {headers: { Authorization: `Bearer ${token}` }})

      // if(response.data.error === "NO WORKING DAY"){
      //
      // }
      // dispatch(fetchWorkTodaySuccess(response.data))
      return response;
    } catch (e) {
      return e.response.data;
      console.error(fetchWorkTodayError(e));
    }
  }
}


export function currentDay() {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');

      const response = await API.get(`work-day/previous`, {headers: { Authorization: `Bearer ${token}` }})

      return response;
    } catch (e) {
      return e.response.data;
      console.error(fetchWorkTodayError(e));
    }
  }
}

export function closeDay(data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');

      const response = await API.post(`work-day/close-day`, data, {headers: { Authorization: `Bearer ${token}` }})

      // if(response.data.error === "NO WORKING DAY"){
      //
      // }
      dispatch(fetchWorkTodaySuccess(response.data))
      // return response;
    } catch (e) {
      console.error(fetchWorkTodayError(e));
    }
  }
}

export function getSalary() {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');

      const response = await API.get(`salary/index-working-day`, {headers: { Authorization: `Bearer ${token}` }})
      // console.log(response);
      // return response;
      // dispatch(fetchWorkTodaySuccess(response.data))
    } catch (e) {
      console.error(fetchWorkTodayError(e));
    }
  }
}

export function getMastersSalary() {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');

      const response = await API.get(`salary/index-salary`, {headers: { Authorization: `Bearer ${token}` }})
      // console.log(response);
      // return response;
      dispatch(fetchMastersSalarySuccess(response.data))
    } catch (e) {
      console.error(fetchMastersSalaryError(e));
    }
  }
}


export function fetchMastersSalarySuccess(mastersSalary) {
  return {
    type: FETCH_MASTERS_SALARY_INFO_SUCCESS,
    mastersSalary
  }
}

export function fetchMastersSalaryError(e) {
  return {
    type: FETCH_MASTERS_SALARY_INFO_ERROR,
    error: e
  }
}


export function getMastersSalaryErn() {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');

      const response = await API.get(`salary/master-earning`, {headers: { Authorization: `Bearer ${token}` }})
      // console.log(response);
      // return response;
      dispatch(fetchMastersSalaryErnSuccess(response.data))
    } catch (e) {
      console.error(fetchMastersSalaryErnError(e));
    }
  }
}

export function fetchMastersSalaryErnSuccess(paymentsSalary) {
  return {
    type: FETCH_MASTERS_SALARY_SUCCESS,
    paymentsSalary
  }
}

export function fetchMastersSalaryErnError(e) {
  return {
    type: FETCH_MASTERS_SALARY_ERROR,
    error: e
  }
}


export function getPaySalary(data) {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');

      const response = await API.post(`salary/master-salary`, data, {headers: { Authorization: `Bearer ${token}` }})
      // console.log(response);
      return response;
      // dispatch(fetchMastersSalaryErnSuccess(response.data))
    } catch (e) {
      console.error(fetchMastersSalaryErnError(e));
    }
  }
}
