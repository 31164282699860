import React, {Component} from 'react'
import './AddManagerPopup.css'

import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {createManager, editManager} from "../../../store/action/settings";
import {fieldEmail, fieldEmpty, fieldPassword} from "../../../services/validationService";
import InputMask from 'react-input-mask';



class AddManagerPopup extends Component {

  state = {
    name: '',
    last_name : '',
    phone : '',
    role: 'MANAGER',
    email : '',
    state: 'ACTIVE',
    password : '',

    validation: {
      name: false,
      last_name : false,
      phone : false,
      email : false,
      password : false,
    }

  }


  handleClose = () => {
    this.props.handleClose();
  }

  handlerSaveForm =() =>{
    let dataForm = {
      name: this.state.name,
      last_name : this.state.last_name,
      phone : this.state.phone,
      role : this.state.role,
      email : this.state.email,
      state : this.state.state,
      password : this.state.password,
    };

    let validation = fieldEmpty(dataForm);
    let validEmail = fieldEmail(dataForm.email);
    let validPassword = fieldPassword(dataForm.password);


    if (!validation.isValidation || !validEmail || !validPassword) {

      if(!validEmail){
        validation.validation.email = true;
      }

      if(!validPassword){
        validation.validation.password = true;
      }

      this.setState({
        validation: validation.validation
      });
      return;
    }


    this.props.createManager(dataForm).then((response) => {
      // console.log(response);
      if(response.status == 200){
        this.props.handleClose();
        this.props.getManagers();
      }
      if(response.errors){
        this.setState({validError: true})
      }
    });
  }

  handlerEditForm =() =>{

    let dataForm = {
      name: this.refs.managerName.getElementsByTagName('input')[0].value,
      last_name : this.refs.managerLastName.getElementsByTagName('input')[0].value,
      phone : this.refs.managerPhone.value,
      role: 'MANAGER',
      email : this.refs.managerEmail.getElementsByTagName('input')[0].value,
      state: 'ACTIVE',
      // password : this.refs.managerPass.getElementsByTagName('input')[0].value,
    }


    let validation = fieldEmpty(dataForm);
    let validEmail = fieldEmail(dataForm.email);
    // let validPassword = fieldPassword(dataForm.password);


    if (!validation.isValidation || !validEmail ) {

      if(!validEmail){
        validation.validation.email = true;
      }

      // if(!validPassword){
      //   validation.validation.password = true;
      // }

      this.setState({
        validation: validation.validation
      });
      return;
    }

    if (this.refs.managerPass.getElementsByTagName('input')[0].value !== '') {
      dataForm.password =  this.refs.managerPass.getElementsByTagName('input')[0].value;
    }

    this.props.editManager(dataForm, this.props.rowId).then((response) => {
      if(response.status == 200){
        this.props.handleClose();
        this.props.getManagers();
      }
      if(response.errors){
        this.setState({validError: true})
      }
    });
  }


  render() {

    return (

      <div className="modal-popup__window container">
        <div className="modal-popup__header">
          <span>
            {this.props.isEdit ? <FormattedMessage id="app.edit"/> : <FormattedMessage id="app.addManagers"/>}
          </span>
        </div>
        <div className="modal-popup__body">
          <div className="waybills-popup__inner">

            {this.props.isEdit ?
              <React.Fragment>

                {this.props.managers.map((manager, index) => {
                  if(manager.id === this.props.rowId){
                    return (
                      <div className="row" key={index} >
                        <div className="col-12">

                          <div className="row">
                            <div className={'col-12'}>
                              <TextField className={"task-create__name-input"}
                                         defaultValue={manager.name}
                                         error={this.state.validation.name}
                                         inputProps={{'data-name': 'name'}}
                                         onChange={(event) => this.setState({name: event.target.value})}
                                         ref={'managerName'}
                                         label={<FormattedMessage id="app.name"/>}/>
                            </div>

                            <div className={'col-12'}>
                              <TextField className={"task-create__name-input"}
                                         defaultValue={manager.last_name}
                                         error={this.state.validation.last_name}
                                         inputProps={{'data-name': 'lastName'}}
                                         onChange={(event) => this.setState({last_name: event.target.value})}
                                         ref={'managerLastName'}
                                         label={<FormattedMessage id="app.lastName"/>}/>

                            </div>

                            <div className={'col-12'}>
                              {/*<TextField className={"task-create__name-input"} id="standard-basic"*/}
                                         {/*defaultValue={manager.phone}*/}
                                         {/*inputProps={{'data-name': 'phone'}}*/}
                                         {/*onChange={(event) => this.setState({phone: event.target.value})}*/}
                                         {/*ref={'managerPhone'}*/}
                                         {/*label={<FormattedMessage id="app.phone"/>}/>*/}

                              <InputMask
                                mask="+380(99)9999999"
                                disabled={false}
                                maskChar="_"
                                defaultValue={manager.phone}
                                onChange={(event) => this.setState({phone: event.target.value})}
                                inputProps={{'data-name': 'phone'}}
                                ref={'managerPhone'}
                              >
                                {() => <TextField
                                  className={"task-create__name-input"}
                                  label={<FormattedMessage id="app.phone"/>}
                                  error={this.state.validation.phone}/>
                                }
                              </InputMask>
                            </div>

                            <div className={'col-12'}>
                              <TextField className={"task-create__name-input"}
                                         defaultValue={manager.email}
                                         error={this.state.validation.email}
                                         inputProps={{'data-name': 'emailAddress'}}
                                         onChange={(event) => this.setState({email: event.target.value})}
                                         ref={'managerEmail'}
                                         label={<FormattedMessage id="app.emailAddress"/>}/>

                            </div>

                            <div className={'col-12'}>
                              <TextField className={"task-create__name-input"}
                                         inputProps={{'data-name': 'password'}}
                                         error={this.state.validation.password}
                                         onChange={(event) => this.setState({password: event.target.value})}
                                         type="password"
                                         ref={'managerPass'}
                                         label={<FormattedMessage id="app.password"/>}/>

                            </div>
                          </div>
                        </div>

                      </div>
                    )
                  }

                })}


              </React.Fragment>

              :

              <React.Fragment>
                <div className="row">
                  <div className="col-12">

                    <div className="row">
                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"} id="standard-basic"
                                   value={this.state.name}
                                   error={this.state.validation.name}
                                   inputProps={{'data-name': 'name'}}
                                   onChange={(event) => this.setState({name: event.target.value})}
                                   label={<FormattedMessage id="app.name"/>}/>
                      </div>

                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"} id="standard-basic"
                                   value={this.state.last_name}
                                   error={this.state.validation.last_name}
                                   inputProps={{'data-name': 'lastName'}}
                                   onChange={(event) => this.setState({last_name: event.target.value})}
                                   label={<FormattedMessage id="app.lastName"/>}/>

                      </div>

                      <div className={'col-12'}>
                        <InputMask
                          mask="+380(99)9999999"
                          disabled={false}
                          maskChar="_"
                          value={this.state.phone}
                          onChange={(event) => this.setState({phone: event.target.value})}
                          inputProps={{'data-name': 'phone'}}
                        >
                          {() => <TextField
                            className={"task-create__name-input"}
                            label={<FormattedMessage id="app.phone"/>}
                            error={this.state.validation.phone}/>
                          }
                        </InputMask>

                      </div>

                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"} id="standard-basic"
                                   value={this.state.email}
                                   error={this.state.validation.email}
                                   inputProps={{'data-name': 'emailAddress'}}
                                   onChange={(event) => this.setState({email: event.target.value})}
                                   label={<FormattedMessage id="app.emailAddress"/>}/>

                      </div>

                      <div className={'col-12'}>
                        <TextField className={"task-create__name-input"} id="standard-basic"
                                   value={this.state.password}
                                   error={this.state.validation.password}
                                   inputProps={{'data-name': 'password'}}
                                   onChange={(event) => this.setState({password: event.target.value})}
                                   type="password"
                                   label={<FormattedMessage id="app.password"/>}/>

                      </div>
                    </div>
                  </div>

                </div>

              </React.Fragment>
            }

          </div>

          <div className="modal-popup__footer">
            <div className="modal-popup__btn">
              <Button variant="contained" color="secondary" className={"modal-popup__btn-i"} onClick={this.handleClose}>
                <FormattedMessage id="app.close"/>
              </Button>

              {this.props.isEdit ?
                <Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                        onClick={this.handlerEditForm}>
                  <FormattedMessage id="app.change"/>
                </Button>

                :

                < Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                         onClick={this.handlerSaveForm}>
                  <FormattedMessage id="app.save"/>
                </Button>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    managers: state.settings.managers,
  }
}

function mapDispathToProps(dispath) {
  return {
    createManager: (data) => dispath(createManager(data)),
    editManager: (data, id) => dispath(editManager(data, id))
  }
}


export default connect(mapStateToProps, mapDispathToProps)(AddManagerPopup);
