import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import './WaybillsPopup.css'

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import OutsideAlerter from "../../../hoc/OutsideCliker/OutsideCliker";
import {FormattedMessage, injectIntl} from "react-intl";
import {
  createOutfits, createOutfitsParts, createOutfitsService, deleteOutfits, deleteOutfitsParts, deleteOutfitsService,
  fetchOutfits, fetchOutfitsCashPDF, fetchOutfitsPDF, inDraftOutfits,
  updateOutfits,
  updateOutfitsParts,
  updateOutfitsService
} from "../../../store/action/outfits";
import connect from "react-redux/es/connect/connect";
import {fetchClients} from "../../../store/action/clients";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {fetchMasters, fetchService, fetchWorkers} from "../../../store/action/settings";
import {fetchProducts, fetchProductsInStock} from "../../../store/action/products";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {fetchRecipient} from "../../../store/action/bills";


class WaybillsPopup extends Component {

  state = {
    client: 1,
    create: false,
    work_order_id: -1,
    open: false,
    noOrder: false,
    max_data: false,

    productStockCount: false,

    expenseFop: '2',
    recipient: [],

    data: {
      first_name: '',
      last_name: '',
      phone: '',
      address: '',
      brand: '',
      model: '',
      year: '',
      registered_id: '',
      car_body: '',
      engine: '',
      chasse: '',
      payment_type: ''
    },


    dataUpdate: {
      user_id: '',
      client_id: 1,
      car_id: 1,
      data_start: '',
      data_end: '',
      machine_mileage: '',
      description: '',
      master: '',
    },

    fieldsWorkAndService: [
      {
        data: '',
        work_order_service_id: null,
        service_id: null,
        workName: '',
        workData: '',
        amount: '',
        price: '',
        sum: ''
      }
    ],

    fieldsUsedParts: [
      {
        work_order_part_id: null,
        article_id: null,
        sparePartsName: '',
        amount: '',
        price: '',
        sum: '',
        article: '',
      }
    ],

    validation: {
      data_start: false,
      user_id: false,
      data_end: false,
      first_name: false,
      table: false,
    }

  }

  componentDidMount() {
    this.props.fetchAllClients();
    this.props.fetchService();
    this.props.fetchMasters();

    this.props.fetchRecipient().then((response) => {
      this.setState({
        recipient: response.data
      })
    });

    let that = this;

    if (!this.props.isEdit) {
      this.props.fetchProductsInStock();
      this.props.createOutfits().then((response) => {
        if (response.data.hasOwnProperty('error')) {
          this.setState({noOrder: true})
          return;
        }

        that.setState({work_order_id: response.data.id});

        let patrs = that.state.fieldsUsedParts;
        patrs[0].work_order_part_id = response.data.parts[0].id;

        that.setState({fieldsUsedParts: patrs});

        let services = that.state.fieldsWorkAndService;
        services[0].work_order_service_id = response.data.services[0].id;

        that.setState({fieldsWorkAndService: services});
      })
    } else {
      this.props.fetchProducts().then(() => {
        this.setState({
          create: true
        });

        let editService = this.props.outfits.data[this.props.index].services;
        let editParts = this.props.outfits.data[this.props.index].parts;

        let dataUpdate = {
          data_start: this.props.outfits.data[this.props.index].data_start,
          data_end: this.props.outfits.data[this.props.index].data_end,
          user_id: this.props.outfits.data[this.props.index].user_id,
          client_id: this.props.outfits.data[this.props.index].client_id,
          car_id: this.props.outfits.data[this.props.index].car_id,
          machine_mileage: this.props.outfits.data[this.props.index].machine_mileage,
          description: this.props.outfits.data[this.props.index].description,
          master: this.props.outfits.data[this.props.index].master,
          recipient_id: this.props.outfits.data[this.props.index].recipient_id,
        }

        let expenseFop = this.props.outfits.data[this.props.index].recipient_id !== null ? this.props.outfits.data[this.props.index].recipient_id : '2';

        let data = this.state.data;
        data.first_name = this.props.outfits.data[this.props.index].client.first_name;
        data.last_name = this.props.outfits.data[this.props.index].client.last_name;
        data.phone = this.props.outfits.data[this.props.index].client.phone;
        data.address = this.props.outfits.data[this.props.index].client.address;
        data.brand = this.props.outfits.data[this.props.index].client.car.brand;
        data.model = this.props.outfits.data[this.props.index].client.car.model;
        data.year = this.props.outfits.data[this.props.index].client.car.year;
        data.registered_id = this.props.outfits.data[this.props.index].client.car.registered_id;
        data.car_body = this.props.outfits.data[this.props.index].client.car.car_body;
        data.engine = this.props.outfits.data[this.props.index].client.car.engine;
        data.chasse = this.props.outfits.data[this.props.index].client.car.chasse;
        data.payment_type = this.props.outfits.data[this.props.index].client.payment_type;


        let editServiceItem = [];
        editService.map((item) => {
          if (item.service_id !== null) {
            editServiceItem.push({
              work_order_service_id: item.id,
              service_id: item.service.id,
              workName: item.service.name,
              workData: item.service,
              amount: parseInt(item.amount),
              price: parseFloat(item.price).toFixed(2),
              sum: parseFloat(parseFloat(item.amount) * parseFloat(item.price)).toFixed(2)
            });
          }
        });
        // console.log(123);
        // console.log(editServiceItem);

        let editPartsItem = [];
        editParts.map((item) => {
          if (item.article_id !== null) {
            editPartsItem.push({
              work_order_part_id: item.id,
              article_id: item.article.id,
              sparePartsName: item.article.name,
              amount: parseInt(item.amount),
              price: parseFloat(item.price).toFixed(2),
              sum: parseFloat(parseFloat(item.amount) * parseFloat(item.price)).toFixed(2),
              article: item.article
            });
          }
        });


        this.setState({
          fieldsWorkAndService: editServiceItem,
          fieldsUsedParts: editPartsItem,
          work_order_id: this.props.outfits.data[this.props.index].id,
          dataUpdate: dataUpdate,
          expenseFop: expenseFop,
          data: data
        });

      });



    }

    let today = new Date();
    let day = new Date().getDate() < 10 ? '0' + parseInt(new Date().getDate()) : new Date().getDate();
    let dayMonth = new Date().getMonth() + 1 < 10 ? '0' + parseInt(new Date().getMonth() + 1) : new Date().getMonth() + 1;
    let dayYear = new Date().getFullYear();
    // today = dayYear + '-' + dayMonth + '-' + day;
    today = today.getFullYear() + '-' + dayMonth + '-' + day + 'T' + today.getHours() + ":" + today.getMinutes();

    let newVal = this.state.dataUpdate;
    newVal.data_start = today;
    this.setState({max_data: today});
  }

  componentWillUnmount() {
    if (!this.state.create) {
      this.props.deleteOutfits(this.state.work_order_id);
      this.props.handleClose();
      setTimeout(() => {
        this.props.reRender();
      }, 500)
    }
  }

  handleChangeValueWorkAndService = (event) => {

    let index = event.target.dataset.key;
    let newVal = this.state.fieldsWorkAndService;
    let field = event.target.dataset.field;

    newVal[index][field] = event.target.value;
    // if (newVal[index].amount.length === 0) newVal[index].amount = 1;
    // newVal[index].sum = parseInt(newVal[index].amount) * parseFloat(newVal[index].price);

    this.setState({
      fieldsWorkAndService: newVal
    });

  }

  handleBlurValueWorkAndService = (event) => {

    let index = event.target.dataset.key;
    let newVal = this.state.fieldsWorkAndService;
    let field = event.target.dataset.field;

    newVal[index][field] = event.target.value;
    if (newVal[index].amount.length === 0) newVal[index].amount = 1;
    newVal[index].sum = parseFloat(parseInt(newVal[index].amount) * parseFloat(newVal[index].price)).toFixed(2);

    this.setState({
      fieldsWorkAndService: newVal
    });

    this.props.updateOutfitsService(this.state.work_order_id, newVal[index].work_order_service_id, newVal[index]).then((response) => {
      // console.log(response);
    });
  }

  addFieldWorkAndServiceHandler = () => {
    let fieldsWorkAndService = {
      work_order_service_id: null,
      service_id: null,
      workName: '',
      amount: '',
      price: '',
      sum: ''
    }

    this.props.createOutfitsService(this.state.work_order_id).then((response) => {
      fieldsWorkAndService.work_order_service_id = response.data.id;
      fieldsWorkAndService.service_id = response.data.id;
    });


    let oldfieldsWorkAndService = this.state.fieldsWorkAndService;
    oldfieldsWorkAndService.push(fieldsWorkAndService);

    this.setState({
      fieldsWorkAndService: oldfieldsWorkAndService
    });
  }

  removeFieldWorkAndServiceHandler = (index) => {


    let oldfieldsWorkAndService = this.state.fieldsWorkAndService;


    this.props.deleteOutfitsService(this.state.work_order_id, oldfieldsWorkAndService[index].work_order_service_id).then((response) => {
      // console.log(response);
    });

    oldfieldsWorkAndService.splice(index, 1);

    this.setState({
      fieldsWorkAndService: oldfieldsWorkAndService
    });
  }

  handleChangeValueUsedParts = (event) => {

    let index = event.target.dataset.key;
    let newVal = this.state.fieldsUsedParts;
    let field = event.target.dataset.field;

    newVal[index][field] = event.target.value;

    this.setState({
      fieldsUsedParts: newVal
    });

  }

  handleBlurValueUsedParts = (event) => {

    let index = event.target.dataset.key;
    let newVal = this.state.fieldsUsedParts;
    let field = event.target.dataset.field;

    newVal[index][field] = event.target.value;
    if (newVal[index].amount.length === 0) newVal[index].amount = 1;
    newVal[index].sum = parseFloat(parseInt(newVal[index].amount) * parseFloat(newVal[index].price)).toFixed(2);

    // console.log(newVal);
    this.setState({
      fieldsUsedParts: newVal
    });

    this.props.updateOutfitsParts(this.state.work_order_id, newVal[index].work_order_part_id, newVal[index]).then((response) => {
      // console.log(response);
      if(response.status === 500){
        this.setState({productStockCount: true})
      } else {
        this.setState({productStockCount: false})
      }
    });
  }

  addFieldUsedPartsHandler = () => {
    let fieldsUsedParts = {
      work_order_part_id: null,
      article_id: null,
      sparePartsName: '',
      amount: '',
      price: '',
      sum: ''
    }

    this.props.createOutfitsParts(this.state.work_order_id).then((response) => {
      fieldsUsedParts.work_order_part_id = response.data.id;
      fieldsUsedParts.article_id = response.data.id;
    })

    let oldfieldsUsedParts = this.state.fieldsUsedParts;
    oldfieldsUsedParts.push(fieldsUsedParts);

    this.setState({
      fieldsUsedParts: oldfieldsUsedParts
    });
  }

  removeFieldUsedPartsHandler = (index) => {
    let oldfieldsUsedParts = this.state.fieldsUsedParts;

    this.props.deleteOutfitsParts(this.state.work_order_id, oldfieldsUsedParts[index].work_order_part_id).then((response) => {
      // console.log(response);
    })

    oldfieldsUsedParts.splice(index, 1);

    this.setState({
      fieldsUsedParts: oldfieldsUsedParts
    });
  }

  handleChangeExecutor = (event) => {
    this.setState({
      client: event.target.value,
    })
  }


  handleClose = () => {
    this.setState({
      create: false
    });
    this.props.handleClose();
  }

  handleCloseOnly = () => {
    this.setState({
      create: true
    });
    this.props.handleClose();
  }

  handleCreate = () => {
    let validation = this.state.validation;
    let data_start = this.state.dataUpdate.data_start;
    let data_end = this.state.dataUpdate.data_end;
    let user_id = this.state.dataUpdate.user_id;
    let flag = false;

    validation.data_start = false;
    validation.data_end = false;

    if (data_start.length > 0 && data_end.length > 0) {
      let start = new Date(data_start);
      let end = new Date(data_end);

      if (start > end) {
        validation.data_start = true;
        validation.data_end = true;
        this.setState({validation: validation})
        flag = true;
      }
    } else {
      if (data_start.length === 0)
        validation.data_start = true;
      if (data_end.length === 0)
        validation.data_end = true;
      flag = true;
    }

    if (user_id === '') {
      flag = true;
      validation.user_id = true;
    } else {
      validation.user_id = false;
    }


    if (this.state.data.first_name.length === 0) {
      validation.first_name = true;
      flag = true;
    } else {
      validation.first_name = false;
    }

    let work = '';
    let spareParts = '';

    if(this.state.fieldsWorkAndService.length > 0){
      work = this.state.fieldsWorkAndService[0].workName;
    }
    if(this.state.fieldsUsedParts.length > 0){
      spareParts = this.state.fieldsUsedParts[0].sparePartsName;
    }
    // let work = this.state.fieldsWorkAndService[0].workName;
    // let spareParts = this.state.fieldsUsedParts[0].sparePartsName;

    if (work === '' && spareParts === '') {
      validation.table = true;
      flag = true;
    } else {
      validation.table = false;
    }

    this.setState({validation: validation})

    if (flag) {
      return;
    }

    this.setState({
      create: true
    });

    this.props.inDraftOutfits(this.state.work_order_id).then(() => {
      this.props.handleClose();
      this.props.reRender();
    });

  }


  milageChangeHandler = (event) => {

    if (this.state.work_order_id !== -1) {

      let newVal = this.state.dataUpdate;
      newVal[event.target.dataset.name] = event.target.value;
      this.setState({dataUpdate: newVal});

      // this.props.updateOutfits(this.state.work_order_id, this.state.dataUpdate).then((response) => {
      //   console.log(response);
      // })
    }

  }

  createChangeHandler = (event) => {

    if (this.state.work_order_id !== -1) {

      let newVal = this.state.dataUpdate;
      newVal[event.target.dataset.name] = event.target.value;
      this.setState({dataUpdate: newVal});

      this.props.updateOutfits(this.state.work_order_id, this.state.dataUpdate).then((response) => {
        // console.log(response);
      })
    }

  }

  handleMasterChangeInput = (event) => {
    if (event === null) return;

    let dataUpdate = this.state.dataUpdate;
    dataUpdate.user_id = event.id;

    this.setState({
      dataUpdate: dataUpdate
    });

    this.props.updateOutfits(this.state.work_order_id, this.state.dataUpdate).then((response) => {
      // console.log(response);
    })
  }

  handleAutocompleteChangeInput = (event) => {

    if (event === null) return;

    if (event.id) {

      let data = {
        first_name: event.first_name,
        last_name: event.last_name,
        phone: event.phone,
        address: event.address,
        brand: event.car.brand,
        model: event.car.model,
        year: event.car.year,
        registered_id: event.car.registered_id,
        car_body: event.car.car_body,
        engine: event.car.engine,
        chasse: event.car.chasse,
        payment_type: event.payment_type,
      }


      this.setState({
        data: data
      });


      let dataUpdate = this.state.dataUpdate;
      dataUpdate.client_id = event.id;
      dataUpdate.car_id = event.car.id;

      if(data.payment_type === 'NO_CASH'){
        dataUpdate.recipient_id = this.state.expenseFop
      } else {
        dataUpdate.recipient_id = '1'
      }

      this.setState({
        dataUpdate: dataUpdate
      });

      setTimeout(() => {
        this.props.updateOutfits(this.state.work_order_id, this.state.dataUpdate).then((response) => {
          // console.log(response);
        })
      });




    }
  }

  handleChangeWorkNameKeyUp = (event, e, index) => {
    let newField = this.state.fieldsWorkAndService;

    newField[index].workName = event;
    this.setState({fieldsWorkAndService: newField})
  }

  handleChangeWorkName = (event, e, index) => {
    let newFieldsWorkAndService = this.state.fieldsWorkAndService;
    if (event === null) return;

    newFieldsWorkAndService[index].data = event;
    newFieldsWorkAndService[index].service_id = event.id;
    newFieldsWorkAndService[index].workName = event.name;
    newFieldsWorkAndService[index].amount = 1;
    newFieldsWorkAndService[index].price = event.price;
    newFieldsWorkAndService[index].sum = 1 * parseFloat(event.price);

    this.setState({fieldsWorkAndService: newFieldsWorkAndService})

    this.props.updateOutfitsService(this.state.work_order_id, newFieldsWorkAndService[index].work_order_service_id, newFieldsWorkAndService[index]).then((response) => {
      // console.log(response.status === 200);
    });

  }

  handleChangePartsKeyUp = (event, e, index) => {
    let newField = this.state.fieldsUsedParts;

    newField[index].sparePartsName = event;
    this.setState({fieldsUsedParts: newField})
  }

  handleChangeParts = (event, e, index) => {
    let newFieldsUsedParts = this.state.fieldsUsedParts;
    if (event === null) return;

    newFieldsUsedParts[index].article_id = event.id;
    newFieldsUsedParts[index].sparePartsName = event.name;
    newFieldsUsedParts[index].amount = 1;
    newFieldsUsedParts[index].price = event.price;
    newFieldsUsedParts[index].sum = 1 * parseFloat(event.price);

    this.setState({fieldsUsedParts: newFieldsUsedParts})

    this.props.updateOutfitsParts(this.state.work_order_id, newFieldsUsedParts[index].work_order_part_id, newFieldsUsedParts[index]).then((response) => {
      // console.log(response);
      if(response.status === 500){
        this.setState({productStockCount: true})
      } else {
        this.setState({productStockCount: false})
      }
    });

  }

  handlePrintCash = () => {
    this.props.fetchOutfitsCashPDF(this.state.work_order_id).then((response) => {
      // console.log(response);
    })
  }

  handlePrint = () => {
    this.props.fetchOutfitsPDF(this.state.work_order_id).then((response) => {
      // console.log(response);
    })
  }

  handleChangeFop = (event) => {
    this.setState({expenseFop: event.target.value});

    let dataUpdate = this.state.dataUpdate;

    dataUpdate.recipient_id = event.target.value

    this.setState({
      dataUpdate: dataUpdate
    });

    setTimeout(() => {
      this.props.updateOutfits(this.state.work_order_id, this.state.dataUpdate).then((response) => {
        // console.log(response);
      })
    });
  }


  render() {
    const placeholderDesc = this.props.intl.formatMessage({
      id: 'app.description',
    });

    let that = this;

    let editElement = '';
    if (this.props.isEdit) {
      editElement = this.props.outfits.data[this.props.index];
    }

    let clients = this.props.clients;

    let serviceEdit = [];
    if (that.props.service.length > 0) {
      that.state.fieldsWorkAndService.map((i, key) => {
        that.props.service.map((item) => {
          if (item.id === i.service_id) {
            serviceEdit.push(item);
          }
        })
      })
    }
    let partsEdit = [];
    if (that.props.products.length > 0) {
      that.state.fieldsUsedParts.map((i, key) => {
        that.props.products.map((item) => {
          if (item.id === i.article_id) {
            partsEdit.push(item);
          }
        })
      })

    }


    return (

      <div className="modal-popup__window container">
        <div className="modal-popup__header">
                <span>
                  {this.props.isEdit ? <FormattedMessage id="app.waybill"/> : <FormattedMessage id="app.newOutfit"/>}
                </span>
        </div>
        <div className="modal-popup__body">
          {this.state.noOrder ?
            <React.Fragment>
              <div className={'waybills-popup__inner'}>
                <FormattedMessage id="app.workDayClose"/>
              </div>

              <div className="modal-popup__footer">
                <div className="modal-popup__btn">

                  <Button variant="contained" color="secondary" className={"modal-popup__btn-i"}
                          onClick={this.handleCloseOnly}>
                    <FormattedMessage id="app.close"/>
                  </Button>

                </div>
              </div>
            </React.Fragment>


            :
            <React.Fragment>
              <div className="waybills-popup__inner">
                {this.props.isEdit ?
                  <React.Fragment>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className={'col-md-6'}>
                            <TextField
                              // type="date"
                              type="datetime-local"
                              value={this.state.dataUpdate.data_start}
                              error={this.state.validation.data_start}
                              className={'modal-popup__input--time'}
                              InputProps={{ inputProps: {
                                // max: this.state.max_data,
                                  'data-name': 'data_start' } }}
                              label=<FormattedMessage id="app.orderDate"/>
                            onChange={(event) => {
                            this.createChangeHandler(event)
                          }}
                            />
                          </div>

                          <div className={'col-md-6'}>
                            <TextField
                              // type="date"
                              type="datetime-local"
                              value={this.state.dataUpdate.data_end}
                              error={this.state.validation.data_end}
                              InputProps={{ inputProps: {
                                // max: this.state.max_data,
                                  'data-name': 'data_end'
                              } }}
                              className={'modal-popup__input--time'}
                              label={<FormattedMessage id="app.orderExecutionDate"/>}
                              InputLabelProps={{
                                shrink: true,
                              }}

                              onChange={(event) => {
                                this.createChangeHandler(event)
                              }}
                            />
                          </div>
                        </div>

                        <div className="row mb15">
                          <div className={'col-md-6'}>
                            <Autocomplete
                              freeSolo
                              className={'task-autocomplite'}
                              options={this.props.masters}
                              value={this.state.dataUpdate.master}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => {
                                this.handleMasterChangeInput(newValue, event)
                              }}
                              renderInput={(params) => <TextField {...params}
                                                                  label={<FormattedMessage id="app.responsible"/>}
                                                                  error={this.state.validation.user_id}
                                                                  inputProps={{
                                                                    ...params.inputProps,
                                                                    'data-name': 'master'
                                                                  }}
                              />}
                            />

                          </div>

                          <div className={'col-md-6'}>
                            <Autocomplete
                              freeSolo
                              options={clients}
                              value={this.state.data}
                              getOptionLabel={(option) => option.first_name}
                              onChange={(event, newValue) => {
                                this.handleAutocompleteChangeInput(newValue, event)
                              }}
                              renderInput={(params) => <TextField {...params}
                                                                  label={<FormattedMessage id="app.clientName"/>}
                                                                  size={'small'}
                                                                  error={this.state.validation.first_name}
                                                                  inputProps={{
                                                                    ...params.inputProps,
                                                                    'data-name': 'first_name'
                                                                  }}
                              />}
                            />

                          </div>


                        </div>


                        <div className="row mb15">
                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.brand}
                                       label={<FormattedMessage id="app.carBrand"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>

                          </div>
                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.model}
                                       label={<FormattedMessage id="app.сarModel"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>

                          </div>

                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.year}
                                       label={<FormattedMessage id="app.yearOfIssue"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>
                          </div>

                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.registered_id}
                                       label={<FormattedMessage id="app.stateRegisterNumber"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>

                          </div>


                        </div>

                        {/*<div className="row mb15">*/}
                          {/**/}
                        {/*</div>*/}


                        <div className="row mb15">
                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.car_body}
                                       label={<FormattedMessage id="app.bodyType"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>
                          </div>

                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.engine}
                                       label={<FormattedMessage id="app.engine"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>

                          </div>

                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.chasse}
                                       label={<FormattedMessage id="app.shasi"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>
                          </div>
                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.phone}
                                       label={<FormattedMessage id="app.phone"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>

                          </div>
                        </div>

                        {/*<div className="row mb15">*/}
                          {/**/}

                        {/*</div>*/}
                        <div className="row mb15">


                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.address}
                                       label={<FormattedMessage id="app.address"/>}
                                       inputProps={{
                                         readOnly: Boolean(true),
                                       }}/>

                          </div>

                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"}
                                       value={this.state.dataUpdate.machine_mileage}
                                       onBlur={(event) => this.createChangeHandler(event)}
                                       onChange={(event) => this.milageChangeHandler(event)}
                                       label={<FormattedMessage id="app.machineMileage"/>}
                                       inputProps={{
                                         'data-name': "machine_mileage"
                                       }}/>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-12">
                            <textarea name="" placeholder={placeholderDesc} className={'waybills-popup__desc'}
                                      defaultValue={editElement.description} readOnly={true}>
                            </textarea>

                              </div>
                            </div>
                          </div>
                        </div>

                        {this.state.data.payment_type === 'NO_CASH' ?
                          <div className="row mb15">
                            <div className="col-md-12">
                              {this.state.recipient.length > 0 ?
                                <RadioGroup className={'radio-group'} name="gender1" defaultValue={'' + this.state.expenseFop}
                                            onChange={this.handleChangeFop}>

                                  {
                                    this.state.recipient.map((item, index) => {
                                      return (
                                        <FormControlLabel value={String(item.id)} key={index} control={<Radio/>}
                                                          label={item.name}/>
                                      )
                                    })
                                  }
                                </RadioGroup>

                                :
                                null
                              }
                            </div>

                          </div>
                          :
                          null
                        }

                      </div>

                    </div>

                    <div className="row">
                      <div className={'waybills-popup__works col-12'}>
                        <div className="waybills-popup__work-title">
                          <FormattedMessage id="app.completedWorksAndServices"/>
                          <Button variant="contained" color="primary" size="small"
                                  onClick={that.addFieldWorkAndServiceHandler}>
                            <FormattedMessage id="app.add"/>
                          </Button>
                        </div>

                        {this.state.fieldsWorkAndService.map(function (item, key) {
                          return (
                            <div className="waybills-popup__work-name row" key={key}>
                              <div className="col-md-3">

                                <Autocomplete
                                  freeSolo
                                  inputValue={that.state.fieldsWorkAndService[key].workName}
                                  options={that.props.service}
                                  value={item.workData}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event, newValue) => {
                                    that.handleChangeWorkName(newValue, event, key)
                                  }}

                                  onInputChange={(event, newValue) => {
                                    that.handleChangeWorkNameKeyUp(newValue, event, key)
                                  }}
                                  renderInput={(params) => <TextField {...params}
                                                                      label={<FormattedMessage id="app.workName"/>}
                                                                      size={'small'}
                                                                      inputProps={{
                                                                        ...params.inputProps,
                                                                        // readOnly: Boolean(true)
                                                                      }}
                                  />}
                                />


                              </div>
                              <div className="col-md-3">
                                <TextField className={"task-create__name-input"}
                                  // onKeyUp={that.handleChangeValueWorkAndService}
                                           value={that.state.fieldsWorkAndService[key].amount}
                                           inputProps={{
                                             'data-key': key, 'data-field': 'amount',
                                             //  readOnly: Boolean(true)
                                           }}
                                           onChange={that.handleChangeValueWorkAndService}
                                           onBlur={that.handleBlurValueWorkAndService}
                                           label={<FormattedMessage id="app.count"/>}
                                />
                              </div>


                              <div className="col-md-2">
                                <TextField className={"task-create__name-input"}
                                  // onKeyUp={that.handleChangeValueWorkAndService}
                                           value={that.state.fieldsWorkAndService[key].price}
                                           onChange={that.handleChangeValueWorkAndService}
                                           onBlur={that.handleBlurValueWorkAndService}
                                           inputProps={{
                                             'data-key': key, 'data-field': 'price',
                                             //  readOnly: Boolean(true)
                                           }}
                                           label={<FormattedMessage id="app.price"/>}
                                />
                              </div>
                              <div className="col-md-2">
                                <TextField className={"task-create__name-input"}
                                  // onKeyUp={that.handleChangeValueWorkAndService}
                                           value={that.state.fieldsWorkAndService[key].sum}
                                           inputProps={{
                                             'data-key': key, 'data-field': 'sum',
                                             // readOnly: Boolean(true)
                                           }}
                                           onChange={that.handleChangeValueWorkAndService}
                                           onBlur={that.handleBlurValueWorkAndService}
                                           label={<FormattedMessage id="app.amount"/>}
                                />
                              </div>
                              <div className="col-md-2 d-flex justify-content-end align-items-end">
                                <Button variant="contained" color="primary" size="small" data-key={key}
                                        onClick={() => that.removeFieldWorkAndServiceHandler(key)}>
                                  <FormattedMessage id="app.remove"/>
                                </Button>
                              </div>
                            </div>
                          )
                        })}


                      </div>
                    </div>

                    <div className="row">
                      <div className={'waybills-popup__spare-parts col-12'}>
                        <div className="waybills-popup__spare-parts-title">
                          <FormattedMessage id="app.usedSpareParts"/>
                          <Button variant="contained" color="primary" size="small"
                                  onClick={that.addFieldUsedPartsHandler}>
                            <FormattedMessage id="app.add"/>
                          </Button>
                        </div>


                        {this.state.fieldsUsedParts.map(function (item, key) {

                          return (
                            <div className="waybills-popup__spare-parts-name row" key={key}>

                              <div className="col-md-3">
                                {that.props.products.length > 0 ?
                                  <Autocomplete
                                    freeSolo
                                    inputValue={that.state.fieldsUsedParts[key].sparePartsName}
                                    options={that.props.products}
                                    value={item.article}
                                    getOptionLabel={(option) => option.name + ' ' + option.article + (option.short_supplier_name !== '' ? '_' + option.short_supplier_name : '')}
                                    onChange={(event, newValue) => {
                                      that.handleChangeParts(newValue, event, key)
                                    }}
                                    onInputChange={(event, newValue) => {
                                      that.handleChangePartsKeyUp(newValue, event, key)
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={<FormattedMessage
                                                                          id="app.sparePartsName"/>}
                                                                        size={'small'}
                                                                        inputProps={{
                                                                          ...params.inputProps,
                                                                          // readOnly: Boolean(true)
                                                                        }}
                                    />}
                                  />

                                  : null}


                              </div>

                              <div className="col-md-3">
                                <TextField className={"task-create__name-input"}
                                  // onKeyUp={that.handleChangeValueUsedParts}
                                           value={that.state.fieldsUsedParts[key].amount}
                                           onChange={that.handleChangeValueUsedParts}
                                           onBlur={that.handleBlurValueUsedParts}
                                           inputProps={{
                                             'data-key': key, 'data-field': 'amount',
                                             // readOnly: Boolean(true)
                                           }}
                                           label={<FormattedMessage id="app.count"/>}
                                />

                              </div>
                              <div className="col-md-2">
                                <TextField className={"task-create__name-input"}
                                  // onKeyUp={that.handleChangeValueUsedParts}
                                           value={that.state.fieldsUsedParts[key].price}
                                           onChange={that.handleChangeValueUsedParts}
                                           onBlur={that.handleBlurValueUsedParts}
                                           inputProps={{
                                             'data-key': key, 'data-field': 'price',
                                             // readOnly: Boolean(true)
                                           }}
                                           label={<FormattedMessage id="app.price"/>}
                                />
                              </div>
                              <div className="col-md-2">
                                <TextField className={"task-create__name-input"}
                                  // onKeyUp={that.handleChangeValueUsedParts}
                                           value={that.state.fieldsUsedParts[key].sum}
                                           onChange={that.handleChangeValueUsedParts}
                                           onBlur={that.handleBlurValueUsedParts}
                                           inputProps={{
                                             'data-key': key, 'data-field': 'sum',
                                             // readOnly: Boolean(true)
                                           }}
                                           label={<FormattedMessage id="app.amount"/>}
                                />
                              </div>
                              <div className="col-md-2 d-flex justify-content-end align-items-end">
                                <Button variant="contained" color="primary" size="small" data-key={key}
                                        onClick={() => that.removeFieldUsedPartsHandler(key)}>
                                  <FormattedMessage id="app.remove"/>
                                </Button>
                              </div>
                            </div>

                          )
                        })}

                      </div>
                    </div>

                  </React.Fragment>

                  :

                  <React.Fragment>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className={'col-md-6'}>
                            <TextField
                              type="datetime-local"
                              value={this.state.dataUpdate.data_start}
                              error={this.state.validation.data_start}
                              InputProps={{ inputProps: {
                                // max: this.state.max_data,
                                  'data-name': 'data_start' } }}
                              className={'modal-popup__input--time'}
                              label=<FormattedMessage id="app.orderDate"/>

                            onChange={(event) => {
                            this.createChangeHandler(event)
                          }}
                            InputLabelProps={{
                            shrink: true,
                          }}
                            />
                          </div>

                          <div className={'col-md-6'}>
                            <TextField
                              type="datetime-local"
                              ref={'orderExecutionDate'}
                              InputProps={{ inputProps: {
                                // max: this.state.max_data,
                                  'data-name': 'data_end' } }}
                              defaultValue=""
                              error={this.state.validation.data_end}
                              className={'modal-popup__input--time'}
                              label=<FormattedMessage id="app.orderExecutionDate"/>
                            onChange={(event) => {
                            this.createChangeHandler(event)
                          }}
                            InputLabelProps={{
                            shrink: true,
                          }}
                            />
                          </div>
                        </div>

                        <div className="row mb15">
                          <div className={'col-md-6'}>
                            <Autocomplete
                              className={'task-autocomplite'}
                              options={this.props.masters}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => {
                                this.handleMasterChangeInput(newValue, event)
                              }}
                              renderInput={(params) => <TextField {...params}
                                                                  label={<FormattedMessage id="app.responsible"/>}
                                                                  error={this.state.validation.user_id}
                                                                  inputProps={{
                                                                    ...params.inputProps,
                                                                    'data-name': 'master'
                                                                  }}
                              />}
                            />

                          </div>

                          <div className={'col-md-6'}>
                            <Autocomplete
                              options={clients}
                              getOptionLabel={(option) => option.first_name}
                              onChange={(event, newValue) => {
                                this.handleAutocompleteChangeInput(newValue, event)
                              }}
                              renderInput={(params) => <TextField {...params}
                                                                  label={<FormattedMessage id="app.clientName"/>}
                                                                  size={'small'}
                                                                  error={this.state.validation.first_name}
                                                                  inputProps={{
                                                                    ...params.inputProps,
                                                                    'data-name': 'first_name'
                                                                  }}
                              />}
                            />

                          </div>
                        </div>
                        {/*<div className="row mb15">*/}
                          {/**/}


                        {/*</div>*/}
                        <div className="row mb15">
                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.brand}
                                       label={<FormattedMessage id="app.carBrand"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>

                          </div>
                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.model}
                                       label={<FormattedMessage id="app.сarModel"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>

                          </div>


                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.year}
                                       label={<FormattedMessage id="app.yearOfIssue"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>
                          </div>

                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.registered_id}
                                       label={<FormattedMessage id="app.stateRegisterNumber"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>

                          </div>

                        </div>

                        {/*<div className="row mb15">*/}
                          {/**/}
                        {/*</div>*/}


                        <div className="row mb15">
                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.car_body}
                                       label={<FormattedMessage id="app.bodyType"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>
                          </div>

                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.engine}
                                       label={<FormattedMessage id="app.engine"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>

                          </div>

                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.chasse}
                                       label={<FormattedMessage id="app.shasi"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>
                          </div>

                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.address}
                                       label={<FormattedMessage id="app.address"/>}
                                       inputProps={{
                                         readOnly: Boolean(true),
                                       }}/>

                          </div>
                        </div>

                        {/*<div className="row mb15">*/}
                          {/**/}

                        {/*</div>*/}
                        <div className="row mb15">
                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.phone}
                                       label={<FormattedMessage id="app.phone"/>} inputProps={{
                              readOnly: Boolean(true),
                            }}/>

                          </div>


                          <div className={'col-md-3'}>
                            <TextField className={"task-create__name-input"} value={this.state.data.machine_mileage}
                                       onBlur={(event) => this.createChangeHandler(event)}
                                       label={<FormattedMessage id="app.machineMileage"/>}
                                       inputProps={{
                                         'data-name': "machine_mileage"
                                       }}/>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-12">
                            <textarea name=""
                                      placeholder={placeholderDesc} className={'waybills-popup__desc'}
                                      defaultValue={''}
                                      onBlur={(event) => {
                                        this.createChangeHandler(event)
                                      }}
                                      data-name="description">
                            </textarea>
                              </div>
                            </div>
                          </div>
                        </div>

                        {this.state.data.payment_type === 'NO_CASH' ?
                          <div className="row mb15">
                            <div className="col-md-12">
                              {this.state.recipient.length > 0 ?
                                <RadioGroup className={'radio-group'} name="gender1" defaultValue={'' + this.state.expenseFop}
                                            onChange={this.handleChangeFop}>

                                  {
                                    this.state.recipient.map((item, index) => {
                                      return (
                                        <FormControlLabel value={String(item.id)} key={index} control={<Radio/>}
                                                          label={item.name}/>
                                      )
                                    })
                                  }
                                </RadioGroup>

                                :
                                null
                              }
                            </div>

                          </div>
                          :
                          null
                        }


                      </div>

                    </div>

                    <div className={"row" + (this.props.loadingService ? ' loading-table' : '')}>
                      <div className={'waybills-popup__works col-12'}>
                        <div className="waybills-popup__work-title">
                          <FormattedMessage id="app.completedWorksAndServices"/>
                          <Button variant="contained" color="primary" size="small"
                                  onClick={that.addFieldWorkAndServiceHandler}>
                            <FormattedMessage id="app.add"/>
                          </Button>
                        </div>

                        {that.props.service.length > 0 ?
                          this.state.fieldsWorkAndService.map(function (item, key) {
                            return (
                              <div className="waybills-popup__work-name row" key={key}>
                                <div className="col-md-3">
                                  <Autocomplete
                                    inputValue={that.state.fieldsWorkAndService[key].workName}
                                    options={that.props.service}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                      that.handleChangeWorkName(newValue, event, key)
                                    }}

                                    onInputChange={(event, newValue) => {
                                      that.handleChangeWorkNameKeyUp(newValue, event, key)
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={<FormattedMessage id="app.workName"/>}
                                                                        size={'small'}
                                                                        inputProps={{
                                                                          ...params.inputProps,
                                                                        }}
                                    />}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <TextField className={"task-create__name-input"}
                                    // onKeyUp={that.handleChangeValueWorkAndService}
                                             value={that.state.fieldsWorkAndService[key].amount}
                                             inputProps={{'data-key': key, 'data-field': 'amount'}}
                                             onChange={that.handleChangeValueWorkAndService}
                                             onBlur={that.handleBlurValueWorkAndService}
                                             label={<FormattedMessage id="app.count"/>}
                                  />
                                </div>


                                <div className="col-md-2">
                                  <TextField className={"task-create__name-input"}
                                    // onKeyUp={that.handleChangeValueWorkAndService}
                                             value={that.state.fieldsWorkAndService[key].price}
                                             onChange={that.handleChangeValueWorkAndService}
                                             onBlur={that.handleBlurValueWorkAndService}
                                             inputProps={{'data-key': key, 'data-field': 'price'}}
                                             label={<FormattedMessage id="app.price"/>}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <TextField className={"task-create__name-input"}
                                    // onKeyUp={that.handleChangeValueWorkAndService}
                                             value={that.state.fieldsWorkAndService[key].sum}
                                             inputProps={{'data-key': key, 'data-field': 'sum'}}
                                             onChange={that.handleChangeValueWorkAndService}
                                             onBlur={that.handleBlurValueWorkAndService}
                                             label={<FormattedMessage id="app.amount"/>}
                                  />
                                </div>
                                <div className="col-md-2 d-flex justify-content-end align-items-end">
                                  <Button variant="contained" color="primary" size="small" data-key={key}
                                          onClick={() => that.removeFieldWorkAndServiceHandler(key)}>
                                    <FormattedMessage id="app.remove"/>
                                  </Button>
                                </div>
                              </div>
                            )
                          })
                          :

                          <React.Fragment>
                            <div className="error-txt">
                              <FormattedMessage id="app.jobTypesError"/>
                            </div>
                          </React.Fragment>

                        }


                      </div>
                    </div>

                    <div className={"row" + (this.props.loadingProducts ? ' loading-table' : '')}>
                      <div className={'waybills-popup__spare-parts col-12'}>
                        <div className="waybills-popup__spare-parts-title">
                          <FormattedMessage id="app.usedSpareParts"/>
                          <Button variant="contained" color="primary" size="small"
                                  onClick={that.addFieldUsedPartsHandler}>
                            <FormattedMessage id="app.add"/>
                          </Button>
                        </div>

                        {that.props.products.length > 0 ?
                          this.state.fieldsUsedParts.map(function (item, key) {

                            return (
                              <div className="waybills-popup__spare-parts-name row" key={key}>

                                <div className="col-md-3">
                                  <Autocomplete
                                    options={that.props.products}
                                    inputValue={that.state.fieldsUsedParts[key].sparePartsName}
                                    getOptionLabel={(option) => option.name + ' ' + option.article + (option.short_supplier_name !== '' ? '_' + option.short_supplier_name : '')}
                                    onChange={(event, newValue) => {
                                      that.handleChangeParts(newValue, event, key)
                                    }}

                                    onInputChange={(event, newValue) => {
                                      that.handleChangePartsKeyUp(newValue, event, key)
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={<FormattedMessage
                                                                          id="app.sparePartsName"/>}
                                                                        size={'small'}
                                                                        inputProps={{
                                                                          ...params.inputProps,
                                                                        }}
                                    />}
                                  />

                                </div>

                                <div className="col-md-3">
                                  <TextField className={"task-create__name-input"}
                                    // onKeyUp={that.handleChangeValueUsedParts}
                                             value={that.state.fieldsUsedParts[key].amount}
                                             onChange={that.handleChangeValueUsedParts}
                                             onBlur={that.handleBlurValueUsedParts}
                                             inputProps={{'data-key': key, 'data-field': 'amount'}}
                                             label={<FormattedMessage id="app.count"/>}
                                  />

                                </div>
                                <div className="col-md-2">
                                  <TextField className={"task-create__name-input"}
                                    // onKeyUp={that.handleChangeValueUsedParts}
                                             value={that.state.fieldsUsedParts[key].price}
                                             onChange={that.handleChangeValueUsedParts}
                                             onBlur={that.handleBlurValueUsedParts}
                                             inputProps={{'data-key': key, 'data-field': 'price'}}
                                             label={<FormattedMessage id="app.price"/>}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <TextField className={"task-create__name-input"}
                                    // onKeyUp={that.handleChangeValueUsedParts}
                                             value={that.state.fieldsUsedParts[key].sum}
                                             onChange={that.handleChangeValueUsedParts}
                                             onBlur={that.handleBlurValueUsedParts}
                                             inputProps={{'data-key': key, 'data-field': 'sum'}}
                                             label={<FormattedMessage id="app.amount"/>}
                                  />
                                </div>
                                <div className="col-md-2 d-flex justify-content-end align-items-end">
                                  <Button variant="contained" color="primary" size="small" data-key={key}
                                          onClick={() => that.removeFieldUsedPartsHandler(key)}>
                                    <FormattedMessage id="app.remove"/>
                                  </Button>
                                </div>
                              </div>

                            )
                          })
                          :
                          <React.Fragment>
                            <div className="error-txt">
                              <FormattedMessage id="app.parePartsError"/>
                            </div>
                          </React.Fragment>
                        }

                      </div>
                    </div>

                  </React.Fragment>
                }


                {/*<div>*/}
                {/*{this.props.isEdit ?*/}
                {/*<TextField*/}
                {/**/}
                {/*label={<FormattedMessage id="app.sum"/>}*/}
                {/*value={this.props.dataTask.nameTask}/> :*/}

                {/*<TextField className={"task-create__name-input"} */}
                {/*label={<FormattedMessage id="app.sum"/>}/>*/}
                {/*}*/}
                {/*</div>*/}

                {this.state.validation.table ?
                  <div className={'error-txt'}>
                    Введите запчасть или услугу
                  </div>
                  :
                  null
                }

                {this.state.productStockCount ?
                  <div className={'error-txt'}>
                    Недостаточно товара на складе
                  </div>
                  :
                  null
                }

              </div>

              <div className="modal-popup__footer">
                <div className="modal-popup__btn">

                  {!this.props.isEdit ?
                    <div>
                      <Button variant="contained" color="secondary" className={"modal-popup__btn-i"}
                              onClick={this.handleClose}>
                        <FormattedMessage id="app.close"/>
                      </Button>
                      <Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                              onClick={this.handleCreate} disabled={this.state.productStockCount}>
                        <FormattedMessage id="app.save"/>
                      </Button>
                    </div>
                    :

                    <React.Fragment>

                      <React.Fragment>
                        {editElement.client.payment_type === 'CASH' ?
                          <Button variant="contained" color="secondary" className={"modal-popup__btn-i"}
                                  onClick={this.handlePrintCash}>
                            <FormattedMessage id="app.print"/>
                          </Button>
                          :
                          <Button variant="contained" color="secondary" className={"modal-popup__btn-i"}
                                  onClick={this.handlePrint}>
                            <FormattedMessage id="app.print"/>
                          </Button>
                        }
                      </React.Fragment>

                      <Button variant="contained" color="primary" className={"modal-popup__btn-i"}
                              onClick={this.handleCreate}>
                        <FormattedMessage id="app.change"/>
                      </Button>

                      <Button variant="contained" color="secondary" className={"modal-popup__btn-i"}
                              onClick={this.handleCloseOnly}>
                        <FormattedMessage id="app.close"/>
                      </Button>
                    </React.Fragment>


                  }

                </div>
              </div>
            </React.Fragment>
          }


        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    outfits: state.outfits.outfits,
    clients: state.clients.clients,
    service: state.settings.service,
    products: state.products.products,
    masters: state.settings.masters,
    loadingProducts: state.products.loading,
    loadingService: state.settings.loading,
  }

}

function mapDispathToProps(dispath) {
  return {
    // fetchOutfits: () => dispath(fetchOutfits())
    fetchAllClients: () => dispath(fetchClients()),
    fetchRecipient: () => dispath(fetchRecipient()),
    inDraftOutfits: (id) => dispath(inDraftOutfits(id)),
    createOutfits: () => dispath(createOutfits()),
    updateOutfits: (id, data) => dispath(updateOutfits(id, data)),
    fetchService: () => dispath(fetchService()),
    fetchProducts: () => dispath(fetchProducts()),
    fetchProductsInStock: () => dispath(fetchProductsInStock()),
    createOutfitsService: (idOrder) => dispath(createOutfitsService(idOrder)),
    createOutfitsParts: (idOrder) => dispath(createOutfitsParts(idOrder)),
    updateOutfitsService: (idOrder, idOrderService, data) => dispath(updateOutfitsService(idOrder, idOrderService, data)),
    updateOutfitsParts: (idOrder, idOrderPart, data) => dispath(updateOutfitsParts(idOrder, idOrderPart, data)),
    deleteOutfitsService: (idOrder, idOrderService) => dispath(deleteOutfitsService(idOrder, idOrderService)),
    deleteOutfitsParts: (idOrder, idOrderPart) => dispath(deleteOutfitsParts(idOrder, idOrderPart)),
    deleteOutfits: (id) => dispath(deleteOutfits(id)),
    fetchOutfitsCashPDF: (id) => dispath(fetchOutfitsCashPDF(id)),
    fetchOutfitsPDF: (id) => dispath(fetchOutfitsPDF(id)),
    fetchMasters: () => dispath(fetchMasters()),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(injectIntl(WaybillsPopup));
