import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import {NavLink, withRouter} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PopupWrapper from "../../hoc/PopupWrapper/PopupWrapper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import AddProductWaybillsPopup from "../../components/ModalPopups/AddProductWaybillsPopup/AddProductWaybillsPopup";
import './ReportCard.css'
import TableHeader from "../../components/Tables/TableHeader/TableHeader";

import connect from "react-redux/es/connect/connect";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import {
  closeDay,
  fetchWorkDays,
  fetchWorkersStatistic,
  fetchWorkToday, getMastersSalary, getMastersSalaryErn, getPaySalary,
  getSalary, nextOpenDay, reOpenDay
} from "../../store/action/workersStatistic";
import TableRowsWorkersStatistic from "../../components/Tables/TableRows/TableRowsWorkersStatistic";
import TableRowsWorkDays from "../../components/Tables/TableRows/TableRowsWorkDays";
import TableRowsWorkingDay from "../../components/Tables/TableRows/TableRowsWorkingDay";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableRowsPaymentsSalary from "../../components/Tables/TableRows/TableRowsPaymentsSalary";
import TableFooter from "@material-ui/core/TableFooter";
import TableRowsSalaryInfo from "../../components/Tables/TableRows/TableRowsSalaryInfo";
import QuestionPopup from "../../components/ModalPopups/QuestionPopup/QuestionPopup";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";


class ReportCard extends Component {

  state = {
    isOpen: false,
    activeTab: 0,

    perPage: 30,
    currentPage: 1,
    perPageDelivery: 30,
    currentPageDelivery: 1,
    search: '',
    dateStart: '',
    dateEnd: '',

    countMasters: null,
    salary: null,

    errPay: false,
    masterSumm: [],
    alert: false,

    workDaySalary: []
  }

  componentDidMount() {
    // this.props.getSalary();
    this.props.getMastersSalary();

    this.fetchMasterSalary();

    function getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    }

    let monday = getMonday(new Date());
    let day = monday.getDate() + 1 < 10 ? '0' + parseInt(monday.getDate()) : monday.getDate();
    let dayMonth = monday.getMonth() + 1 < 10 ? '0' + parseInt(monday.getMonth() + 1) : monday.getMonth() + 1;
    let dayYear = monday.getFullYear();
    let lastMonday = dayYear + '-' + dayMonth + '-' + day;

    this.setState({dateStart: lastMonday})

    setTimeout(() => {
      this.fetchDataCreate();
    })

    document.title = this.props.intl.formatMessage({
      id: 'app.overhead',
    });
  }

  reRender = () => {
    this.fetchDataCreate();
  }

  addProductWaybillsHandle = () => {
    this.setState({
      isOpen: true
    })
  }
  closePopupProductWaybills = () => {
    this.setState({
      isOpen: false
    })
  }

  handleChangeTabs = (event, newValue) => {
    this.setState({activeTab: newValue})
  }

  fetchMasterSalary = () => {
    this.props.getMastersSalaryErn().then(() => {
      let summForDay = 0;
      let masterSumm = [];
      if (this.props.paymentsSalary.hasOwnProperty('workingDays')) {
        Object.keys(this.props.paymentsSalary.masters).map((master, index) => {
          summForDay = 0;
          this.props.paymentsSalary.workingDays.map((row, key) => {

            row.working_day_master.map((workDay, i) => {
              if (this.props.paymentsSalary.masters[master] === workDay.user_id) {
                summForDay = parseFloat(summForDay + parseFloat(workDay.salary)).toFixed(2);
                summForDay = parseFloat(summForDay);
              }
            })
          });

          if (masterSumm.hasOwnProperty(master)) {

            masterSumm[master] = masterSumm[master] + summForDay;
          } else {
            masterSumm[master] = summForDay;
          }

        });
        Object.keys(masterSumm).map((master) => {
          masterSumm[master] = parseFloat(masterSumm[master]).toFixed(2);
        });

        this.setState({masterSumm: masterSumm})
      }
    });
  }


  fetchDataCreate = (filter = false) => {
    let data = '',
      curPage = this.state.currentPage,
      perPage = this.state.perPage,
      dateStart = this.state.dateStart !== '' ? this.state.dateStart : null,
      dateEnd = this.state.dateEnd !== '' ? this.state.dateEnd : null,
      search = this.state.search !== '' ? this.state.search : null;

    if(filter) {
      curPage = 1;
      this.setState({currentPage: 1});
    }

    let tableDate = '?between=date';
    // if (this.state.activeTab === 0) {
    //   curPage = this.state.currentPageDelivery;
    //   perPage = this.state.perPageDelivery;
    //   tableDate = '&between=delivery_date';
    // }

    // data = data + `?page=${curPage}&limit=${perPage}`;

    // between=created_at,2020-09-16:2020-09-16
    let date = '';

    if (dateStart !== null) date = date + `${tableDate},${dateStart}`;

    if (dateEnd !== null) {
      if (dateStart === null) {
        date = date + `${tableDate},:${dateEnd}`;
      } else {
        date = date + `:${dateEnd}`;
      }
    }

    data = data + date;
    if (data !== '') {
      if (search !== null) data = data + `&filter=id:${search}&master=${search}`;
    } else {
      if (search !== null) data = data + `?filter=id:${search}&master=${search}`;
    }


    this.props.fetchWorkDays(data);
    this.props.fetchWorkToday().then(() => {
      if (!this.props.workingDay.hasOwnProperty('masters')) return;

      let countMasters = this.props.workingDay.masters.length;
      let salary = this.props.workingDay.workingDay.master_income;
      let workDaySalary = [];

      this.props.workingDay.masters.map((item, index) => {

        let master = {
          id: item.id,
          // salary: parseFloat(parseFloat(salary).toFixed(2) / parseFloat(countMasters).toFixed(2)).toFixed(2),
          salary: 0,
          checked: false,
        };

        workDaySalary.push(master);
      });

      this.setState({
        countMasters: countMasters,
        salary: salary,
        workDaySalary: workDaySalary
      })
    });

  }

  filterReset = () => {
    this.setState({
      search: '',
      dateStart: '',
      dateEnd: ''
    })

    setTimeout(() => this.fetchDataCreate(true))
  }

  handleChangePage = (event, newPage) => {
    this.setState({currentPage: newPage + 1})
    setTimeout(() => this.fetchDataCreate())
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({perPage: parseInt(event.target.value, 10)})
    this.setState({currentPage: 0})
    setTimeout(() => this.fetchDataCreate())
  }


  handleChangePageDelivery = (event, newPage) => {
    this.setState({currentPageDelivery: newPage + 1})
    setTimeout(() => this.fetchDataCreate())
  }

  handleChangeRowsPerPageDelivery = (event) => {
    this.setState({perPageDelivery: parseInt(event.target.value, 10)})
    this.setState({currentPageDelivery: 0})
    setTimeout(() => this.fetchDataCreate())
  }

  changeCheckMaster = (event, index) => {
    let workDaySalary = this.state.workDaySalary;
    let masters = this.state.countMasters;

    workDaySalary[index].checked = event.target.checked;

    if (event.target.checked === false) {
      workDaySalary[index].salary = 0;
      masters = masters - 1;
    } else {
      masters = masters + 1;
    }

    masters = workDaySalary.filter(master => master.checked === true).length;

    workDaySalary.map((item, index) => {
      if (item.checked === true) {
        item.salary = parseFloat(parseFloat(this.props.workingDay.workingDay.master_income).toFixed(2) / parseFloat(masters).toFixed(2)).toFixed(2)
      }
    })


    this.setState({workDaySalary: workDaySalary, countMasters: masters})
  }

  changeDaySallary = (event, index) => {
    let workDaySalary = this.state.workDaySalary;
    workDaySalary[index].salary = parseFloat(event.target.value).toFixed(2);
    this.setState({workDaySalary: workDaySalary})
  }

  closeDayPopup = () => {
    this.setState({
      isOpen: true,
    })
  }

  reOpenDay = () => {
    this.props.reOpenDay().then(() => {
      this.fetchDataCreate();
      this.fetchMasterSalary();
    });
  }

  nextOpenDay = () => {
    this.props.nextOpenDay().then((response) => {
      if(response === 'Can\'t open new day'){
        this.setState({alert: true})
        // alert(23423);
      }
      this.fetchDataCreate();
      this.fetchMasterSalary();
    });
  }

  closeDay = () => {
    this.setState({
      isOpen: false,
    });

    let allSalary = 0;
    let workDaySalary = [];


    this.state.workDaySalary.map((item, index) => {
      allSalary = parseFloat(allSalary) + parseFloat(item);

      if (item.checked === true) {
        workDaySalary.push(item);
      }
    });

    if(workDaySalary.length === 0){
      alert('выберите хоть одного мастера');
      return;
    }

    if (allSalary > this.state.salary) {
      this.setState({errPay: true});
      return;
    } else {
      this.setState({errPay: false});
    }

    this.props.closeDay(workDaySalary).then(() => {
      this.fetchDataCreate();
      this.fetchMasterSalary();
    });
  }


  changeMasterPayment = (event, item) => {
    let masterSumm = this.state.masterSumm;

    masterSumm[item] = event.target.value;
    this.setState({
      masterSumm: masterSumm
    })
  }

  changeMasterPaymentBlur = (event, item) => {
    let masterSumm = this.state.masterSumm;

    masterSumm[item] = parseFloat(event.target.value).toFixed(2);
    this.setState({
      masterSumm: masterSumm
    })
  }

  paySalary = () => {
    let data = {};
    Object.keys(this.state.masterSumm).map((item, index) => {
      data[this.props.paymentsSalary.masters[item]] = this.state.masterSumm[item];
    })

    this.props.getPaySalary(data).then(() => {
      this.props.getMastersSalaryErn();
    })
  }

  closePopup = () => {
    this.setState({isOpen: false})
  }

  render() {

    // console.log(this.state.masterSumm);

    let summSalary = 0;

    let headerDelivery = [
      {
        colspan: '0',
        align: 'left',
        item: ''
      },
      {
        colspan: '0',
        align: 'left',
        item: 'id'
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.date"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.mastersSalary"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.cassa"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.status"/>
      }
    ];

    let headerUnderTableAll = [
      {
        colspan: '0',
        align: 'left',
        item: 'id'
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.name"/>
      },
      {
        colspan: '0',
        align: 'left',
        item: <FormattedMessage id="app.masterSalary"/>
      },
    ];


    return (
      <div className="waybills">
        <div className="page-title">
           <span className={'header__name'}>
                <FormattedMessage id="app.reportCard"/>
            </span>
        </div>

        <div>
          <Collapse in={this.state.alert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({alert: false})
                  }}
                >
                  <FormattedMessage id="app.close"/>
                </IconButton>
              }
            >
              Следующий день вы сможете открыть после 00:00
            </Alert>
          </Collapse>
        </div>

        <div className="filter-head">

          <div className="filter-head__left">
            <div className="site-filter">

              <TextField
                type="date"
                value={this.state.dateStart}
                defaultValue={this.state.dateStart}
                label={<FormattedMessage id="app.selectDateFrom"/>}
                onChange={(event) => {
                  this.setState({dateStart: event.target.value})
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                type="date"
                label={<FormattedMessage id="app.selectDateFor"/>}
                value={this.state.dateEnd}
                onChange={(event) => {
                  this.setState({dateEnd: event.target.value})
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            {/*<div className="filter-client-wrap">*/}
            {/*<TextField*/}
            {/*className="filter-client-select"*/}
            {/*value={this.state.search}*/}
            {/*onChange={(event) => this.setState({search: event.target.value})}*/}
            {/*size="small"*/}
            {/*onKeyDown={this._handleKeyDown}*/}
            {/*label={<FormattedMessage id="app.search"/>}*/}
            {/*variant="outlined"/>*/}
            {/*</div>*/}


            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.filterReset}>
              <FormattedMessage id="app.reset"/>
            </Button>
            <Button variant="contained" className="yellow-btn recording__add-btn" onClick={() => this.fetchDataCreate( true)}>
              <FormattedMessage id="app.search"/>
            </Button>
          </div>


          {this.state.isOpen ?
            <PopupWrapper classPopup={'add-bills-item'} closePopup={this.closePopup}>
              {/*<div>*/}
                <QuestionPopup yes={this.closeDay} question={'Вы уверены что хотите закрыть день?'}/>
              {/*</div>*/}
            </PopupWrapper>
          : ''}
        </div>

        <Paper>
          <Tabs
            value={this.state.activeTab}
            onChange={(event, newValue) => this.handleChangeTabs(event, newValue)}
            indicatorColor="primary"
            textColor="primary"
            style={{padding: '0 20px'}}
          >
            <Tab label={<FormattedMessage id="app.today"/>}/>
            <Tab label={<FormattedMessage id="app.all"/>}/>
            <Tab onClick={this.props.getMastersSalaryErn} label={<FormattedMessage id="app.reportCard"/>}/>
            <Tab onClick={this.props.getMastersSalary} label={<FormattedMessage id="app.payments"/>}/>

          </Tabs>
        </Paper>




        {this.state.activeTab === 0 ?
          <div className="page-section">
            <div className="page-section__content">

              {this.props.workingDay.hasOwnProperty('error') ?
                <div>
                  <div className={'no-open-day'}><FormattedMessage id="app.noOpenDay"/></div>
                  <div className={'pb-4 d-flex justify-content-center'}>
                    <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.reOpenDay}>
                      <FormattedMessage id="app.openLastClosedDay"/>
                    </Button>
                    <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.nextOpenDay}>
                      <FormattedMessage id="app.openNewDay"/>
                    </Button>
                  </div>
                </div>
                :
                <React.Fragment>
                  <div className="income-salary row m-0">
                    <div className="all-income col-md-2">
                      <FormattedMessage id="app.cassa"/> &nbsp;
                      {
                        this.props.workingDay.hasOwnProperty('workingDay') ?
                          parseFloat(this.props.workingDay.workingDay.service_income).toFixed(2)
                          :
                          null
                      }
                      &nbsp; UAH
                    </div>
                    <div className="all-income col-md-3">
                      <FormattedMessage id="app.mastersSalary"/> &nbsp;
                      {
                        this.props.workingDay.hasOwnProperty('workingDay') ?
                          parseFloat(this.props.workingDay.workingDay.master_income).toFixed(2)
                          :
                          null
                      }
                      &nbsp; UAH
                    </div>
                  </div>


                  <TableContainer className={'clients-table'}>
                    <Table size="small" aria-label="a dense table">

                      <TableHead>
                        <TableRow className={'clients-table-head'}>
                          <TableCell align={'left'}></TableCell>
                          <TableCell align={'left'}>id</TableCell>
                          <TableCell align={'left'}><FormattedMessage id="app.name"/></TableCell>
                          <TableCell align={'left'}><FormattedMessage id="app.masterSalary"/></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          this.props.workingDay.hasOwnProperty('masters') ?
                            this.props.workingDay.masters.map((row, key) => {
                              return (
                                <TableRow key={key}>

                                  <TableCell align={'left'}>
                                    {this.state.workDaySalary.length > 0 &&this.state.workDaySalary[key].hasOwnProperty('checked') ?
                                      <input type="checkbox" checked={this.state.workDaySalary[key].checked}
                                             onChange={(event) => this.changeCheckMaster(event, key)}/>
                                      :
                                      null
                                    }
                                  </TableCell> <TableCell align={'left'}>
                                  {row.id}
                                </TableCell>

                                  <TableCell align={'left'}>
                                    {row.name}
                                  </TableCell>

                                  <TableCell align={'left'}>

                                    {(this.state.countMasters !== null && this.state.salary !== null) ?
                                      <TextField
                                        className="work-day-salary"
                                        // defaultValue={parseFloat(parseFloat(this.state.salary).toFixed(2) / parseFloat(this.state.countMasters).toFixed(2)).toFixed(2)}
                                        value={this.state.workDaySalary[key].salary}
                                        onChange={(event) => this.changeDaySallary(event, key)}
                                        size="small"
                                        variant="outlined"/>
                                      :
                                      <TextField
                                        className="work-day-salary"
                                        // onChange={(event) => this.setState({workDaySalary: event.target.value})}
                                        size="small"
                                        variant="outlined"/>
                                    }

                                  </TableCell>


                                </TableRow>
                              )
                            })
                            : null
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="btn-salary d-flex justify-content-end pb-3 pr-3">
                    <span className={'d-flex align-items-center'}>
                      {this.props.workingDay.hasOwnProperty('workingDay') ? this.props.workingDay.workingDay.date : null}
                    </span>
                    <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.closeDayPopup}>
                      <FormattedMessage id="app.closeDay"/>
                    </Button>
                  </div>

                  {this.state.errPay ?
                    <div className="err-txt d-flex justify-content-end pb-3 pr-3">
                      Сумма выплаты мастерам больше выделеной им суммы
                    </div>
                    : null
                  }
                </React.Fragment>
              }


            </div>
          </div>
          : null
        }

        {this.state.activeTab === 1 ?
          <div className="page-section">
            <div className="page-section__content">
              <TableContainer className={'clients-table' + (this.props.workDaysLoading ? ' loading-table' : '')}>
                <Table size="small" aria-label="a dense table">
                  <TableHeader rows={headerDelivery}/>

                  <TableBody>
                    {
                      this.props.workDays.length > 0 ?
                        this.props.workDays.map((row, key) => {
                          return (
                            <TableRowsWorkDays reRender={this.reRender} key={key}
                                               row={row}
                                               button={true}
                                               undertable={true} headerUnderTable={headerUnderTableAll}/>
                          )
                        })
                        : null
                    }
                  </TableBody>
                </Table>
              </TableContainer>

            </div>
          </div>

          :

          null
        }

        {this.state.activeTab === 2 ?
          <div>
            {this.props.paymentsSalary.hasOwnProperty('workingDays') ?
              <div className="page-section">
                <div className="page-section__content">
                  <TableContainer className={'clients-table'}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow className={'clients-table-head'}>
                          <TableCell align={'left'}></TableCell>
                          <TableCell align={'left'}><FormattedMessage id="app.days"/></TableCell>
                          {Object.keys(this.props.paymentsSalary.masters).map((item, index) => {
                            return (
                              <TableCell key={index} align={'left'}>{item}</TableCell>
                            )
                          })}

                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          this.props.paymentsSalary.workingDays.length > 0 ?
                            this.props.paymentsSalary.workingDays.map((row, key) => {
                              return (
                                <TableRowsPaymentsSalary reRender={this.reRender} key={key}
                                                         row={row} masters={this.props.paymentsSalary.masters}
                                                         button={true}
                                                         undertable={true}/>
                              )
                            })
                            : null
                        }
                      </TableBody>

                      <TableFooter className={'payments-footer-table'}>
                        <TableRow>
                          <TableCell align={'left'}>
                            <FormattedMessage id="app.amount"/>
                          </TableCell>
                          <TableCell align={'left'}></TableCell>
                          {
                            // this.state.masterSumm.length > 0 ?
                            Object.keys(this.props.paymentsSalary.masters).map((item, index) => {
                              return (
                                <TableCell align={'left'} key={index}>
                                  <div className={'d-flex align-items-center'}>
                                    <TextField
                                      className="work-day-salary"
                                      // defaultValue={parseFloat(parseFloat(this.state.salary).toFixed(2) / parseFloat(this.state.countMasters).toFixed(2)).toFixed(2)}
                                      // defaultValue={this.state.masterSumm[item]}
                                      value={this.state.masterSumm[item]}
                                      onChange={(event) => this.changeMasterPayment(event, item)}
                                      onBlur={(event) => this.changeMasterPaymentBlur(event, item)}
                                      size="small"
                                      variant="outlined"/> &nbsp; UAH
                                  </div>

                                </TableCell>
                              )
                            })
                          }

                        </TableRow>
                        <TableRow>
                          <TableCell align={'left'}>
                            <FormattedMessage id="app.invoiceTotal"/>
                          </TableCell>
                          <TableCell align={'right'} colSpan={6}>
                            {
                              Object.keys(this.state.masterSumm).map((item, index) => {
                                summSalary = parseFloat(summSalary) + parseFloat(this.state.masterSumm[item]);
                              })
                            }
                            {
                              (
                                <div><b>{parseFloat(summSalary).toFixed(2)} UAH</b></div>
                              )
                            }
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>

                  <div className="pay-salary d-flex justify-content-end pb-3 pr-3">
                    <Button variant="contained" className="yellow-btn recording__add-btn" onClick={this.paySalary}>
                      <FormattedMessage id="app.paymentSalary"/>
                    </Button>
                  </div>

                </div>
              </div>
              :
              null
            }
          </div>
          : null
        }

        {this.state.activeTab === 3 ?


          <TableContainer className={'clients-table'}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow className={'clients-table-head'}>
                  <TableCell align={'left'}></TableCell>
                  <TableCell align={'left'}><FormattedMessage id="app.days"/></TableCell>
                  <TableCell align={'left'}><FormattedMessage id="app.invoiceTotal"/></TableCell>

                </TableRow>
              </TableHead>

              <TableBody>
                {
                  Object.keys(this.props.mastersSalary).length > 0 ?
                    // this.props.mastersSalary.length > 0 ?
                    Object.keys(this.props.mastersSalary).map((row, key) => {
                        return (
                          <TableRowsSalaryInfo reRender={this.reRender} key={key}
                                               row={this.props.mastersSalary[row]}
                                               date={row}
                                               button={true}
                                               undertable={true} />
                        )
                      })
                      // : null
                    :
                    null
                }
              </TableBody>
            </Table>
          </TableContainer>
          :
          null
        }

      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    loading: state.workersStatistic.loading,
    workDaysLoading: state.workersStatistic.workDaysLoading,
    productWaybills: state.productWaybills.productWaybills,
    delivery: state.products.delivery,
    workDays: state.workersStatistic.workDays,
    workingDay: state.workersStatistic.workingDay,
    paymentsSalary: state.workersStatistic.paymentsSalary,
    mastersSalary: state.workersStatistic.mastersSalary,
  }

}

function mapDispathToProps(dispath) {
  return {
    fetchWorkDays: (data) => dispath(fetchWorkDays(data)),
    fetchWorkToday: () => dispath(fetchWorkToday()),
    closeDay: (data) => dispath(closeDay(data)),
    // getSalary: () => dispath(getSalary()),
    getMastersSalary: () => dispath(getMastersSalary()),
    getMastersSalaryErn: () => dispath(getMastersSalaryErn()),
    getPaySalary: (data) => dispath(getPaySalary(data)),
    reOpenDay: () => dispath(reOpenDay()),
    nextOpenDay: () => dispath(nextOpenDay()),
  }
}


export default connect(mapStateToProps, mapDispathToProps)(withRouter(injectIntl(ReportCard)));
